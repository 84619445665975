package hu.mkik.vb.portal.ui.proceeding

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.model.ParticipationCandidate
import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.ui.participationService
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.bodyMedium
import hu.simplexion.z2.browser.css.bodySmall
import hu.simplexion.z2.browser.css.displayGrid
import hu.simplexion.z2.browser.css.mb24
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.BoundField
import hu.simplexion.z2.browser.immaterial.schematic.attachListener
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.search.*
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.localization.localizedTextStore
import hu.simplexion.z2.schematic.SchematicFieldEvent
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.localLaunch
import kotlinx.datetime.Clock
import kotlin.time.Duration.Companion.days

fun Z2.participantSearch2(candidate: ParticipationCandidate, exclude: List<UUID<AccountVB>>) {

    search<ParticipationCandidate>(strings.participantSearchHint)
        .query { query(it, exclude) }
        .itemText { it.fullName }
        .itemRender { searchHit(it) }
        .filterChange { candidate.reset() }
        .onSelect { candidate.copyFrom(it) }
        .also { it.addCss(mb24) }

    field { candidate.fullName }
    field { candidate.email }
    field { candidate.address }
}

fun Z2.participantSearch(candidate: ParticipationCandidate, exclude: List<UUID<AccountVB>>) {
    field { candidate.fullName }
    field { candidate.email }
    field { candidate.address }

    attachListener(candidate) {
        if (it !is SchematicFieldEvent) return@attachListener
        if (it.field.name == "email") {
            localLaunch {

            }
        }
     }
}

fun Z2.gridConfig(templateColumns : String? = null, templateRows : String? = null, column : String? = null, row : String? = null, gap: Int? = null) {
    addCss(displayGrid)
    if (templateColumns != null) gridTemplateColumns = templateColumns
    if (templateRows != null) gridTemplateRows = templateRows
    if (column != null) gridColumn = column
    if (row != null) gridRow = row
    if (gap != null) gridGap = gap.px
}

private suspend fun query(filter: String, exclude: List<UUID<AccountVB>>): List<ParticipationCandidate> {
    return participationService
        .candidates(null, null, filter.lowercase())
        .filter { ! exclude.contains(it.account) }
}

private fun Z2.searchHit(candidate: ParticipationCandidate) {
    div(bodyMedium) { +candidate.fullName }
    div(bodySmall) { + candidate.email }
}

fun lastWeek() =
    Clock.System.now().minus(7.days)

fun BoundField<String>.validateCaseNumber(proceeding: Proceeding) {
    attachListener(proceeding) { // FIXME generalize suspend field validator
        if ( ! proceeding.caseNumber.matches(Regex("VB/\\d{5}"))) {
            error = true
            errorText = strings.invalidCaseNumber.localized
            return@attachListener
        }
        if (proceeding.caseNumber != currentProceeding.caseNumber) {
            localLaunch {
                val result = proceedingService.checkCaseNumber(proceeding.caseNumber)
                if (result == null) {
                    error = false
                } else {
                    error = true
                    errorText = localizedTextStore[result]?.value
                }
            }
        } else {
            error = false
        }
    }
}