package hu.mkik.vb.portal.ui.account.pages

import hu.mkik.vb.portal.ui.component.singlePageDialogWithTitleAndLanguages
import hu.mkik.vb.portal.ui.securityPolicy
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.application.session
import hu.simplexion.z2.auth.sessionService
import hu.simplexion.z2.browser.css.displayGrid
import hu.simplexion.z2.browser.css.pl24
import hu.simplexion.z2.browser.css.pr24
import hu.simplexion.z2.browser.css.pt24
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.BoundField
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.fr
import hu.simplexion.z2.browser.material.textfield.AbstractField
import hu.simplexion.z2.browser.material.textfield.TextField
import hu.simplexion.z2.browser.routing.SimpleRouter
import hu.simplexion.z2.schematic.Schematic
import hu.simplexion.z2.services.transport.ServiceCallStatus
import hu.simplexion.z2.services.transport.ServiceResultException
import kotlinx.browser.window
import loadCoreData
import mainRouter

val Login = SimpleRouter(loggedIn = false) {
    singlePageDialogWithTitleAndLanguages(strings.login) { LoginForm(this).main() }
}

class LoginData : Schematic<LoginData>() {
    var accountName by string().blank(false)
    var password by secret().blank(false)
}

private class LoginForm(parent: Z2) : Z2(parent) {

    val data = LoginData()

    lateinit var accountNameField: BoundField<String>
    lateinit var passwordField: BoundField<String>

    override fun main(): LoginForm {
        addCss(displayGrid, pl24, pr24)
        gridTemplateColumns = "1fr"
        gridTemplateRows = "min-content min-content"

        // FIXME remove test username and password
        if (window.location.host in listOf("localhost:3000", "127.0.0.1:3000")) {
            data.accountName = "366003"
            data.password = "csl"
        }

        form(displayGrid) {
            gridTemplateColumns = 1.fr
            gridTemplateRows = "min-content min-content min-content"
            accountNameField = field { data.accountName }
            passwordField = field { data.password }
        }

        grid("1fr min-content", "min-content", pt24) {
            div {
                style.marginLeft = "-8px"
                textButton(strings.forgottenPassword) { PasswordResetInit.open() }
            }

            filledLaunchButton(strings.login) { execute() }
        }

        return this
    }

    private fun switchLanguage(language: String) {

    }

    suspend fun execute() {
        // have to use field values directly as Chrome autofill does not fire proper field change
        // event, see https://stackoverflow.com/questions/35049555/chrome-autofill-autocomplete-no-value-for-password
        // FIXME there are actually events fired but they are the wrong type

        val account = (accountNameField.uiField as TextField).inputElement.value
        val password = (passwordField.uiField as AbstractField<String>).inputElement.value

        data.accountName = account
        data.password = password

        if (!data.touch()) return

        try {
            sessionService.login(data.accountName, data.password).let {
                if (securityPolicy.twoFactorAuthentication) {
                    SecurityCode.open()
                } else {
                    session = it
                    loadCoreData()
                    mainRouter.open()
                }
            }
        } catch (ex: ServiceResultException) {
            val locked = (ex.responseEnvelope.status == ServiceCallStatus.AuthenticationFailLocked)
            with(accountNameField.uiField.state) {
                error = true
                errorText = if (locked) strings.accountLocked.localized else ""
            }
            with(passwordField.uiField.state) {
                error = true
                errorText = if (locked) "" else strings.loginFail.toString()
            }
        }
    }

}