package hu.mkik.vb.portal.ui.proceeding.participation.components

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.RoleCandidate
import hu.mkik.vb.portal.ui.component.participationColumn
import hu.mkik.vb.portal.ui.participationService
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.browser.material.icon.icon
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.util.UUID
import kotlin.collections.set

val representationSelects = mutableMapOf<UUID<Participation>, Boolean>()

fun Z2.representation(roleCandidate: RoleCandidate, copy: Participation) =
    div(borderOutline, borderRadius4, positionRelative).attach(roleCandidate) {
        val representations = with(currentProceeding.settings) {
            listOf(
                claimantRepresenterRole to claimantRole,
                claimantCaseManagerRole to claimantRole,
                respondentRepresenterRole to respondentRole,
                respondentCaseManagerRole to respondentRole,
                intervenerRepresenterRole to intervenerRole
            )
        }

        representationSelects.clear()

        val representedRole = representations.firstOrNull { it.first == roleCandidate.role.uuid }?.second

        table<Participation> {
            header = true
            headerBuilder = {
                grid("1fr min-content", "40px", borderBottomOutline) {
                    div {
                        div(alignSelfCenter, p12, labelMedium) { +strings.representedParty.localeCapitalized }
                    }
                }
            }

            query = {
                representationSelects.clear()
                if (roleCandidate.role.uuid == copy.proceedingRole) {
                    participationService.representations(copy.uuid).forEach {
                        representationSelects[it] = true
                    }
                }
                currentProceeding.participations.value.filter { it.proceedingRole == representedRole && it.uuid != copy.uuid && it.active }
            }
            rowId = { row -> row.uuid }

            participationColumn()

            column {
                render = { row -> if (representationSelects[row.uuid] == true) icon(browserIcons.check) }
                initialSize = 3.em
            }

            column {
                label = strings.actions
                render = { row ->
                    style.marginLeft = (-12).px
                    if (representationSelects[row.uuid] == true) {
                        textButton(strings.remove) {
                            representationSelects[row.uuid] = false
                            table.redraw()
                        }
                    } else {
                        textButton(strings.add) {
                            representationSelects[row.uuid] = true
                            table.redraw()
                        }
                    }
                }
                initialSize = "120px"
            }
        }
    }