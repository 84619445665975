package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.VbPortalRoles
import hu.simplexion.z2.application.model.ApplicationSettings
import hu.simplexion.z2.services.Service

interface VbPortalApi : Service {

    suspend fun vbPortalRoles(): VbPortalRoles

}