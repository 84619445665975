package hu.mkik.vb.portal.ui.administration.feeCondition

import hu.mkik.vb.portal.model.ProceedingFeeCondition
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.proceedingFeeService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.section
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.browser.util.io
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.localLaunch
import mainRouter

object EditProceedingFee : NavRouter(useParentNav = true) {
    val uuid by uuid<ProceedingFeeCondition>()
    override var default: Z2Builder = { edit(uuid) }
}

object AddProceedingFee : NavRouter(useParentNav = true) {
    override var default: Z2Builder = { edit(UUID.nil()) }
}

internal fun Z2.edit(uuid: UUID<ProceedingFeeCondition>) {
    localLaunch {
        val add = (uuid == UUID.nil<ProceedingFeeCondition>())
        val condition = if (add) ProceedingFeeCondition() else proceedingFeeService.get(uuid)

        surfaceContainerLowest(borderOutline, p24) {
            div(titleLarge) { text { if (add) strings.addProceedingFee else condition.type } }
            if (! add) {
                div(pt4, bodySmall, onSurfaceVariantText) { text { condition.uuid } }
            }

            grid(gridGap16) {
                gridTemplateColumns = "1fr 1fr"
                gridTemplateRows = "min-content 1fr"

                section(strings.proceedingType) {
                    gridColumn = "1"
                    gridRow = "1"
                    field { condition.proceedingType }
                }

                section(strings.type) {
                    gridColumn = "1"
                    gridRow = "2"
                    field { condition.type }
                }

                section(strings.values) {
                    gridColumn = "2"
                    gridRow = "1/3"
                    grid {
                        gridConfig("1fr", "min-content", gap = 8)

                        field { condition.validFrom }
                        field { condition.subjectValueLimit }
                        field { condition.minimumFee }
                        field { condition.maximumFee }
                        field { condition.baseFee }
                        field { condition.percent }
                        field { condition.percentageBasis }
                    }
                }
            }

            grid(pt24, gridGap16) {
                gridTemplateColumns = "1fr min-content"

                textButton(strings.cancel) { mainRouter.back() } css justifySelfEnd

                filledButton(strings.save) {
                    io {
                        if (add) {
                            proceedingFeeService.add(condition)
                            snackbar(strings.createSuccess)
                            mainRouter.back()
                        } else {
                            proceedingFeeService.update(condition)
                            snackbar(strings.saveSuccess)
                        }
                    }
                }
            }
        }
    }
}