package hu.mkik.vb.portal.ui.component

import hu.mkik.vb.portal.model.Proceeding
import hu.simplexion.z2.browser.css.bodySmall
import hu.simplexion.z2.browser.css.onSurfaceVariantText
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.immaterial.schematic.BoundField
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.schematic.SchematicAccessFunction
import hu.simplexion.z2.schematic.access.SchematicAccessContext

fun proceedingCaseNumber(proceedings: List<Proceeding>, uuid: UUID<Proceeding>?) =
    proceedings.firstOrNull { uuid == it.uuid }?.caseNumber ?: ""

fun proceedingSubject(proceedings: List<Proceeding>, uuid: UUID<Proceeding>?) =
    proceedings.firstOrNull { uuid == it.uuid }?.subject ?: ""

@SchematicAccessFunction
fun Z2.proceeding(
    options: List<Proceeding>,
    afterChange : (UUID<Proceeding>) -> Unit = {  },
    context: SchematicAccessContext? = null,
    accessor: () -> UUID<Proceeding>
): BoundField<UUID<Proceeding>> {

    checkNotNull(context)

    return selectField(
        options.map { it.uuid },
        { if (it.isNil) "" else proceedingCaseNumber(options, it) + " - " + proceedingSubject(options, it) },
        {
            div { + proceedingCaseNumber(options, it) }
            div(bodySmall, onSurfaceVariantText) { + proceedingSubject(options, it) }
        },
        afterChange,
        context
    )
}