package hu.mkik.vb.portal.model

import hu.simplexion.z2.schematic.Schematic
import kotlinx.datetime.LocalTime

class Hearing : Schematic<Hearing>() {
    var uuid by uuid<Hearing>(true)

    var proceeding by uuid<Proceeding>()
    var site by uuid<Space>()

    var hearingReport by uuid<Document>().nullable()
    var decision by uuid<Document>().nullable()

    var date by localDate()
    var start by localTime(min = LocalTime(6, 0), max = LocalTime(22, 0))

    var note by string(maxLength = 500)

    var status by enum<HearingStatus>()
}