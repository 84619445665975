package hu.mkik.vb.portal.model

import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.schematic.Schematic

class Participation : Schematic<Participation>() {
    var uuid by uuid<Participation>(true)

    var proceeding by uuid<Proceeding>()
    var mpaid by int()
    var participant by uuid<AccountVB>(true).nullable()
    var proceedingRole by uuid<Role>()
    var rank by int(default = 1, min = 1)
    var note by string() maxLength 500
    var active by boolean(true)

    var fullName by string(100) minLength 5 blank false
    var address by string(150)
    var email by string(100)
    var phone by string(100)
    var bankAccount by string(50)

    var retired by boolean(false)
    var technical by boolean(false)

    // FIXME model computed fields properly, these are not persisted but computed on-demand
    var roleName by string()

    override fun toString(): String = fullName

    val primary
        get() = (rank == 1)
}