package hu.mkik.vb.portal.ui.proceeding.hearing.modals

import hu.mkik.vb.portal.model.Hearing
import hu.mkik.vb.portal.model.Space
import hu.mkik.vb.portal.ui.hearingService
import hu.mkik.vb.portal.ui.proceeding.currentCaseNumber
import hu.mkik.vb.portal.ui.spaceService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.loading
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.layout.scrolledBoxWithLabel
import hu.simplexion.z2.browser.material.modal.editModal
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.textfield.textField

fun editHearing(hearing: Hearing, onUpdate: suspend () -> Unit) =
    editModal {
        loading {
            val copy = hearing.copy()
            var address = ""

            title("${strings.editHearing} - $currentCaseNumber")

            body {
                loading {
                    val space = spaceService.get(copy.site)

                    grid("min-content", "min-content", 16) {
                        address = space.address
                        textField(space.address, label = strings.address) { address = it.value }

                        grid("min-content min-content", "min-content", 16) {
                            field { copy.date }
                            field { copy.start }
                        }

                        field { copy.note }

                        scrolledBoxWithLabel(strings.status) {
                            field { copy.status }
                        }
                    }
                }
            }

            save {
                var space = spaceService.get(copy.site)

                if (address != space.address) {
                    val spaceUuid = spaceService.add(Space().also {
                        it.address = address
                        it.name = "auto"
                        it.active = false
                    })
                    space = spaceService.get(spaceUuid)
                }

                copy.site = space.uuid

                copy.touch()

                if (!copy.isValid) {
                    snackbar(strings.invalidFields)
                    return@save
                }

                hearingService.update(copy)
                onUpdate()
                close()
            }
        }
    }