package hu.mkik.vb.portal.ui.account.modals

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.ui.accountVBService
import hu.mkik.vb.portal.ui.component.NewPassword
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.userAccount
import hu.simplexion.z2.browser.material.modal.modal
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.util.UUID

fun passwordChange(uuid : UUID<AccountVB>) =
    modal {
        style.width = 400.px

        title(strings.passwordChange)

        lateinit var newPassword: NewPassword

        body {
             newPassword = NewPassword(this, uuid == userAccount).main()
        }

        save {
            if (!newPassword.valid()) return@save
            accountVBService.setPassword(uuid.cast(), newPassword.currentPassword, newPassword.newPassword)
            snackbar(strings.successfulPasswordChange)
            close()
        }
    }