package hu.mkik.vb.portal.ui.proceeding.document.modals

import hu.mkik.vb.portal.model.DocumentType
import hu.mkik.vb.portal.model.FolderType
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.document.components.FileBundleInput
import hu.mkik.vb.portal.ui.proceeding.document.components.fileBundle
import hu.mkik.vb.portal.ui.proceeding.document.logic.startUpload
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.adaptive.modal.modal

fun documentUpload() =
    modal {
        lateinit var bundle : FileBundleInput<FolderType, DocumentType>

        title(strings.addDocument)

        body {
            bundle = fileBundle(currentProceeding)
        }

        save(strings.upload) {
            if (! bundle.validate()) return@save
            startUpload(bundle)
            close()
        }
    }