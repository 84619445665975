package hu.mkik.vb.portal.model

import hu.simplexion.z2.localization.model.Locale
import hu.simplexion.z2.schematic.Schematic

class Proceeding : Schematic<Proceeding>() {
    val uuid by uuid<Proceeding>(true)

    var caseNumber by string(50, blank = false)
    var subject by string(500)
    var description by string(500)
    var filingNumber by int()

    var letterOfClaimantDate by localDate()
    var accountingDate by localDate().nullable()
    var contractDate by localDate().nullable()
    var closeDate by localDate().nullable()

    var renewal by boolean()
    var expedited by boolean()

    var type by enum<ProceedingType>()

    var established by boolean()
    var adjourned by boolean()
    var partialJudgement by boolean()
    var closed by boolean()
    var reopened by boolean()
    var archived by boolean()

    var closeType by enum<ProceedingCloseType>().nullable()

    var claimValue by decimal(scale = 0) min 0
    var counterClaimValue by decimal(scale = 0) min 0
    var setoffObjection by decimal(scale = 0) min 0

    var waiveCounterRegistrationFee by boolean()
    var isClaimantLevyFree by boolean()
    var isRespondentLevyFree by boolean()

    var language by uuid<Locale>()

    var modifiedBy by uuid<AccountVB>(true)
    var modifiedAt by instant()

    var primaryClaimantName by string()
    var primaryRespondentName by string()
    var secretaryName by string()

}