package hu.mkik.vb.portal.ui.administration.role

import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.technical
import hu.mkik.vb.portal.ui.util.content
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.auth.roleService
import hu.simplexion.z2.baseIcons
import hu.simplexion.z2.baseStrings
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.routing.NavRouter

val RoleList = NavRouter(baseStrings.roles, baseIcons.roles, visibility = technical, useParentNav = true) { list() }

fun Z2.list() =
    tablePage<Role> {

        header {
            text = strings.roles
        }

        rowId = { it.uuid }
        query = { roleService.query() }

        schematicColumn { Role().programmaticName }
        schematicColumn { Role().displayName }
        schematicColumn { Role().contextName }
        schematicColumn { Role().displayOrder }

    }