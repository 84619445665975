package hu.mkik.vb.portal.ui.proceeding.finance.components

import hu.mkik.vb.portal.model.finance.ProceedingFeeCalculation
import hu.mkik.vb.portal.model.finance.ProceedingFinanceBundle
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.localization.locales.localeCapitalized

fun Z2.feeCalculation(bundle: ProceedingFinanceBundle, calculation: ProceedingFeeCalculation) =
    surfaceContainerLowest(borderOutline, positionRelative) {
        style.minWidth = "668px"

        gridConfig("1fr", "repeat(3, min-content) 1fr")

        div(pb16) { + strings.calculation.localeCapitalized }

        grid("min-content min-content", "min-content min-content") {
            columnGap = 24.px

            field { calculation.administrationFee } readOnly true
            div {  }

            field { calculation.chairmanHonorarium } readOnly true
            div { }

            field { calculation.reserveFund } readOnly true
            div {  }

            field { calculation.arbitratorsHonorarium } readOnly true
            field { calculation.arbitratorHonorarium } readOnly true

            field { calculation.levy } readOnly true
            div { }

            field { calculation.socialSecurityContribution } readOnly true
            div { }

            div(borderBottomOutline, mb4) { gridColumn = "1/3" }
            div(borderBottomOutline, mb16) { gridColumn = "1/3" }

            field { calculation.total } readOnly true
            div {  }

            div {
                gridColumn = "1/3"
                + strings.calculationExplanation
            }
        }

    }
