package hu.mkik.vb.portal.ui.proceeding.finance.logic

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.finance.ProceedingFinanceBundle

val ProceedingFinanceBundle.incomingParticipations: List<Participation>
    get() {
        val roles = with(settings) {
            listOf(
                claimantRole, claimantRepresenterRole, claimantArbitratorRole, claimantCaseManagerRole,
                respondentRole, respondentRepresenterRole, respondentArbitratorRole, respondentCaseManagerRole
            )
        }

        return participations.filter { it.active && (it.proceedingRole in roles) }
    }