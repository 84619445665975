package hu.mkik.vb.portal.ui.report

import defaultLayout
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.secretary
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.select.select
import hu.simplexion.z2.browser.immaterial.table.builders.ColumnBuilder
import hu.simplexion.z2.browser.immaterial.table.builders.TableBuilder
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledButton
import hu.simplexion.z2.browser.material.card.outlinedCard
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.browser.material.fr
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.browser.routing.RoutingTarget
import hu.simplexion.z2.localization.label
import hu.simplexion.z2.localization.text.monthNameTable
import hu.simplexion.z2.schematic.Schematic
import hu.simplexion.z2.schematic.SchematicAccessFunction
import hu.simplexion.z2.schematic.access.SchematicAccessContext
import hu.simplexion.z2.schematic.schema.SchemaField
import hu.simplexion.z2.util.hereAndNow

object Report : NavRouter(strings.reports, icons.report, visibility = secretary) {
    val proceedingRegister by render(strings.proceedingRegisterReport, icons.report) { proceedingRegister() }
    val monthlySummary by render(strings.monthlySummaryReport, icons.report) { monthlySummary() }
    val yearlySummary by render(strings.yearlySummaryReport, icons.report) { yearlySummary() }
    val refundSummary by render(strings.refundSummaryReport, icons.report) { refundSummary() }
    val levySummary by render(strings.levySummaryReport, icons.report) { levySummary() }
    val honorariumSummary by render(strings.honorariumSummaryReport, icons.report) { honorariumSummary() }

    override fun default(receiver: Z2, path: List<String>) {
        receiver.defaultLayout(this, nav) { report() }
    }
}

private fun Z2.report() =
    surfaceContainerLowest(borderOutline) {
        div(titleLarge, pb24) {
            text { strings.reports }
        }

        grid {
            gridTemplateColumns = "repeat(auto-fit, 300px)"
            gridAutoRows = "min-content"
            gridGap = 8.px

            for (target in Report.targets) {
                reportCard(target)
            }
        }
    }

private fun Z2.reportCard(target: RoutingTarget<*>) {
    outlinedCard(target.label) {
        style.display = "grid"
        gridTemplateColumns = 1.fr
        gridTemplateRows = "min-content 1fr min-content"

        div(bodyMedium) {
            text { target.label?.support }
        }

        div(justifySelfEnd, pt24) {
            filledButton(browserStrings.open) { target.open() }
        }
    }
}

fun Z2.yearSelect(onChangeFun: (Int) -> Unit) {
    select((2023..hereAndNow().year).toList(), hereAndNow().year, strings.year) {
        onChangeFun(it.value)
    }
}

fun Z2.monthSelect(onChangeFun: (Int) -> Unit) {
    select(
        monthNameTable.toList(),
        monthNameTable[hereAndNow().monthNumber - 1],
        strings.month
    ) {
        onChangeFun(monthNameTable.indexOf(it.value) + 1)
    }
}

@SchematicAccessFunction
fun TableBuilder<*>.multiLineColumn(
    context: SchematicAccessContext? = null,
    accessor: () -> String
): ColumnBuilder<*> {
    checkNotNull(context)
    val field = context.field as SchemaField<String>

    return column {
        label = field.label(context.schematic)
        render = { row ->
            div(pb4, pt4) {
                for (date in field.getValue(row as Schematic<*>).split("\n")) {
                    div(whiteSpaceNoWrap) { + date }
                }
            }
        }
        comparator = { a, b ->
            val an = field.getValue(a as Schematic<*>)
            val bn = field.getValue(b as Schematic<*>)
            an.compareTo(bn)
        }
        exportHeader = label
        exportFun = { field.getValue(it as Schematic<*>) }
        initialSize = 7.em
    }
}