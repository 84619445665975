package hu.mkik.vb.portal.model.finance

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.Proceeding
import hu.simplexion.z2.schematic.Schematic

class IncomingPayment : Schematic<IncomingPayment>() {

    var uuid by uuid<IncomingPayment>(true)

    var proceeding by uuid<Proceeding>()
    var participation by uuid<Participation>()

    var paymentAmount by decimal(scale = 0, min = 1)
    var remainingAmount by decimal(scale = 0, min = 0)

    var valueDate by localDate()

    var bankTransaction by uuid<BankTransaction>().nullable()

}