package hu.mkik.vb.portal.ui.proceeding.task.pages

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.Task
import hu.simplexion.z2.schematic.Schematic

class TaskFilter : Schematic<TaskFilter>() {

    var statusFilter by boolean().nullable()
    var assigneeFilter by uuid<Participation>().nullable()

    fun filter(task: Task): Boolean {
        if (statusFilter != null && task.done != statusFilter) return false
        if (assigneeFilter != null && task.assignedTo != assigneeFilter) return false
        return true
    }

}