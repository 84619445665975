package hu.mkik.vb.portal.ui.component

import hu.mkik.vb.portal.ui.isDesktop
import hu.mkik.vb.portal.ui.isLandscape
import hu.mkik.vb.portal.ui.isMobile
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.runtime.localized
import hu.simplexion.z2.localization.text.LocalizedText

fun Z2.singlePageDialog(builder: Z2Builder) =
    surfaceContainerLowest(displayFlex) {
        if (isDesktop) {
            addCss(alignItemsCenter, justifyContentCenter)
            style.width = "100vw"
        } else {
            style.height = "100vh"
            style.width = "100vw"
        }
        removeCss(p16)
        builder()
    }

fun Z2.singlePageDialogWithTitleAndLanguages(
    title: LocalizedText,
    spanLast: Boolean = true,
    innerBuilder: Z2.() -> Z2
) {
    singlePageDialog {
        when {
            isDesktop -> {
                grid("minmax(1fr, 384px)", "min-content 1fr min-content", gridGap32, borderRadius8, borderOutline) {
                    style.maxWidth = "384px"
                    logoAndTitle(title)
                    innerBuilder()
                    languages()
                }
            }

            isMobile && isLandscape -> {
                grid("1fr 1fr", "1fr min-content", heightFull, wFull, pt16) {
                    logoAndTitle(title) css pt16 gridColumn "1" gridRow "1"
                    innerBuilder() css alignSelfCenter gridColumn "2" gridRow (if (spanLast) "1" else "1/span2")
                    languages() gridColumn (if (spanLast) "1/span2" else "1") gridRow "2"
                }
            }

            else -> {
                grid("minmax(1fr, 384px)", "min-content 1fr min-content", heightFull) {
                    logoAndTitle(title) css pb24
                    innerBuilder() css alignSelfStart
                    languages()
                }
            }
        }
    }
}


fun Z2.logoAndTitle(title: LocalizedText) =
    div(wFull, positionRelative, displayFlex, flexDirectionColumn) {
        style.minHeight = "auto"

        img(wFull, pl16, pb8, pr16, pt8) {
            src = "/logo.jpg"
        }

        div(titleLarge, alignSelfCenter, p24) {
            text { title.localeCapitalized }
        }
    }

fun Z2.languages() =
    grid("repeat(3,min-content)", "min-content", pl16, pr24, borderTopOutlineVariant) {
        textButton("magyar".localized) { }
        textButton("deutsch".localized) { }
        textButton("english".localized) { }
    }