package hu.mkik.vb.portal.ui.proceeding.finance.components

import hu.mkik.vb.portal.model.finance.IncomingPair
import hu.mkik.vb.portal.model.finance.IncomingPayment
import hu.mkik.vb.portal.model.finance.IncomingRequirement
import hu.mkik.vb.portal.model.finance.ProceedingFinanceBundle
import hu.mkik.vb.portal.ui.component.participation
import hu.mkik.vb.portal.ui.component.participationColumn
import hu.mkik.vb.portal.ui.component.participationName
import hu.mkik.vb.portal.ui.component.participationType
import hu.mkik.vb.portal.ui.financeService
import hu.mkik.vb.portal.ui.proceeding.finance.logic.incomingParticipations
import hu.mkik.vb.portal.ui.proceeding.finance.modals.IncomingProposal
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.ifValid
import hu.mkik.vb.portal.ui.util.ifValidForCreate
import hu.mkik.vb.portal.ui.util.tertiaryContainer
import hu.simplexion.z2.adaptive.modal.modal
import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.field.FieldState
import hu.simplexion.z2.browser.field.stereotype.decimalField
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.columnGap
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.browser.immaterial.table.builders.size
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.material.button.filledButton
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.browser.material.icon.icon
import hu.simplexion.z2.browser.material.menu.dropdownMenu
import hu.simplexion.z2.browser.material.menu.menuItem
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.switch.SwitchConfig
import hu.simplexion.z2.browser.material.switch.SwitchField
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.locales.localized
import hu.simplexion.z2.localization.localized
import hu.simplexion.z2.localization.text.LocalizedText
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.hereAndNow
import kotlin.math.min

class IncomingFinance(
    parent: Z2,
    var bundle: ProceedingFinanceBundle
) : Z2(parent, classes = arrayOf(displayGrid)) {

    val selectedRequirements = mutableSetOf<UUID<IncomingRequirement>>()
    val selectedPayments = mutableSetOf<UUID<IncomingPayment>>()

    val requirements
        get() = bundle.incomingRequirements

    val payments
        get() = bundle.incomingPayments

    val pairs
        get() = bundle.incomingPairs

    lateinit var pairsTable: Table<IncomingPair>

    override fun main(): IncomingFinance {
        gridConfig("1fr", "repeat(4,min-content)")
        columnGap = 24.px

        requirements()
        payments()
        pairingAction()
        pairs()

        return this
    }

    fun Z2.requirements() =
        grid("1fr", "min-content 1fr") {

            requirementsHeader()

            table<IncomingRequirement> {

                fixRowHeight = false
                noScroll = true
                rowId = { it.uuid }
                data = requirements.filter { it.remainingAmount > 0L }
                doubleClickFun = { editRequirement(it) }

                with(IncomingRequirement()) {
                    schematicColumn { deadline } size 8.em
                    participationColumn(bundle.participations) { participation }
                    schematicColumn { type }
                    schematicColumn { requiredAmount }
                    schematicColumn { remainingAmount }.also { it.label = strings.missingAmount }
                    schematicColumn { counter } label strings.counterClaimAbrv initialSize 3.em
                    column {
                        initialSize = "min-content"
                        render = { row ->
                            SwitchField(
                                this,
                                FieldState(),
                                config = SwitchConfig().also {
                                    it.onChange = { switch ->
                                        if (switch.value) {
                                            selectedRequirements.add(row.uuid)
                                        } else {
                                            selectedRequirements.remove(row.uuid)
                                        }
                                        pairsTable.redraw()
                                    }
                                }
                            ).main().value = (row.uuid in selectedRequirements)
                            addCss(pr12)
                        }
                    }
                }
            } css borderOutline
        }

    fun Z2.requirementsHeader() =
        grid("1fr min-content", "32px", gridGap16, mb12) {
            div(alignSelfCenter) { +strings.incomingRequirements.localeCapitalized }
            div(alignSelfCenter) {
                filledButton(strings.actions) { }.apply {
                    dropdownMenu {
                        menuItem(1, label = strings.addItem) { addRequirement() }
                        menuItem(3, label = strings.claimValue) { IncomingProposal(bundle, false) { reload() } }
                        menuItem(3, label = strings.counterClaimValue) { IncomingProposal(bundle, true) { reload() } }
                    }
                }
            }
        }

    fun Z2.payments() =
        grid("1fr", "min-content 1fr") {

            paymentsHeader()

            table<IncomingPayment> {

                fixRowHeight = false
                noScroll = true
                rowId = { it.uuid }
                data = payments.filter { it.remainingAmount > 0L }

                with(IncomingPayment()) {
                    schematicColumn { valueDate } size 8.em
                    participationColumn(bundle.participations) { participation }
                    schematicColumn { paymentAmount }
                    schematicColumn { remainingAmount }
                    column {
                        initialSize = "min-content"
                        render = { row ->
                            SwitchField(
                                this,
                                FieldState(),
                                config = SwitchConfig().also {
                                    it.onChange = { switch ->
                                        if (switch.value) {
                                            selectedPayments.add(row.uuid)
                                        } else {
                                            selectedPayments.remove(row.uuid)
                                        }
                                        pairsTable.redraw()
                                    }
                                }
                            ).main().value = (row.uuid in selectedPayments)
                        }
                    }
                }
            } css borderOutline
        }

    fun Z2.paymentsHeader() =
        grid("1fr min-content", "32px", pt24, mb12, gridGap16) {
            div(alignSelfCenter) { +strings.incomingPayments.localeCapitalized }
            div(alignSelfCenter) {
                filledButton(strings.actions) { }.apply {
                    dropdownMenu {
                        menuItem(1, label = strings.addIncomingPayment) { addPayment() }
                    }
                }
            }
        }

    fun Z2.pairingAction() =
        div(alignSelfCenter, justifySelfCenter, mt24) {
            style.marginBottom = "-32px"
            style.zIndex = "100"
            filledLaunchButton(strings.pairing) {
                addPair()
            }
        }

    fun Z2.pairsHeader() =
        grid("1fr min-content", "32px", gridGap16, mb8) {
            div(alignSelfCenter) { +strings.paidIncomingRequirements.localeCapitalized }
            div { }
        }

    fun Z2.pairs() =
        grid("1fr", "min-content 1fr", pt16) {

            pairsHeader()

            table<IncomingPair> {
                pairsTable = table

                fixRowHeight = false
                noScroll = true
                rowId = { it.uuid }
                data = pairs

                column {
                    label = strings.deadline
                    render = {
                        div(bodyMedium, displayFlex, alignItemsCenter, justifyContentCenter, p8) {
                            style.marginLeft = (-8).px
                            if (it.requirement in selectedRequirements) addCss(tertiaryContainer, borderRadius8)
                            +it.requirement.data.deadline.localized
                        }
                    }
                    initialSize = "min-content"
                }
                column {
                    label = strings.participation
                    render = {
                        div {
                            it.requirement.data.participation.let { p ->
                                div(whiteSpaceNoWrap, bodyMedium) { +participationName(bundle.participations, p) }
                                div(whiteSpaceNoWrap, bodySmall) { +participationType(bundle.participations, p) }
                            }
                        }
                    }
                    initialSize = "1fr"
                }
                column {
                    label = strings.type
                    render = {
                        addCss(bodyMedium, whiteSpaceNoWrap)
                        +it.requirement.data.type.localized
                    }
                    initialSize = "min-content"
                }
                column {
                    label = strings.valueDate
                    render = {
                        div(displayFlex, bodyMedium, alignItemsCenter, justifyContentCenter, pl24) {
                            if (it.payment in selectedPayments) addCss(tertiaryContainer, borderRadius8)
                            if (it.payment != null) {
                                +it.payment?.data?.valueDate?.localized
                            }
                        }
                    }
                    initialSize = 9.em
                }
                column {
                    label = strings.participation
                    render = {
                        if (it.payment != null) {
                            div {
                                it.payment!!.data.participation.let { p ->
                                    div(whiteSpaceNoWrap, bodyMedium) { +participationName(bundle.participations, p) }
                                    div(whiteSpaceNoWrap, bodySmall) { +participationType(bundle.participations, p) }
                                }
                            }
                        } else {
                            div(bodyMedium) { +strings.withdrawnRequirement }
                        }
                    }
                    initialSize = "1fr"
                }
                column {
                    label = strings.amount
                    render = {
                        addCss(justifyContentFlexEnd, pr12, bodyMedium)
                        +it.pairedAmount.localized
                    }
                    initialSize = 8.em
                }
                column {
                    label = strings.counterClaimAbrv
                    render = { if (it.requirement.data.counter) icon(browserIcons.check) }
                    initialSize = 3.em
                }
                actionColumn {
                    action {
                        label = strings.breakPair
                        handler = { removePair(it) }
                    }
                    initialSize = "6em"
                }
            } css borderOutline
        }

    // ------------------------------------------------------------------------
    // Actions - Fee
    // ------------------------------------------------------------------------

    fun addRequirement() {
        requirementEditor(
            strings.addItem,
            strings.add,
            IncomingRequirement().apply {
                proceeding = bundle.proceeding.uuid
                deadline = hereAndNow().date
            }
        ) { req ->
            ifValidForCreate(req) {
                financeService.add(it)
            }
        }
    }

    fun editRequirement(original: IncomingRequirement) {
        requirementEditor(
            strings.edit,
            strings.save,
            original.copy()
        ) { req ->
            if (req.withdrawn && req.requiredAmount != req.remainingAmount) {
                snackbar(strings.cannotWithdrawPaired)
                return@requirementEditor false
            }

            ifValid(req) {
                if (req.withdrawn) {
                    financeService.withdraw(it)
                } else {
                    financeService.update(it)
                }
            }
        }

    }

    fun requirementEditor(
        dialogTitle: LocalizedText,
        commitTitle: LocalizedText,
        req: IncomingRequirement,
        commitFun: suspend (req: IncomingRequirement) -> Boolean
    ) {
        modal {
            style.minWidth = 600.px
            val copy = req.copy()
            val pairedAmount = req.requiredAmount - req.remainingAmount

            title(dialogTitle.localeCapitalized)

            body {
                grid(gridGap16) {
                    field { copy.type }
                    participation(bundle.incomingParticipations) { copy.participation }
                    field { copy.deadline }
                    field { copy.requiredAmount }
                    if (!copy.uuid.isNil) {
                        div(displayFlex, flexDirectionRow).attach(copy) {
                            decimalField(pairedAmount, 0, strings.alreadyPaidAmount) { }.also { it.state.readOnly = true }
                            div(p24) { }
                            decimalField(copy.requiredAmount - pairedAmount, 0, strings.remainingAmount) { }.also {
                                it.state.readOnly = true
                                it.state.label = strings.missingAmount.localized
                                if (copy.requiredAmount - pairedAmount < 0) {
                                    it.state.toError(strings.negativeRequiredAmount)
                                } else {
                                    it.state.error = false
                                }
                            }
                        }
                        field { copy.counter } label strings.counterClaim
                        field { copy.withdrawn } label strings.withdrawRequirement
                    }
                }
            }

            save {
                if (copy.requiredAmount - pairedAmount < 0) {
                    snackbar(strings.negativeRequiredAmount)
                    return@save
                }
                if (commitFun(copy)) {
                    reload()
                    close()
                }
            }
        }
    }

    // ------------------------------------------------------------------------
    // Actions - Payment
    // ------------------------------------------------------------------------

    fun addPayment() {
        val pmt = IncomingPayment().apply {
            proceeding = bundle.proceeding.uuid
            valueDate = hereAndNow().date
        }

        modal {
            title(strings.addIncomingPayment.localized)

            body {
                grid(gridGap16) {
                    field { pmt.valueDate }
                    participation(bundle.incomingParticipations) { pmt.participation }
                    field { pmt.paymentAmount }
                }
            }

            save {
                ifValidForCreate(pmt) {
                    financeService.add(it)
                    reload()
                    close()
                }
            }
        }
    }

    // ------------------------------------------------------------------------
    // Actions - Pair
    // ------------------------------------------------------------------------

    suspend fun addPair() {
        if (selectedRequirements.isEmpty()) {
            snackbar(strings.noRequirementSelected)
            return
        }
        if (selectedPayments.isEmpty()) {
            snackbar(strings.noPaymentSelected)
            return
        }

        if (selectedRequirements.size > 1 || selectedPayments.size > 1) {
            snackbar(strings.oneItemMayBeSelected)
            return
        }

        val requirement = selectedRequirements.first().data
        val payment = selectedPayments.first().data

        val pairedAmount = min(requirement.remainingAmount, payment.remainingAmount)

        val pair = IncomingPair().also {
            it.proceeding = bundle.proceeding.uuid
            it.requirement = requirement.uuid
            it.payment = payment.uuid
            it.pairedAmount = pairedAmount
        }

        financeService.add(pair)
        reload()
    }

    private fun removePair(pair: IncomingPair) {
        modal {
            title(strings.breakPair.localized)

            body {
                +strings.confirmBreakPair
            }

            save(strings.yes) {
                financeService.removeIncoming(pair.uuid)
                reload()
                close()
            }
        }
    }

    // ------------------------------------------------------------------------
    // Helpers
    // ------------------------------------------------------------------------

    suspend fun reload() {
        bundle = financeService.bundle(bundle.proceeding.uuid)
        selectedRequirements.clear()
        selectedPayments.clear()
        clear()
        main()
    }

    val UUID<IncomingRequirement>.data
        get() = requirements.first { it.uuid == this }

    val UUID<IncomingPayment>.data
        get() = payments.first { it.uuid == this }
}