package hu.mkik.vb.portal.ui.proceeding.participation.util

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.material.snackbar.warningSnackbar

fun rankCollision(participations: List<Participation>, participation: Participation): Boolean {

    val ranks = participations
        .filter { (it.uuid != participation.uuid) && (it.proceedingRole == participation.proceedingRole) }
        .map { it.rank }

    val rankedRoles = listOf(
        currentProceeding.settings.claimantRole,
        currentProceeding.settings.respondentRole
    )

    if (participation.proceedingRole in rankedRoles && participation.rank in ranks) {
        warningSnackbar(strings.sameRankParticipantExists)
        return true
    }

    return false
}

fun duplicatedPartner(participations: List<Participation>, participation: Participation): Boolean {
    participations.firstOrNull {
        //println("${it.mpaid} ${participation.mpaid} $it")
        it.mpaid == participation.mpaid
    } ?: return false
    warningSnackbar(strings.sameMpaidParticipantExists)
    return true
}
