package hu.mkik.vb.portal.ui.administration.softwareUpdate

import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.sysAdminService
import hu.mkik.vb.portal.ui.util.loading
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.pb16
import hu.simplexion.z2.browser.css.w304
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.pre
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.localization.locales.localeCapitalized

object SoftwareUpdate : NavRouter(strings.softwareUpdate, icons.softwareUpdate, useParentNav = true, default = { update() })

private fun Z2.update() =
    surfaceContainerLowest(borderOutline) {

        title(strings.softwareUpdate.localeCapitalized)

        loading {
            val activeRelease = sysAdminService.activeRelease()
            val clearDatabaseScript = sysAdminService.clearDatabaseScript()

            div(w304) {
                div(pb16) { + strings.activeRelease.localeCapitalized }
                field { activeRelease.version } readOnly true
                field { activeRelease.releaseDate } readOnly true
                field { activeRelease.commit } readOnly true
            }

            pre {
                +clearDatabaseScript
            }
//                val policy = authAdminService.getPolicy()
//
//                with(policy) {
//                    field { passwordChangeInterval }
//                    field { passwordHistoryLength }
//                    field { passwordLengthMinimum }
//                    field { uppercaseMinimum }
//                    field { digitMinimum }
//                    field { specialCharacterMinimum }
//                    field { sameCharacterMaximum }
//                    field { minEntropy }
//                    field { maxFailedAuths }
//                }
//
//                filledLaunchButton(strings.save) {
//                    authAdminService.setPolicy(policy)
//                    snackbar(strings.saveSuccess)
//                }
        }
    }