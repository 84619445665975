package hu.mkik.vb.portal.model.finance

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.ProceedingRole
import hu.mkik.vb.portal.model.ProceedingSettings
import hu.simplexion.z2.schematic.Schematic

class ProceedingFinanceBundle : Schematic<ProceedingFinanceBundle>() {

    var proceeding by schematic<Proceeding>()
    var settings by schematic<ProceedingSettings>()
    var roles by schematicList<ProceedingRole>() // FIXME schematic companion not found, replae with Role

    var participations by schematicList<Participation>()

    var bankTransactions by schematicList<BankTransaction>()

    var incomingPairs by schematicList<IncomingPair>()
    var incomingPayments by schematicList<IncomingPayment>()
    var incomingRequirements by schematicList<IncomingRequirement>()

    var outgoingPairs by schematicList<OutgoingPair>()
    var outgoingPayments by schematicList<OutgoingPayment>()
    var outgoingRequirements by schematicList<OutgoingRequirement>()

    var expenses by schematicList<Expense>()
    
}