package hu.mkik.vb.portal.ui.report

import hu.mkik.vb.portal.model.report.YearSummaryItem
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.overflowHidden
import hu.simplexion.z2.browser.css.positionRelative
import hu.simplexion.z2.browser.field.stereotype.longField
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.modal.modal
import hu.simplexion.z2.util.hereAndNow
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.text.StaticText
import hu.simplexion.z2.localization.text.monthNameTable
import hu.simplexion.z2.localization.text.quarterNameTable
import hu.simplexion.z2.localization.withLocalization
import hu.simplexion.z2.xlsx.browser.onExportXlsx

fun Z2.yearlySummary() =
    surfaceContainerLowest(borderOutline) {
        modal {
            var year = hereAndNow().date.year
            var initialReserveFund = 0L

            title(strings.monthlySummaryReport)
            body {
                yearSelect { year = it }
                longField(initialReserveFund, strings.initialReserveFund) { initialReserveFund = it }
            }
            save(strings.ok) {
                this@surfaceContainerLowest.run(year, initialReserveFund)
                close()
            }
        }
    }

private fun Z2.run(year: Int, initialReserveFund : Long) {
    lateinit var table: Table<YearSummaryItem>

    grid {
        gridConfig("1fr", "min-content 1fr")
        addCss(overflowHidden)

        div {
            title(strings.yearlySummaryReport.localeCapitalized + " - " + year) {
                filledLaunchButton(strings.download) {
                    table.onExportXlsx()
                }
            }
        }

        div(positionRelative) {
            style.overflowX = "scroll"

            div {
                table<YearSummaryItem> {
                    table = this.table

                    rowId = { it.period }
                    query = { proceedingService.yearSummary(year, initialReserveFund) }

                    withLocalization(reportStrings) {
                        with(YearSummaryItem()) {
                            column {
                                label = StaticText("", "$year")
                                render = { + periodName(it.period) }
                                initialSize = "min-content"
                                exportFun = { + periodName(it.period) }
                                exportHeader = label
                            }
                            schematicColumn { closedCases }
                            schematicColumn { registrationFee }
                            schematicColumn { paidArbitrationFee }
                            schematicColumn { administrationFee }
                            schematicColumn { reserveFund }
                            schematicColumn { honorarium }
                            schematicColumn { levy }
                            schematicColumn { refund }
                            schematicColumn { openedCases }
                            schematicColumn { accumulatedReserveFund }
                        }
                    }
                }
            }
        }
    }
}

fun periodName(value : Int) =
    when (value) {
        0 -> ""
        in 1..12 -> monthNameTable[value - 1].toString()
        in 13 .. 16 -> quarterNameTable[value - 13].toString()
        17 -> strings.total.toString()
        else -> throw IllegalStateException("invalid period value: $value")
    }