package hu.mkik.vb.portal.model

import hu.simplexion.z2.schematic.Schematic

class ProceedingEvent : Schematic<ProceedingEvent>() {
    val uuid by uuid<ProceedingEvent>(true)

    var proceeding by uuid<Proceeding>()

    var createdAt by instant()
    var createdBy by uuid<AccountVB>()

    var type by enum<ProceedingEventType>()
    var additionalInfo by string()

    var document by uuid<Document>().nullable()
    var documentName by string().nullable()
    var documentType by uuid<DocumentType>().nullable()

    var participation by uuid<Participation>().nullable()

    var task by uuid<Task>().nullable()
    var deadline by localDate().nullable()
    var note by string().nullable()
}