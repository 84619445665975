package hu.mkik.vb.portal.ui.bank.pages

import hu.mkik.vb.portal.model.finance.BankStatement
import hu.mkik.vb.portal.ui.bank.modals.uploadStatement
import hu.mkik.vb.portal.ui.bankService
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.loading
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledButton
import hu.simplexion.z2.localization.locales.localeCapitalized

fun Z2.statements() =
    surfaceContainerLowest(borderOutline) {
        loading {
            grid {
                gridConfig("1fr", "min-content 1fr")

                title(
                    strings.statements.localeCapitalized,
                    leftActions = {
                        filledButton(strings.upload) { uploadStatement() }
                    }
                )

                div {
                    table<BankStatement> {
                        rowId = { it.statementId }
                        query = { bankService.statements() }

                        with(BankStatement()) {
                            schematicColumn { statementId }
                            schematicColumn { startDate }
                            schematicColumn { endDate }
                            schematicColumn { creditCount }
                            schematicColumn { creditAmount }
                            schematicColumn { remainingCredit }
                            schematicColumn { debitCount }
                            schematicColumn { debitAmount }
                            schematicColumn { remainingDebit }
                        }
                    }
                }
            }
        }
    }