package hu.mkik.vb.portal.ui.account.pages

import hu.mkik.vb.portal.ui.accountVBService
import hu.mkik.vb.portal.ui.component.NewPassword
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.auth.model.Credentials
import hu.simplexion.z2.auth.model.Principal
import hu.simplexion.z2.auth.principalService
import hu.simplexion.z2.auth.sessionService
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.routing.Router
import hu.simplexion.z2.browser.routing.isLoggedIn
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.util.localLaunch
import io.ktor.http.*
import kotlinx.browser.window
import mainRouter

object Activation : Router<Z2>(loggedIn = false) {
    val uuid by uuid<Principal>()
    val key by string()

    override fun default(receiver: Z2, path: List<String>) {
        receiver.activation()
    }
}

private fun Z2.activation() {
    surfaceContainerLowest(displayFlex, alignItemsCenter, justifyContentCenter) {
        localLaunch {
            val activationKey = Credentials().also {
                it.principal = Activation.uuid
                it.value = Activation.key.decodeURLPart() // FIXME decodeURLPart handling
            }

            val active = accountVBService.canActivate(activationKey)

            grid(gridGap8) {
                gridTemplateColumns = "min-content"
                gridAutoRows = "min-content"

                div(p24, borderRadius8, borderOutline) {
                    grid(gridGap32) {
                        gridTemplateColumns = 400.px
                        gridAutoRows = "min-content"

                        img(w400) {
                            src = "/logo.jpg"
                        }

                        if (!active) {
                            div(titleLarge, justifySelfCenter, pb24) {
                                text { strings.expiredActivationKey }
                            }
                        } else {

                            div(titleLarge, justifySelfCenter) {
                                text { strings.accountActivation.localeCapitalized }
                            }

                            val newPassword = NewPassword(this, false).main()

                            grid {
                                gridTemplateColumns = "1fr min-content"
                                gridTemplateRows = "min-content"
                                gridGap = 24.px

                                style.position = "relative"
                                style.left = "-8px"

                                div { }

                                filledLaunchButton(strings.ok) {
                                    if (!newPassword.valid()) return@filledLaunchButton

                                    try {
                                        if (isLoggedIn) {
                                            sessionService.logout()
                                            window.location.reload()
                                        }

                                        principalService.activate(
                                            Credentials().apply { value = newPassword.newPassword },
                                            activationKey
                                        )

                                        mainRouter.login.open()

                                    } catch (ex: Exception) {
                                        snackbar(strings.error)
                                    }
                                }
                            }
                        }
                    }
                }

                grid {
                    gridTemplateColumns = "1fr repeat(3, min-content)"
                    gridTemplateRows = "min-content"
                    gridGap = 16.px

                    textButton(strings.english) { }
                    textButton(strings.help) { }
                    textButton(strings.privacy) { }
                    textButton(strings.term) { }
                }
            }
        }
    }
}
