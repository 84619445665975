package hu.mkik.vb.portal.ui.component

import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.modal.Modals
import hu.simplexion.z2.browser.material.vh
import hu.simplexion.z2.browser.material.vw
import hu.simplexion.z2.deprecated.browser.CssClass
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.text.LocalizedText

fun modal(vararg cssClasses: CssClass, builder: Z2.() -> Unit) {
    Modals += Z2(classes = cssClasses) {
        gridTemplateColumns = "1fr"
        gridTemplateRows = "min-content 1fr"

        style.maxHeight = 98.vh
        style.maxWidth = 98.vw

        addCss(
            boxSizingBorderBox,
            onSurfaceText,
            surface,
            borderRadius12,
            displayGrid,
            positionRelative,
            overflowHidden
        )

        builder()
    }
}

fun Z2.modalHeader(title: LocalizedText) {
    div(pb16, pt16, displayFlex, alignItemsCenter, headlineSmall, borderBottomOutlineVariant) {
        span(pl24, pr24) { +title.localeCapitalized }
    }
}

fun Z2.modalFooter(insideFun: Z2.() -> Unit) {
    div(borderTopOutlineVariant) {
        grid(
            pl16,
            pr16,
            pt12,
            pb12,
            gridAutoFlowColumn,
            gridAutoColumnsMinContent,
            gridGap16,
            justifyContentSpaceBetween
        ) {
            insideFun()
        }
    }
}

fun Z2.modalOk() {
    modalFooter {
        div { }
        filledLaunchButton(browserStrings.ok) {
            Modals.close()
        }
    }
}

fun Z2.modalClose() {
    modalFooter {
        div { }
        filledLaunchButton(browserStrings.ok) {
            Modals.close()
        }
    }
}