package hu.mkik.vb.portal.ui.component

import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.field.FieldState
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.schematic.BoundField
import hu.simplexion.z2.browser.immaterial.select.SelectBase
import hu.simplexion.z2.browser.immaterial.select.SelectConfig
import hu.simplexion.z2.localization.label
import hu.simplexion.z2.schematic.access.SchematicAccessContext
import hu.simplexion.z2.schematic.schema.SchemaField

fun <T> Z2.selectField(
    options: List<T>,
    itemTextFun : (T) -> String,
    itemContentFun: Z2.(T) -> Unit,
    afterChange: (T) -> Unit,
    context: SchematicAccessContext
): BoundField<T> {

    val field = context.field as SchemaField<T>
    val label = field.label(context.schematic)

    return BoundField(this, context) {
        SelectBase(
            this,
            FieldState().also {
                it.label = label.toString() // FIXME localizedText.toString
            },
            SelectConfig<T>().apply {
                this.itemTextFun = itemTextFun
                this.itemContentFun = itemContentFun
                trailingIcon = browserIcons.down
                this.options = options
                onChange = {
                    field.setValue(context.schematic, it.value)
                    afterChange(it.value)
                }
            }
        ).main().apply {
            valueOrNull = field.getValue(context.schematic)
        }
    }
}