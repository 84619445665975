package hu.mkik.vb.portal.ui.proceeding.participation.components

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.RoleCandidate
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.schematic.field

fun Z2.retired(roleCandidate: RoleCandidate, copy: Participation) =
    div().attach(roleCandidate) {
        val arbitrators = with(currentProceeding.settings) {
            listOf(chairmanRole, claimantArbitratorRole, respondentArbitratorRole)
        }
        if (roleCandidate.role.uuid in arbitrators) {
            field { copy.retired }
        } else {
            copy.retired = false
        }
    }