package hu.mkik.vb.portal.model

import hu.simplexion.z2.schematic.Schematic

class AccountVB : Schematic<AccountVB>() {
    var uuid by uuid<AccountVB>(true)

    var accountType by enum<AccountVBType>()
    var mpaid by int()

    var fullName by string(100) minLength 5 blank false
    var email by email()
    var phone by string()
    var officePhone by string()
    var fax by string()
    var nationality by string(50)
    var profession by string(100)
    var qualification by string(100)
    var languageSkills by string(100)
    var section by string(100)
    var scientificDegree by string(100)
    var workplace by string(100)
    var job by string(100)
    var position by string(100)
    var retiredJudge by boolean()
    var taxId by string(20)
    var socialSecurityNumber by string(20)
    var mothersName by string(50)
    var birthName by string(50)
    var dateOfBirth by localDate()
    var placeOfBirth by string(100)
    var address by string(150)
    var postalAddress by string(150)
    var transferAddress by string(150)
    var companyRegistrationNumber by string(50)
    var euTaxId by string(20)
    var note by string(250)
}