package hu.mkik.vb.portal.ui.administration.locales.modals

import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.adaptive.modal.modal
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.localization.localeService
import hu.simplexion.z2.localization.model.Locale

fun localeEditor(locale: Locale?) =
    modal {

        val copy = locale?.copy() ?: Locale()

        title(strings.addLocale.localized)

        body {
            field { copy.isoCode }
            field { copy.countryCode }
            field { copy.nativeName }
            field { copy.visible }
            field { copy.priority }
        }

        save {
            if (!copy.touch()) return@save
            if (copy.uuid.isNil) {
                localeService.add(copy)
            } else {
                localeService.update(copy)
            }
            close()
        }

    }