package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.DocumentPermission
import hu.mkik.vb.portal.model.FolderType
import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.ProceedingType
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface FolderTypeApi : Service {

    suspend fun add(folder: FolderType) : UUID<FolderType>

    suspend fun update(folder: FolderType)

    suspend fun remove(folder: UUID<FolderType>)

    suspend fun query(): List<FolderType>

    suspend fun query(proceedingType: ProceedingType) : List<FolderType>

    // TODO move this into ProceedingImpl
    suspend fun queryUploadPermissions(proceeding: UUID<Proceeding>): List<DocumentPermission>

}