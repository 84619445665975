package hu.mkik.vb.portal.ui.administration.space

import hu.mkik.vb.portal.model.Space
import hu.mkik.vb.portal.ui.secretary
import hu.mkik.vb.portal.ui.spaceService
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.localLaunch
import hu.simplexion.z2.localization.locales.localeCapitalized
import mainRouter

object EditSpace : NavRouter(useParentNav = true, visibility = secretary) {
    val uuid by uuid<Space>()
    override var default: Z2Builder = { edit(uuid) }
}

object AddSpace : NavRouter(useParentNav = true, visibility = secretary) {
    override var default: Z2Builder = { edit(UUID.nil()) }
}

internal fun Z2.edit(uuid: UUID<Space>) {
    localLaunch {
        val add = (uuid == UUID.nil<Space>())
        val space = if (add) Space() else spaceService.get(uuid)

        surfaceContainerLowest(borderOutline, p24) {
            div(w400) {
                div(titleLarge, pb24) {
                    text { if (add) strings.addSpace.localeCapitalized else strings.editSpace.localeCapitalized }
                    if (! add) {
                        div(pt4, bodySmall, onSurfaceVariantText) { text { space.uuid } }
                    }
                }

                field { space.name }
                field { space.address }
                field { space.description }
                field { space.active }

                grid(pt24, gridGap16) {
                    gridTemplateColumns = "1fr min-content"

                    textButton(strings.cancel) { mainRouter.back() } css justifySelfEnd

                    filledLaunchButton(strings.save) {
                        space.touch()

                        val valid = if (add) space.isValidForCreate else space.isValid

                        if (! valid) {
                            snackbar(strings.invalidFields)
                            return@filledLaunchButton
                        }

                        if (add) {
                            spaceService.add(space)
                            snackbar(strings.createSuccess)
                            mainRouter.back()
                        } else {
                            spaceService.update(space)
                            snackbar(strings.saveSuccess)
                            mainRouter.back()
                        }
                    }
                }
            }
        }
    }
}