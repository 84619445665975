package hu.mkik.vb.portal.ui.administration.account.pages

import hu.mkik.vb.portal.model.runtime.AccountSummaryEntry
import hu.mkik.vb.portal.ui.accountVBService
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.secretaryOrTechnical
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.routing.NavRouter

object AccountList : NavRouter(strings.accounts, icons.accounts, useParentNav = true, visibility = secretaryOrTechnical, default = { list() }) {
    val add by AddAccount
    val edit by EditAccount
}

private fun Z2.list() =
    tablePage<AccountSummaryEntry> {

        header {
            text = strings.accounts
            search()
            action(strings.addAccount) { AddAccount.open() }
        }

        rowId = { it.uuid }
        query = { accountVBService.list() }
        doubleClickFun = { EditAccount.open(it.uuid) }

        with(AccountSummaryEntry()) {
            schematicColumn { mpaid } initialSize "min-content"
            schematicColumn { fullName }
            schematicColumn { email }
            schematicColumn { phone }
            schematicColumn { accountType }
            schematicColumn { activated } initialSize "min-content"
            schematicColumn { locked } initialSize "min-content"
            schematicColumn { lastLogin }
        }

        actionColumn {
            action {
                label = browserStrings.details
                handler = { EditAccount.open(it.uuid) }
            }
        }

    }