package hu.mkik.vb.portal.ui.proceeding.finance.components

import hu.mkik.vb.portal.model.FeeType
import hu.mkik.vb.portal.model.finance.*
import hu.mkik.vb.portal.ui.component.*
import hu.mkik.vb.portal.ui.financeService
import hu.mkik.vb.portal.ui.proceeding.feeTable
import hu.mkik.vb.portal.ui.proceeding.finance.modals.OutgoingProposal
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.ifValid
import hu.mkik.vb.portal.ui.util.ifValidForCreate
import hu.mkik.vb.portal.ui.util.plusDays
import hu.mkik.vb.portal.ui.util.tertiaryContainer
import hu.simplexion.z2.adaptive.modal.modal
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.field.FieldState
import hu.simplexion.z2.browser.field.stereotype.decimalField
import hu.simplexion.z2.browser.field.stereotype.longField
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.browser.immaterial.table.builders.size
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLow
import hu.simplexion.z2.browser.material.button.filledButton
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.browser.material.icon.icon
import hu.simplexion.z2.browser.material.menu.dropdownMenu
import hu.simplexion.z2.browser.material.menu.menuItem
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.switch.SwitchConfig
import hu.simplexion.z2.browser.material.switch.SwitchField
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.locales.localized
import hu.simplexion.z2.localization.localized
import hu.simplexion.z2.localization.text.LocalizedText
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.hereAndNow
import kotlin.math.max
import kotlin.math.min

class OutgoingFinance(
    parent: Z2,
    var bundle: ProceedingFinanceBundle
) : Z2(parent, classes = arrayOf(displayGrid)) {

    val selectedRequirements = mutableSetOf<UUID<OutgoingRequirement>>()
    val selectedPayments = mutableSetOf<UUID<OutgoingPayment>>()

    val requirements
        get() = bundle.outgoingRequirements

    val payments
        get() = bundle.outgoingPayments

    val pairs
        get() = bundle.outgoingPairs

    lateinit var pairsTable: Table<OutgoingPair>

    override fun main(): OutgoingFinance {
        gridConfig("1fr", "repeat(4,min-content)")
        columnGap = 24.px

        requirements()
        payments()
        pairingAction()
        pairs()

        return this
    }

    fun Z2.requirements() =
        grid("1fr", "min-content 1fr") {

            requirementsHeader()

            table<OutgoingRequirement> {

                fixRowHeight = false
                noScroll = true
                rowId = { it.uuid }
                data = requirements.filter { it.remainingAmount > 0L }
                doubleClickFun = { editRequirement(it) }

                with(OutgoingRequirement()) {
                    schematicColumn { deadline } size 8.em
                    participationColumn(bundle.participations) { participation }
                    schematicColumn { type } initialSize "max-content"
                    schematicColumn { requiredAmount }
                    schematicColumn { remainingAmount } label strings.notPaidYet
                    schematicColumn { counter } label strings.counterClaimAbrv initialSize 3.em
                    column {
                        initialSize = "min-content"
                        render = { row ->
                            SwitchField(
                                this,
                                FieldState(),
                                config = SwitchConfig().also {
                                    it.onChange = { switch ->
                                        if (switch.value) {
                                            selectedRequirements.add(row.uuid)
                                        } else {
                                            selectedRequirements.remove(row.uuid)
                                        }
                                        pairsTable.redraw()
                                    }
                                }
                            ).main().value = (row.uuid in selectedRequirements)
                            addCss(pr12)
                        }
                    }
                }
            } css borderOutline
        }

    fun Z2.requirementsHeader() =
        grid("1fr min-content", "32px", gridGap16, mb12) {
            div(alignSelfCenter) { +strings.outgoingRequirements.localeCapitalized }
            div(alignSelfCenter) {
                filledButton(strings.actions) { }.apply {
                    dropdownMenu {
                        menuItem(1, label = strings.addItem) { addRequirement() }
                        menuItem(3, label = strings.claimPayments) { OutgoingProposal(bundle, false) { reload() } }
                        menuItem(3, label = strings.counterClaimPayments) {
                            OutgoingProposal(
                                bundle,
                                true
                            ) { reload() }
                        }
                    }
                }
            }
        }

    fun Z2.payments() =
        grid("1fr", "min-content 1fr") {

            paymentsHeader()

            table<OutgoingPayment> {

                fixRowHeight = false
                noScroll = true
                rowId = { it.uuid }
                data = payments.filter { it.remainingAmount > 0L }

                with(OutgoingPayment()) {
                    schematicColumn { valueDate }
                    participationColumn(bundle.participations) { participation } initialSize "1fr"
                    schematicColumn { paymentAmount }
                    schematicColumn { remainingAmount }
                    column {
                        initialSize = "min-content"
                        render = { row ->
                            SwitchField(
                                this,
                                FieldState(),
                                config = SwitchConfig().also {
                                    it.onChange = { switch ->
                                        if (switch.value) {
                                            selectedPayments.add(row.uuid)
                                        } else {
                                            selectedPayments.remove(row.uuid)
                                        }
                                        pairsTable.redraw()
                                    }
                                }
                            ).main().value = (row.uuid in selectedPayments)
                        }
                    }
                }
            } css borderOutline
        }

    fun Z2.paymentsHeader() =
        grid("1fr min-content", "32px", pt24, mb12, gridGap16) {

            div(alignSelfCenter) { +strings.outgoingPayments.localeCapitalized }
            div(alignSelfCenter) {
                filledButton(strings.actions) { }.apply {
                    dropdownMenu {
                        menuItem(1, label = strings.addOutgoingPayment) { addPayment() }
                    }
                }
            }
        }

    fun Z2.pairingAction() =
        div(alignSelfCenter, justifySelfCenter, mt24) {
            style.marginBottom = "-32px"
            style.zIndex = "100"

            filledLaunchButton(strings.pairing) {
                addPair()
            }
        }

    fun Z2.pairs() =
        grid("1fr", "min-content 1fr", pt16) {

            pairsHeader()

            table<OutgoingPair> {
                pairsTable = table

                fixRowHeight = false
                noScroll = true
                rowId = { it.uuid }
                data = pairs

                column {
                    label = strings.deadline
                    render = {
                        div(bodyMedium, displayFlex, alignItemsCenter, justifyContentCenter, p8) {
                            style.marginLeft = (-8).px
                            if (it.requirement in selectedRequirements) addCss(tertiaryContainer, borderRadius8)
                            +it.requirement.data.deadline.localized
                        }
                    }
                    initialSize = "min-content"
                }
                column {
                    label = strings.participation
                    render = {
                        div {
                            it.requirement.data.participation.let { p ->
                                div(whiteSpaceNoWrap, bodyMedium) { +participationName(bundle.participations, p) }
                                div(whiteSpaceNoWrap, bodySmall) { +participationType(bundle.participations, p) }
                            }
                        }
                    }
                    initialSize = "1fr"
                }
                column {
                    label = strings.type
                    render = {
                        addCss(bodyMedium, whiteSpaceNoWrap)
                        +it.requirement.data.type.localized
                    }
                    initialSize = "min-content"
                }
                column {
                    label = strings.valueDate
                    render = {
                        div(displayFlex, bodyMedium, alignItemsCenter, justifyContentCenter, pl24) {
                            if (it.payment in selectedPayments) addCss(tertiaryContainer, borderRadius8)
                            if (it.payment != null) {
                                +it.payment?.data?.valueDate?.localized
                            }
                        }
                    }
                    initialSize = 9.em
                }
                column {
                    label = strings.participation
                    render = {
                        if (it.payment != null) {
                            div {
                                it.payment!!.data.participation.let { p ->
                                    div(whiteSpaceNoWrap, bodyMedium) { +participationName(bundle.participations, p) }
                                    div(whiteSpaceNoWrap, bodySmall) { +participationType(bundle.participations, p) }
                                }
                            }
                        } else {
                            div(bodyMedium) { +strings.withdrawnRequirement }
                        }
                    }
                    initialSize = "1fr"
                }
                column {
                    label = strings.amount
                    render = {
                        addCss(justifyContentFlexEnd, pr12, bodyMedium)
                        +it.pairedAmount.localized
                    }
                    initialSize = 8.em
                }
                column {
                    label = strings.counterClaimAbrv
                    render = { if (it.requirement.data.counter) icon(browserIcons.check) }
                    initialSize = 3.em
                }
                actionColumn {
                    action {
                        label = strings.breakPair
                        handler = { removePair(it) }
                    }
                    initialSize = "6em"
                }
            } css borderOutline
        }

    fun Z2.pairsHeader() =
        grid(mb12) {
            gridTemplateColumns = "1fr min-content"
            gridTemplateRows = "32px"
            gridGap = 16.px

            div(alignSelfCenter) { +strings.paidOutgoingRequirements.localeCapitalized }
            div { }
        }

    // ------------------------------------------------------------------------
    // Actions - Fee
    // ------------------------------------------------------------------------

    fun addRequirement() {
        requirementEditor(
            strings.addItem,
            strings.add,
            OutgoingRequirement().apply {
                proceeding = bundle.proceeding.uuid
                deadline = hereAndNow().date
            }
        ) { req ->
            ifValidForCreate(req) {
                financeService.add(it)
            }
        }
    }

    fun editRequirement(original: OutgoingRequirement) {
        requirementEditor(
            strings.edit,
            strings.save,
            original.copy()
        ) { req ->
            if (req.withdrawn && req.requiredAmount != req.remainingAmount) {
                snackbar(strings.cannotWithdrawPaired)
                return@requirementEditor false
            }

            ifValid(req) {
                if (req.withdrawn) {
                    financeService.withdraw(it)
                } else {
                    financeService.update(it)
                }
            }
        }

    }

    fun requirementEditor(
        dialogTitle: LocalizedText,
        commitTitle: LocalizedText,
        req: OutgoingRequirement,
        commitFun: suspend (req: OutgoingRequirement) -> Boolean
    ) {
        modal {
            style.minWidth = 600.px
            val copy = req.copy()
            val pairedAmount = req.requiredAmount - req.remainingAmount

            title(dialogTitle.localeCapitalized)

            body {
                grid("minmax(400px,1fr)", "minmax(0,1fr)", pt16, pb16, pb24, gridGap16, heightMinContent) {
                    field { copy.type }
                    participation(bundle.participations) { copy.participation }
                    field { copy.deadline }
                    field { copy.requiredAmount }
                    if (!copy.uuid.isNil) {
                        div(displayFlex, flexDirectionRow).attach(copy) {
                            decimalField(pairedAmount, 0, strings.alreadyPaidAmount) { }.also {
                                it.state.readOnly = true
                                it.state.label = strings.alreadyPaidAmountOut.localized
                            }
                            div(p24) { }
                            decimalField(copy.requiredAmount - pairedAmount, 0, strings.remainingAmount) { }.also {
                                it.state.readOnly = true
                                if (copy.requiredAmount - pairedAmount < 0) {
                                    it.state.toError(strings.negativeRequiredAmount)
                                } else {
                                    it.state.error = false
                                }
                            }
                        }
                        field { copy.withdrawn } label strings.withdrawRequirement
                    }
                    field { copy.counter } label strings.counterClaim
                }
            }

            save {
                if (copy.requiredAmount - pairedAmount < 0) {
                    snackbar(strings.negativeRequiredAmount)
                    return@save
                }
                if (commitFun(copy)) {
                    reload()
                    close()
                }
            }
        }
    }

    // ------------------------------------------------------------------------
    // Actions - Claim Value
    // ------------------------------------------------------------------------

    fun claimValue() {
        modal {
            title(strings.claimValue)

            val copy = bundle.proceeding.copy()
            val arbitrationFees = requirements.filter { it.type == OutgoingRequirementType.ArbitratorsHonorarium }
            val currentArbitrationFee = if (arbitrationFees.isEmpty()) 0 else bundle.proceeding.claimValue
            val proposedArbitrationFees = mutableListOf<OutgoingRequirement>()

            body {

                surfaceContainerLow(borderOutline, mb24) {
                    div(pb16) { +strings.alreadyAddedItems.localeCapitalized }

                    longField(
                        currentArbitrationFee,
                        label = strings.claimValue
                    ) { }.state.readOnly = true

                    outgoingRequirementSummary(arbitrationFees, true)
                }

                surfaceContainerLow(borderOutline) {
                    div(pb16) { +strings.new.localeCapitalized }
                    field { copy.claimValue }

                    div().attach(copy) {
                        val difference = copy.claimValue - currentArbitrationFee

                        decimalField(
                            difference,
                            scale = 0,
                            label = strings.amountChange
                        ) { }.state.readOnly = true

                        arbitrationFeeProposal(
                            arbitrationFees,
                            copy.claimValue,
                            difference,
                            proposedArbitrationFees
                        )
                    }
                }
            }

            save {
                proceedingService.setClaimValue(copy.uuid, copy.claimValue)
                for (proposed in proposedArbitrationFees) {
                    financeService.add(proposed)
                }
                reload()
                close()
            }
        }
    }

    fun Z2.outgoingRequirementSummary(inData: List<OutgoingRequirement>, showRemainingAmount: Boolean) {
        table<OutgoingRequirement> {
            rowId = { it.uuid }
            data = inData
            with(OutgoingRequirement()) {
                schematicColumn { deadline }
                participationNameColumn(bundle.participations) { participation }
                participationTypeColumn(bundle.participations) { participation }
                schematicColumn { requiredAmount }
                if (showRemainingAmount) {
                    schematicColumn { remainingAmount }
                }
            }
        }
    }

    fun Z2.arbitrationFeeProposal(
        arbitrationFees: List<OutgoingRequirement>,
        newValue: Long,
        difference: Long,
        proposal: MutableList<OutgoingRequirement>
    ) {
        val fee = feeTable
            .filter { it.proceedingType == bundle.proceeding.type && it.type == FeeType.AdministrationFee }
            .sortedBy { it.subjectValueLimit }
            .sortedBy { it.validFrom }
            .lastOrNull { it.subjectValueLimit <= newValue }

        if (fee == null) {
            snackbar(strings.feeCalculationError)
            return
        }

        val alreadyRequiredAmount = arbitrationFees.sumOf { it.requiredAmount }
        val newFeeAmount = max(fee.minimumFee, fee.baseFee + (newValue - fee.subjectValueLimit) * fee.percent / 100_000)

        val (claimantShare, respondentShare) = when {
            bundle.proceeding.expedited -> (100 to 0)
            newValue < 5_000_000 -> (100 to 0) // FIXME move this into proceeding settings
            else -> (50 to 50)
        }

        val feeDifference = newFeeAmount - alreadyRequiredAmount

        val claimantAmount = claimantShare * feeDifference / 100
        val respondentAmount = respondentShare * feeDifference / 100

        proposal.clear()

        if (claimantAmount > 0) {
            proposal += OutgoingRequirement().also {
                it.proceeding = bundle.proceeding.uuid
                it.type = OutgoingRequirementType.ArbitratorsHonorarium
                it.deadline = hereAndNow().plusDays(15).date
                it.participation = participantOf(bundle.settings.claimantRole)
                it.requiredAmount = claimantAmount
                it.remainingAmount = it.requiredAmount
            }
        }

        if (respondentAmount > 0) {
            proposal += OutgoingRequirement().also {
                it.proceeding = bundle.proceeding.uuid
                it.type = OutgoingRequirementType.ArbitratorsHonorarium
                it.deadline = hereAndNow().plusDays(15).date
                it.participation = participantOf(bundle.settings.respondentRole)
                it.requiredAmount = respondentAmount
                it.remainingAmount = it.requiredAmount
            }
        }

        outgoingRequirementSummary(proposal, false)
    }

    fun participantOf(role: UUID<Role>) =
        bundle.participations.firstOrNull { p -> p.proceedingRole == role }?.uuid ?: UUID.nil()

    // ------------------------------------------------------------------------
    // Actions - Payment
    // ------------------------------------------------------------------------

    fun addPayment() {
        val pmt = OutgoingPayment().apply {
            proceeding = bundle.proceeding.uuid
            valueDate = hereAndNow().date
        }

        modal {
            title(strings.addOutgoingPayment)

            body {
                grid(gridGap16) {
                    field { pmt.valueDate }
                    participation(bundle.participations) { pmt.participation }
                    field { pmt.paymentAmount }
                }
            }

            save {
                ifValidForCreate(pmt) {
                    financeService.add(it)
                    reload()
                    close()
                }
            }
        }
    }

    // ------------------------------------------------------------------------
    // Actions - Pair
    // ------------------------------------------------------------------------

    suspend fun addPair() {
        if (selectedRequirements.isEmpty()) {
            snackbar(strings.noRequirementSelected)
            return
        }
        if (selectedPayments.isEmpty()) {
            snackbar(strings.noPaymentSelected)
            return
        }

        if (selectedRequirements.size > 1 || selectedPayments.size > 1) {
            snackbar(strings.oneItemMayBeSelected)
            return
        }

        val requirement = selectedRequirements.first().data
        val payment = selectedPayments.first().data

        val pairedAmount = min(requirement.remainingAmount, payment.remainingAmount)

        val pair = OutgoingPair().also {
            it.proceeding = bundle.proceeding.uuid
            it.requirement = requirement.uuid
            it.payment = payment.uuid
            it.pairedAmount = pairedAmount
        }

        financeService.add(pair)
        reload()
    }

    private fun removePair(pair: OutgoingPair) {
        modal {
            title(strings.breakPair)

            body {
                +strings.confirmBreakPair
            }

            save(strings.yes) {
                financeService.removeOutgoing(pair.uuid)
                reload()
                close()
            }
        }
    }

    // ------------------------------------------------------------------------
    // Helpers
    // ------------------------------------------------------------------------

    suspend fun reload() {
        bundle = financeService.bundle(bundle.proceeding.uuid)
        selectedRequirements.clear()
        selectedPayments.clear()
        clear()
        main()
    }

    val UUID<OutgoingRequirement>.data
        get() = requirements.first { it.uuid == this }

    val UUID<OutgoingPayment>.data
        get() = payments.first { it.uuid == this }
}