package hu.mkik.vb.portal.ui.administration.space

import hu.mkik.vb.portal.model.Space
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.secretary
import hu.mkik.vb.portal.ui.spaceService
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.backgroundTransparent
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.p0
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.container
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.routing.NavRouter

object SpaceList : NavRouter(
    strings.spaces, icons.meetingRoom,
    useParentNav = true, default = { list() },
    visibility = secretary
) {
    val add by AddSpace
    val edit by EditSpace
}

private fun Z2.list() =
    surfaceContainerLowest(borderOutline) {

        container(p0, backgroundTransparent, scroll = false) {

            table<Space> {

                header {
                    text = strings.spaces
                    action(strings.addSpace) { AddSpace.open() }
                }

                rowId = { it.uuid }
                query = { spaceService.list() }
                doubleClickFun = { EditSpace.open(it.uuid) }

                with (Space()) {
                    schematicColumn { name }
                    schematicColumn { address }
                    schematicColumn { description }
                    schematicColumn { active }
                }

                actionColumn {
                    action {
                        label = strings.edit
                        handler = { EditSpace.open(it.uuid) }
                    }
                }

            }
        }
    }