package hu.mkik.vb.portal.model.report

import hu.mkik.vb.portal.model.ProceedingCloseType
import hu.simplexion.z2.schematic.Schematic

class ProceedingRegisterItem : Schematic<ProceedingRegisterItem>() {
    var caseNumber by string()
    var secretary by string()
    var claimantName by string()
    var claimantCity by string()
    var claimantNames by string()
    var claimantAddresses by string()
    var respondentName by string()
    var respondentCity by string()
    var respondentNames by string()
    var respondentAddresses by string()
    var letterOfClaimantDate by localDate()
    var closeDate by localDate().nullable()
    var accountingDate by localDate().nullable()
    var closeType by enum<ProceedingCloseType>().nullable()
    var subject by string()
    var notes by string()
    var claimValue by decimal(scale = 0)
    var counterClaimValue by decimal(scale = 0)
    var arbitrationFee by decimal(scale = 0)
    var caArbitrationFee by decimal(scale = 0)
    var registrationFee by decimal(0)
    var registrationFeeDates by string()
    var arbitrationFeeDates by string()
    var paidArbitrationFees by decimal(scale = 0)
    var expenses by decimal(0)
    var expenseDates by string()
    var levy by decimal(scale = 0)
    var administrationFee by decimal(scale = 0)
    var chairmanHonorarium by decimal(scale = 0)
    var claimantArbitratorsHonorarium by decimal(scale = 0)
    var respondentArbitratorsHonorarium by decimal(scale = 0)
    var caLevy by decimal(scale = 0)
    var caAdministrationFee by decimal(scale = 0)
    var caChairmanHonorarium by decimal(scale = 0)
    var caClaimantArbitratorsHonorarium by decimal(scale = 0)
    var caRespondentArbitratorsHonorarium by decimal(scale = 0)
    var chairmanName by string()
    var claimantArbitratorName by string()
    var respondentArbitratorName by string()
    var claimantRefundAmount by decimal(0)
    var claimantAddress by string()
    var claimantAccountNumber by string()
    var respondentRefundAmount by decimal(0)
    var respondentAddress by string()
    var respondentAccountNumber by string()
    var contractDate by localDate().nullable()
    var reserveFund by decimal(scale = 0)
    var caReserveFund by decimal(scale = 0)
    var socialSecurityContribution by decimal(scale = 0)
    var caSocialSecurityContribution by decimal(scale = 0)
    var verification by decimal(scale = 0)
    var charimanHonorariumTotal by decimal(scale = 0)
    var claimantArbitratorsHonorariumTotal by decimal(scale = 0)
    var respondentArbitratorsHonorariumTotal by decimal(scale = 0)
    var totalLevy by decimal(scale = 0)
}