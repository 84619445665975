package hu.mkik.vb.portal.ui.proceeding.finance.pages

import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.financeService
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.feeTable
import hu.mkik.vb.portal.ui.proceeding.finance.components.OutgoingFinance
import hu.mkik.vb.portal.ui.proceedingFeeService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.loading
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.gridGap8
import hu.simplexion.z2.browser.css.pb8
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.layout.surfaceContainerHighest

fun Z2.outgoingFinance() =
    surfaceContainerHighest(borderOutline, scroll = true) {
        grid("1fr", "min-content 1fr", gridGap8, pb8) {

            title(
                currentProceeding.proceeding.caseNumber,
                strings.outgoingFinance,
            )

            loading {
                val bundle = financeService.bundle(currentProceeding.uuid)
                feeTable = proceedingFeeService.list()
                OutgoingFinance(this, bundle).main()
            }
        }
    }
