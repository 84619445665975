package hu.mkik.vb.portal.ui.proceeding.document.logic

import hu.mkik.vb.portal.model.Document
import hu.mkik.vb.portal.model.DocumentType
import hu.mkik.vb.portal.model.FolderType
import hu.mkik.vb.portal.model.runtime.DocumentBundle
import hu.mkik.vb.portal.ui.documentService
import hu.mkik.vb.portal.ui.isSecretary
import hu.mkik.vb.portal.ui.proceeding.currentParticipations
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.document.components.ContentUpload
import hu.mkik.vb.portal.ui.proceeding.document.components.FileBundleInput
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.userAccount
import hu.simplexion.z2.application.session
import hu.simplexion.z2.browser.css.w400
import hu.simplexion.z2.browser.material.modal.Modals
import hu.simplexion.z2.browser.material.modal.modal
import hu.simplexion.z2.content.model.Content
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.localLaunch
import org.w3c.files.File

suspend fun startUpload(builder: FileBundleInput<FolderType, DocumentType>) {
    val bundle = DocumentBundle()

    bundle.main = toDocument(builder, builder.mainFile.first())
    bundle.attachments = builder.attachments.map { toDocument(builder, it) }

    val progress = modal(w400) {
        title(strings.startingUpload)
    }

    val added = documentService.add(bundle)

    added.forEach { document ->
        localLaunch {
            if (document.name == bundle.main.name) {
                upload(document, builder.mainFile.first())
            } else {
                upload(document, builder.attachments.single { it.name == document.name })
            }
        }
    }

    Modals.close()
}

fun toDocument(builder: FileBundleInput<FolderType, DocumentType>, file: File) =
    Document().also {
        it.folderType = builder.folder!!.uuid
        it.documentType = builder.type!!.uuid
        it.name = file.name
        it.size = file.size.toLong()
        it.proceeding = currentProceeding.proceeding.uuid
        // FIXME clean up submitter
        it.submitter = if (isSecretary && builder.submitter != null) builder.submitter!!.uuid else currentParticipations.first { p -> p.participant == session.principal }.uuid
        it.submissionMode = builder.submissionMode?.id ?: -1
        it.dataCarrier = builder.dataCarrier?.id ?: -1
        it.uploadedBy = userAccount!!
    }

fun upload(document: Document, file: File) {
    ContentUpload(
        document,
        Content().also {
            it.name = document.name
            @Suppress("UNCHECKED_CAST") // I have no idea why cast is not allowed here
            it.uuid = document.uuid as UUID<Content>
        },
        file
    ).main()
}