package hu.mkik.vb.portal.ui.administration.taskType

import hu.mkik.vb.portal.model.TaskType
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.secretaryOrTechnical
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.taskTypeService
import hu.mkik.vb.portal.ui.util.localized
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.css.backgroundTransparent
import hu.simplexion.z2.browser.css.bodyMedium
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.p0
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.span
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.container
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.icon.icon
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.localization.localized

object TaskTypeList : NavRouter(
    strings.taskTypes, icons.task,
    useParentNav = true, default = { list() },
    visibility = secretaryOrTechnical
) {
    val add by AddTaskType
    val edit by EditTaskType
}

private fun Z2.list() =

    tablePage<TaskType> {

        header {
            text = strings.taskTypes
            action(strings.addTaskType) { AddTaskType.open() }
        }

        rowId = { it.uuid }
        query = { taskTypeService.list() }
        doubleClickFun = { EditTaskType.open(it.uuid) }

        column {
            label = strings.proceedingType
            render = { +it.proceedingType.localized }
            comparator = { a, b -> a.proceedingType.compareTo(b.proceedingType) }
            initialSize = "1fr"
        }

        column {
            label = strings.name
            render = { +it.name }
            comparator = { a, b -> a.name.compareTo(b.name) }
            initialSize = "1fr"
        }

        column {
            label = strings.role
            render = { +it.assignToRole.localized }
            comparator = { a, b -> a.assignToRole.localized.compareTo(b.assignToRole.localized) }
            initialSize = "min-content"
        }

        column {
            label = strings.deadlineDays
            render = {
                +it.deadlineDays.toString()
            }
            comparator = { a, b -> a.deadlineDays.compareTo(b.deadlineDays) }
            initialSize = "7em"
        }

        column {
            label = strings.active
            render = { span(bodyMedium) { if (it.active) icon(browserIcons.check) } }
            comparator = { a, b -> a.active.compareTo(b.active) }
            initialSize = "3em"
        }

        actionColumn {
            action {
                label = strings.edit
                handler = { EditTaskType.open(it.uuid) }
            }
            initialSize = "min-content"
        }

    }