package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.ParticipationCandidate
import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.ProceedingEvent
import hu.mkik.vb.portal.model.report.HonorariumSummary
import hu.mkik.vb.portal.model.report.ProceedingRegisterItem
import hu.mkik.vb.portal.model.report.RefundSummary
import hu.mkik.vb.portal.model.report.YearSummaryItem
import hu.mkik.vb.portal.model.runtime.ProceedingBundle
import hu.simplexion.z2.services.Service
import hu.simplexion.z2.util.UUID

interface ProceedingApi : Service {

    suspend fun active(): List<Proceeding>

    suspend fun archived(): List<Proceeding>

    suspend fun get(proceeding: UUID<Proceeding>): Proceeding

    suspend fun getBundle(proceeding: UUID<Proceeding>): ProceedingBundle

    suspend fun add(proceeding: Proceeding, claimant: ParticipationCandidate, respondent: ParticipationCandidate): UUID<Proceeding>

    suspend fun update(proceeding: Proceeding)

    suspend fun remove(proceeding: UUID<Proceeding>)

    suspend fun setClaimValue(proceeding: UUID<Proceeding>, value: Long)

    suspend fun setCounterClaimValue(proceeding: UUID<Proceeding>, value: Long)

    suspend fun saveEditable(proceeding: Proceeding)

    suspend fun events(proceeding: UUID<Proceeding>, offset : Int = 0, limit : Int = 10) : List<ProceedingEvent>

    suspend fun setArchived(proceeding: UUID<Proceeding>, active : Boolean)

    suspend fun register(year : Int) : List<ProceedingRegisterItem>

    suspend fun monthly(year : Int, month : Int) : List<ProceedingRegisterItem>

    suspend fun yearSummary(year : Int, initialReserveFund : Long) : List<YearSummaryItem>

    suspend fun refundSummary(year : Int, month : Int) : List<RefundSummary>

    suspend fun honorariumSummary(year : Int, month : Int) : List<HonorariumSummary>

    suspend fun checkCaseNumber(caseNumber : String) : String?

}