package hu.mkik.vb.portal.ui.proceeding.history.pages

import hu.mkik.vb.portal.model.ProceedingEvent
import hu.mkik.vb.portal.ui.component.participationByAccountColumn
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.proceeding.ProceedingViewModel
import hu.mkik.vb.portal.ui.proceeding.currentParticipations
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.history.modals.proceedingEventDetails
import hu.mkik.vb.portal.ui.proceedingEventService
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.mb24
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import kotlinx.datetime.Instant

fun Z2.history() {
    surfaceContainerLowest(borderOutline) {
        History(this, currentProceeding).main()
    }
}

class History(
    parent: Z2,
    val model: ProceedingViewModel
) : Z2(parent) {

    lateinit var table: Table<ProceedingEvent>

    override fun main(): History {
        addCss(mb24)
        build()
        return this
    }

    fun Z2.build() {
        title(currentProceeding.proceeding.caseNumber, strings.history) {  }

        table<ProceedingEvent> {
            this@History.table = table

            fixRowHeight = false
            rowId = { it.uuid }
            query = { proceedingEventService.query(currentProceeding.proceeding.uuid, Instant.fromEpochSeconds(0)) }

            with(ProceedingEvent()) {
                schematicColumn { this.createdAt }
                participationByAccountColumn(currentParticipations) { this.createdBy }
                schematicColumn { this.type }
                schematicColumn { this.documentName }
                schematicColumn { this.additionalInfo }
                actionColumn {
                    action {
                        label = strings.details
                        handler = { proceedingEventDetails(it) }
                    }
                    initialSize = "min-content"
                }
            }
        }
    }
}

