package hu.mkik.vb.portal.model.finance

import hu.mkik.vb.portal.model.Proceeding
import hu.simplexion.z2.schematic.Schematic

class IncomingPair : Schematic<IncomingPair>() {
    var uuid by uuid<IncomingPair>(true)

    var proceeding by uuid<Proceeding>()

    var requirement by uuid<IncomingRequirement>()
    var payment by uuid<IncomingPayment>().nullable()

    var pairedAmount by decimal(scale = 0, min = 1)
}