package hu.mkik.vb.portal.model

import hu.simplexion.z2.util.hereAndNow
import hu.simplexion.z2.schematic.Schematic

class ProceedingFeeCondition : Schematic<ProceedingFeeCondition>() {
    var uuid by uuid<ProceedingFeeCondition>()

    var proceedingType by enum<ProceedingType>()
    var type by enum<FeeType>()

    var validFrom by localDate(hereAndNow().date)
    var validUntil by localDate()

    var subjectValueLimit by decimal(scale = 0)
    var minimumFee by decimal(scale = 0)
    var maximumFee by decimal(scale = 0)
    var baseFee by decimal(scale = 0)
    var percent by decimal(scale = 3, min = 0)
    var percentageBasis by string(maxLength = 100)
}