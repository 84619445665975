package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.DocumentPermission
import hu.mkik.vb.portal.model.ProceedingType
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface DocumentPermissionApi : Service {

    suspend fun query() : List<DocumentPermission>

    suspend fun query(proceedingType: ProceedingType, proceedingRole: UUID<Role>?, systemRole: UUID<Role>): List<DocumentPermission>

}