package hu.mkik.vb.portal.model

import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.schematic.Schematic

class DocumentPermission : Schematic<DocumentPermission>() {
    var uuid by uuid<DocumentPermission>()

    var order by int()

    var proceedingType by enum<ProceedingType>()
    var list by enum<PermissionType>()
    var upload by enum<PermissionType>()
    var download by enum<PermissionType>()
    var inactivate by enum<PermissionType>()

    var folderType by uuid<FolderType>()
    var documentType by uuid<DocumentType>()
    var proceedingRole by uuid<Role>().nullable()
    var systemRole by uuid<Role>().nullable()

//    var notification by uuid<NotificationTemplate>()
    var description by string() maxLength 500
}