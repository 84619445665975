package hu.mkik.vb.portal.ui.util

import hu.mkik.vb.portal.ui.isDesktop
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.Z2Builder
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.deprecated.browser.CssClass
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.text.LocalizedText

fun Z2.content(vararg classes: CssClass, scroll: Boolean = false, builder: Z2.() -> Unit) =
    div(boxSizingBorderBox, positionRelative, heightFull, wFull, surfaceContainerLowest, p16, overflowHidden) {
        if (isDesktop) {
            addCss(borderRadius16, borderOutline, pt24, mb8)
        } else {
            addCss(borderTopOutlineVariant)
        }

        div(boxSizingBorderBox, positionRelative, heightFull, wFull) {
            if (classes.isNotEmpty()) addCss(*classes)

            if (scroll) addCss(overflowYAuto)

            builder()
        }
    }

fun Z2.section(title: LocalizedText, builder: Z2Builder) =
    div {
        div(titleMedium, pt24, primaryText, pb4) { text { title.localeCapitalized } }
        div(borderBottomOutlineVariant, pt0, mb16)
        builder()
    }

fun Z2.importantSection(title: LocalizedText, builder: Z2Builder) =
    div {
        div(titleMedium, pt24, errorText, pb4) { text { title.localeCapitalized } }
        div(borderBottomOutlineVariant, pt0, mb16)
        builder()
    }

