package hu.mkik.vb.portal.ui.proceeding.task.pages

import hu.mkik.vb.portal.model.Task
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.isProceedingManager
import hu.mkik.vb.portal.ui.proceeding.*
import hu.mkik.vb.portal.ui.proceeding.task.components.addTask
import hu.mkik.vb.portal.ui.proceeding.task.components.editTask
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.taskService
import hu.mkik.vb.portal.ui.userAccount
import hu.mkik.vb.portal.ui.util.customChip
import hu.mkik.vb.portal.ui.util.localized
import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.span
import hu.simplexion.z2.browser.immaterial.schematic.attachListener
import hu.simplexion.z2.browser.immaterial.select.singleChipSelect
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.button.textLaunchButton
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.browser.material.icon.icon
import hu.simplexion.z2.localization.locales.localized

fun Z2.tasks() {
    surfaceContainerLowest(borderOutline) {
        Tasks(this, currentProceeding).main()
    }
}

class Tasks(
    parent: Z2,
    val model: ProceedingViewModel
) : Z2(parent) {

    lateinit var table: Table<Task>

    val taskFilter = TaskFilter()

    override fun main(): Tasks {
        addCss(mb24)
        build()
        return this
    }

    fun refreshTable() {
        table.setData(currentProceeding.tasks.value)
    }

    fun Z2.build() {
        clear()

        title(currentProceeding.proceeding.caseNumber, strings.tasks) { actions() }

        taskFilters(taskFilter)

        table<Task> {
            this@Tasks.table = table

            // FIXME table update (when adding/editing tasks) vs. filtering
            this.table.attachListener(taskFilter) {
                this.table.onSearch("")
            }

            rowId = { it.uuid }
            query = { currentProceeding.tasks.value }
            doubleClickFun = { editTask(it) { refreshTable() } }
            filterFun = { taskFilter.filter(it) }

            column {
                label = strings.type
                render = { span(bodyMedium) { + it.type.localized } }
                comparator = { a, b -> a.type.compareTo(b.type) }
            }

            column {
                label = strings.assignedTo
                render = { span(bodyMedium) { + it.assignedTo.fullName } }
                comparator = { a, b -> a.assignedTo.fullName!!.compareTo(b.assignedTo.fullName!!) }
            }

            column {
                label = strings.deadline
                render = { span(bodyMedium) { + it.deadline.localized } }
                comparator = { a, b -> a.deadline.compareTo(b.deadline) }
                initialSize = 14.em
            }

            column {
                label = strings.done
                render = { span(bodyMedium) { if (it.done) icon(browserIcons.check) } }
                comparator = { a, b -> a.done.compareTo(b.done) }
            }

            column {
                label = strings.actions
                render = { row ->
                    style.marginLeft = "-12px"

                    textButton(strings.details) {
                        editTask(row) { refreshTable() }
                    }

                    if (!row.done && (isProceedingManager || row.assignedTo == userParticipation?.uuid)) {
                        textLaunchButton(strings.done) {
                            taskService.setDone(row.uuid, true)
                            refreshTasks()
                            refreshEvents()
                            refreshTable()
                        }
                    }
                }
            }
        }
    }

    fun Z2.actions() {
        if (isProceedingManager) {
            textLaunchButton(strings.addTask) {
                addTask { refreshTable() }
            }
        }
    }
}

private fun Z2.taskFilters(filter: TaskFilter) =
    div(displayFlex, flexDirectionRow) {
        style.flexWrap = "wrap"

        val statuses = listOf(strings.done, strings.inProgress, strings.all)

        singleChipSelect(statuses, label = strings.filterByStatus) {
            filter.statusFilter = when (it.valueOrNull) {
                strings.done -> true
                strings.inProgress -> false
                else -> null
            }
        }.customChip()


        val assignees = listOf(strings.ownOnly, strings.all)

        singleChipSelect(assignees, label = strings.filterByAssignee) {
            filter.assigneeFilter = when (it.valueOrNull) {
                strings.ownOnly -> userAccount.participation?.uuid
                else -> null
            }
        }.customChip()

    }
