package hu.mkik.vb.portal.model

import hu.simplexion.z2.localization.locales.localized
import hu.simplexion.z2.schematic.Schematic

class Task : Schematic<Task>() {
    var uuid by uuid<Task>(true)

    var proceeding by uuid<Proceeding>()
    var assignedTo by uuid<Participation>()

    var type by uuid<TaskType>()

    var modifiedBy by uuid<AccountVB>(true)

    var deadline by localDate()

    var note by string(250)
    var modifiedAt by instant()
    var done by boolean()

    var createdBy by uuid<AccountVB>(true)
    var createdAt by instant()

    val localizedDeadline : String
        get() = deadline.localized  //
}