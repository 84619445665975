package hu.mkik.vb.portal.model.finance

import hu.simplexion.z2.schematic.Schematic

class BankTransaction : Schematic<BankTransaction>() {

    var uuid by uuid<BankTransaction>(true)

    var statement by uuid<BankStatement>()

    var type by string(6)
    var valueDate by localDate()
    var amount by decimal(scale = 0)
    var peerName by string()
    var peerAccount by string()
    var additionalInfo by string()

    var remainingAmount by decimal(scale = 0)

}