package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.template.DocumentTemplate
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface DocumentTemplateApi : Service {

    suspend fun templates() : List<DocumentTemplate>

    suspend fun generate(proceeding : UUID<Proceeding>, templateName : String) : ByteArray

}