package hu.mkik.vb.portal.ui.proceeding.document.components

import hu.mkik.vb.portal.model.FolderType
import hu.mkik.vb.portal.ui.contentTypes
import hu.mkik.vb.portal.ui.proceeding.ProceedingViewModel
import hu.simplexion.z2.browser.html.Z2

fun Z2.fileBundle(model: ProceedingViewModel, folderType: FolderType? = null, documentTypeName: String? = null) =
    FileBundleInput(
        this,
        FileBundleInputConfiguration(
            model.directUploadFolderMap.values.toList(),
            model.uploadTypes,
            contentTypes.map { it.extension },
            if (contentTypes.isEmpty()) Long.MAX_VALUE else contentTypes.maxOf { it.sizeLimit }
        ).apply {
            this.typeRenderFun = { + it.name }
            this.folderTextFun = { it.name }
            this.showFolderAndTypeSelect = (documentTypeName == null)
        }
    ).also {
        if (folderType != null) {
            it.folder = folderType
        }
        if (documentTypeName != null) {
            val documentType = model.documentTypes.first { it.name == documentTypeName }
            it.type = documentType
            it.folder = model.folderTypeMap[model.uploadPermissions.first { p -> documentType.uuid == p.documentType }.folderType]
        }
    }.main()