package hu.mkik.vb.portal.ui.component

import hu.mkik.vb.portal.ui.util.flexWrapWrap
import hu.mkik.vb.portal.ui.util.mr24
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.Z2Builder
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.span
import hu.simplexion.z2.localization.locales.localeCapitalized

fun Z2.title(mainTitle : Any, subTitle : Any? = null, subTitleUnder : Boolean = false, leftActions : Z2Builder? = null, rightActions : Z2Builder? = null) {
    div(mb16, displayFlex, flexDirectionRow, alignItemsCenter, flexWrapWrap, justifyContentSpaceBetween) {
        div(displayFlex, flexDirectionRow, alignItemsCenter, flexWrapWrap) {
            div(titleLarge, mr24) {
                div {
                    + mainTitle.toString()
                    if (subTitle != null && ! subTitleUnder) {
                        + " - "
                        span(titleMedium) { + subTitle.toString().localeCapitalized }
                    }
                }
                if (subTitle != null && subTitleUnder) {
                    div(pt4, bodySmall, onSurfaceVariantText) { text { subTitle } }
                }
            }
            leftActions?.invoke(this)
        }
        div {
            rightActions?.invoke(this)
        }
    }
}