package hu.mkik.vb.portal.ui.archive

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.proceeding.proceedingRouter
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.secretary
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.routing.NavRouter

object ArchiveList : NavRouter(
    strings.archive,
    icons.archived,
    visibility = secretary,
    useParentNav = true,
    default = { list() }
)

private fun Z2.list() =
    tablePage<Proceeding> {

        header {
            text = strings.archivedProceedings
            search()
        }

        rowId = { it.uuid }
        query = { proceedingService.archived() }
        doubleClickFun = { proceedingRouter.open(it.uuid) }

        with(Proceeding()) {
            schematicColumn { caseNumber }
            schematicColumn { primaryClaimantName }
            schematicColumn { primaryRespondentName }
            schematicColumn { claimValue }
            schematicColumn { secretaryName }
        }

        actionColumn {
            action {
                label = browserStrings.details
                handler = { proceedingRouter.open(it.uuid) }
            }
        }
    }