package hu.mkik.vb.portal.ui.administration.locales

import hu.mkik.vb.portal.ui.administration.locales.pages.localeList
import hu.mkik.vb.portal.ui.administration.locales.pages.translationList
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.secretaryOrTechnical
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.localization.model.Locale
import hu.simplexion.z2.util.localLaunch

object LocaleRouter : NavRouter(
    strings.languages,
    icons.languages,
    useParentNav = true,
    visibility = secretaryOrTechnical,
    default = { localeList() }
) {

    lateinit var locale : Locale

    val translations by render { localLaunch { translationList(locale) } }

}