package hu.mkik.vb.portal.ui.administration.account.pages

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.model.AccountVBType
import hu.mkik.vb.portal.ui.*
import hu.mkik.vb.portal.ui.account.modals.passwordChange
import hu.mkik.vb.portal.ui.administration.account.modals.accountHistory
import hu.mkik.vb.portal.ui.administration.account.modals.dmsOneSelect
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.util.content
import hu.mkik.vb.portal.ui.util.section
import hu.simplexion.z2.adaptive.modal.modal
import hu.simplexion.z2.auth.model.Principal
import hu.simplexion.z2.auth.principalService
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.material.button.filledButton
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.button.textLaunchButton
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.snackbar.warningSnackbar
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.schematic.asFieldValidationResult
import hu.simplexion.z2.schematic.schema.validation.FieldValidationResult
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.localLaunch
import mainRouter

object EditAccount : NavRouter(useParentNav = true, visibility = secretaryOrTechnical) {
    val uuid by uuid<AccountVB>()
    override var default: Z2Builder = { editAccount(uuid) }
}

object AddAccount : NavRouter(useParentNav = true, visibility = secretaryOrTechnical) {
    override var default: Z2Builder = { editAccount(UUID.nil()) }
}

internal fun Z2.editAccount(uuid: UUID<AccountVB>) {
    localLaunch {
        val add = (uuid == UUID.nil<AccountVB>())
        val self = (uuid == userAccount)
        val account = if (add) AccountVB() else accountVBService.get(uuid)
        val principal = if (add || !isSecretaryOrTechnical) Principal() else principalService.get(uuid.cast())

        fun canChangePassword(): Boolean {
            if (add) return false
            if (self) return true
            if (isTechnicalAdmin) return true
            if (account.accountType == AccountVBType.Participant && isSecretary) return true
            return false
        }

        content(scroll = true) {
            style.margin = "0 auto"

            title(
                if (add) strings.addAccount.localeCapitalized else account.fullName,
                if (add) "" else account.uuid,
                subTitleUnder = true,
                rightActions = {
                    div(displayFlex, flexDirectionRow, gap16) {
                        if (!add && (isTechnicalAdmin || isSecretary)) filledButton(strings.history) { accountHistory(uuid) }
                        if (canChangePassword()) filledButton(strings.passwordChange) { passwordChange(uuid) }
                    }
                }
            ).apply { style.maxWidth = 900.px }

            grid(pt8) {
                style.maxWidth = 900.px
                gridTemplateColumns = "repeat(auto-fill, minmax(288px, 1fr))"
                columnGap = 24.px

                section(strings.basicInfo) {
                    field { account.fullName } readOnly true
                    field { account.email } readOnly true validateSuspend { uniqueEmailOrSelf(uuid, it!!) }  // FIXME !! is added but I don't know why it's needed
                    field { account.phone } readOnly true
                    if (isSecretaryOrTechnical) {
                        field { account.mpaid }.also { it.supportEnabled = false } readOnly true

                        div(displayFlex, justifyContentSpaceBetween) {

                            if (add) {
                                textLaunchButton(strings.search) {
                                    dmsOneSelect().show()?.let { candidate ->
                                        account.fullName = candidate.fullName
                                        account.email = candidate.email
                                        account.phone = candidate.phone
                                        account.mpaid = candidate.mpaid
                                    }
                                }
                            } else {
                                div()
                            }

                            if (account.mpaid != 0) {
                                textLaunchButton(strings.refresh) {
                                    modal {
                                        title = strings.inProgress
                                        localLaunch {
                                            if (participationService.candidates(null, null, account.mpaid.toString()).single().email.isEmpty()) {
                                                warningSnackbar(strings.noEmail)
                                            } else {
                                                accountVBService.refresh(account.uuid)
                                                accountVBService.get(account.uuid).let {
                                                    account.fullName = it.fullName
                                                    account.email = it.email
                                                    account.phone = it.phone
                                                }
                                                snackbar(strings.refreshSuccess)
                                            }
                                            close()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                if (isTechnicalAdmin || isSecretary) {
                    section(strings.role) {
                        field { account.accountType } readOnly (isSecretary)
                    }
                }

                if (!add && isSecretaryOrTechnical) {
                    section(strings.loginInfo) {
                        grid(gridGap24) {
                            gridTemplateColumns = "1fr"
                            field { principal.lastAuthSuccess } supportEnabled false readOnly true
                            field { principal.authSuccessCount } supportEnabled false readOnly true
                            field { principal.lastAuthFail } supportEnabled false readOnly true
                            field { principal.authFailCount } supportEnabled false readOnly true
                        }
                    }

                    section(strings.status) {
                        grid(gridGap24) {
                            gridTemplateColumns = "1fr"
                            gridAutoRows = "56px"

                            field { principal.activated } css alignSelfCenter
                            field { principal.locked } css alignSelfCenter
                        }
                    }
                }
            }

            if (isSecretaryOrTechnical) {
                grid(pt16) {
                    style.maxWidth = 900.px
                    gridTemplateColumns = "1fr 1fr"
                    gridTemplateRows = "min-content min-content"

                    div(wFull, borderBottomOutlineVariant, pt24, mb16) {
                        gridColumn = "1/span3"
                    }

                    textButton(strings.back) { mainRouter.back() } css justifySelfStart

                    filledLaunchButton(strings.save) {
                        if (account.email.isEmpty()) {
                            warningSnackbar(strings.noEmail)
                            return@filledLaunchButton
                        }

                        if (add) {
                            accountVBService.add(account).also { accountUuid ->
                                principalService.setActivated(accountUuid.cast(), principal.activated)
                                principalService.setLocked(accountUuid.cast(), principal.locked)
                            }
                            snackbar(strings.createSuccess)
                            mainRouter.back()
                        } else {
                            accountVBService.save(account)
                            principalService.setActivated(principal.uuid, principal.activated)
                            principalService.setLocked(principal.uuid, principal.locked)
                            snackbar(strings.saveSuccess)
                        }
                    } css justifySelfEnd
                }
            }
        }
    }
}

suspend fun uniqueEmailOrSelf(uuid: UUID<AccountVB>, email: String): FieldValidationResult {
    val forEmail = accountVBService.getUuidByEmail(email)
    return (forEmail == null || forEmail == uuid).asFieldValidationResult(strings.accountWithMpaidExists)
}

fun Z2.row(builder: Z2Builder) =
    grid(gridGap24, gridAutoFlowColumn) {
        builder()
    }