package hu.mkik.vb.portal.ui.proceeding.history.modals

import hu.mkik.vb.portal.model.ProceedingEvent
import hu.mkik.vb.portal.ui.proceeding.currentParticipations
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.adaptive.modal.modal
import hu.simplexion.z2.browser.css.pt24
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.material.textfield.textField
import hu.simplexion.z2.localization.localized

fun proceedingEventDetails(entry: ProceedingEvent) {
    modal {
        title = strings.details

        body {
            addCss(pt24)
            textField(currentParticipations.firstOrNull { it.uuid == entry.participation }?.fullName ?: "", label = strings.participation).also { it.forceReadOnly = true }
            textField(entry.type.localized, label = strings.type).also { it.forceReadOnly = true }
            field { entry.deadline } readOnly true
            field { entry.createdAt } readOnly true
            textField(currentProceeding.documentTypeMap[entry.documentType]?.name ?: "", label = strings.documentType).also { it.forceReadOnly = true }
            field { entry.documentName } readOnly true
            field { entry.additionalInfo } readOnly true
            field { entry.note } readOnly true
        }

        ok()
    }
}