package hu.mkik.vb.portal.ui.proceeding.document.logic

import hu.mkik.vb.portal.model.Document
import hu.mkik.vb.portal.model.DocumentType
import hu.mkik.vb.portal.model.FolderType
import hu.mkik.vb.portal.model.Participation
import hu.simplexion.z2.schematic.Schematic

class DocumentFilter : Schematic<DocumentFilter>() {

    var folderFilter by uuid<FolderType>().nullable()
    var documentTypeFilter by uuid<DocumentType>().nullable()
    var participantFilter by uuid<Participation>().nullable()
    var afterFilter by instant().nullable()
    var beforeFilter by instant().nullable()
    var fileNameFilter by string().nullable()

    fun String.normalize(): String =
        asDynamic().normalize() as String

    fun filter(documents: List<Document>): List<Document> {
        val folderFilter = this.folderFilter
        val documentTypeFilter = this.documentTypeFilter
        val participantFilter = this.participantFilter
        val afterFilter = this.afterFilter
        val beforeFilter = this.beforeFilter
        val fileNameFilter = this.fileNameFilter

        return documents.filter { document ->
            !((folderFilter != null && document.folderType != folderFilter) ||
                (documentTypeFilter != null && document.documentType != documentTypeFilter) ||
                (participantFilter != null && document.submitter != participantFilter) ||
                (afterFilter != null && document.uploadedAt < afterFilter) ||
                (beforeFilter != null && document.uploadedAt > beforeFilter) ||
                (fileNameFilter != null && fileNameFilter !in document.name.lowercase())
                )
        }
    }

    fun filterRow(document: Document): Boolean {
        val folderFilter = this.folderFilter
        val documentTypeFilter = this.documentTypeFilter
        val participantFilter = this.participantFilter
        val afterFilter = this.afterFilter
        val beforeFilter = this.beforeFilter
        val fileNameFilter = this.fileNameFilter?.normalize()?.lowercase()


        val match = !((folderFilter != null && document.folderType != folderFilter) ||
            (documentTypeFilter != null && document.documentType != documentTypeFilter) ||
            (participantFilter != null && document.submitter != participantFilter) ||
            (afterFilter != null && document.uploadedAt < afterFilter) ||
            (beforeFilter != null && document.uploadedAt > beforeFilter) ||
            (fileNameFilter != null && fileNameFilter !in document.name.normalize().lowercase())
            )

        println("match: $match fileNameFilter=$fileNameFilter")
        return match
    }

}