package hu.mkik.vb.portal.ui.proceeding.documentTemplate.pages

import hu.mkik.vb.portal.model.template.DocumentTemplate
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.documentTemplateService
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.util.downloadBlob
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

fun Z2.documentTemplates() =
    surfaceContainerLowest(borderOutline) {
        title(currentProceeding.proceeding.caseNumber, strings.templates)

        table<DocumentTemplate> {

            rowId = { it.name }
            query = { documentTemplateService.templates() }

            with(DocumentTemplate()) {
                schematicColumn { name }
                schematicColumn { size }
                schematicColumn { modifiedAt }
                actionColumn {
                    action {
                        label = strings.download
                        handler = { generate(it.name) }
                    }
                }
            }
        }
    }

private suspend fun generate(name: String) {
    val docx = documentTemplateService.generate(currentProceeding.uuid, name)
    val blob = Blob(arrayOf(docx), BlobPropertyBag("application/vnd.openxmlformats-officedocument.wordprocessingml.document"))
    downloadBlob(currentProceeding.caseNumber + " - " + name + ".docx", blob)
}