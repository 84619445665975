package hu.mkik.vb.portal.ui.administration.documentType

import hu.mkik.vb.portal.model.DocumentType
import hu.mkik.vb.portal.ui.documentTypeService
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.secretaryOrTechnical
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.css.backgroundTransparent
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.p0
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.container
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.routing.NavRouter

object DocumentTypeList : NavRouter(
    strings.documentTypes,
    icons.documentTypes,
    useParentNav = true,
    visibility = secretaryOrTechnical,
    default = { documentTypeList() }
)

private fun Z2.documentTypeList() =
    tablePage<DocumentType> {

        header {
            text = strings.documentTypes
        }

        rowId = { it.uuid }
        query = { documentTypeService.query() }

        with(DocumentType()) {
            schematicColumn { name }
            schematicColumn { proceedingType }
            schematicColumn { privateType }
        }
    }