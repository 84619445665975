package hu.mkik.vb.portal.model.dmsone

import hu.mkik.vb.portal.model.ParticipationCandidate
import hu.simplexion.z2.schematic.Schematic

class DMSOnePartner : Schematic<DMSOnePartner>() {
    var mpaid by int()
    var fullName by string()
    var emails by schematicList<DMSOnePartnerAdat>()
    var phoneNumbers by schematicList<DMSOnePartnerAdat>()
    var addresses by schematicList<DMSOneCim>()
    var accountNumbers by schematicList<DMSOnePartnerAdat>()

    fun toParticipationCandidate() = ParticipationCandidate().also { pc ->
        pc.dmsonePartner = this
        pc.fullName = fullName
        pc.mpaid = mpaid
        pc.address = addresses.firstOrNull { it.isAlapertelmezett }?.localized ?: ""
        pc.email = emails.firstOrNull { it.isAlapertelmezett }?.ertek ?: ""
        pc.bankAccount = accountNumbers.firstOrNull { it.sorszam == 1 }?.ertek ?: ""
        pc.phone = phoneNumbers.firstOrNull { it.sorszam == 1 }?.ertek ?: ""
    }
}