package hu.mkik.vb.portal.ui.administration.contentType

import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.browser.util.io
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.localLaunch
import hu.simplexion.z2.content.contentTypeService
import hu.simplexion.z2.content.model.ContentType
import hu.simplexion.z2.localization.locales.localeCapitalized
import mainRouter

object EditContentType : NavRouter(useParentNav = true) {
    val uuid by uuid<ContentType>()
    override var default: Z2Builder = { edit(uuid) }
}

object AddContentType : NavRouter(useParentNav = true) {
    override var default: Z2Builder = { edit(UUID.nil()) }
}

internal fun Z2.edit(uuid: UUID<ContentType>) {
    localLaunch {
        val add = (uuid == UUID.nil<ContentType>())
        val contentType = if (add) ContentType() else contentTypeService.get(uuid)

        surfaceContainerLowest(borderOutline, p24) {
            div(titleLarge, pb24) {
                text { if (add) strings.addContentType.localeCapitalized else strings.editContentType.localeCapitalized }
                if (! add) {
                    div(pt4, bodySmall, onSurfaceVariantText) { text { contentType.uuid } }
                }
            }

            field { contentType.extension }
            field { contentType.mimeType }
            field { contentType.sizeLimit }
            //field { contentType.allowed }

            grid(pt24, gridGap16) {
                gridTemplateColumns = "1fr min-content"

                textButton(strings.cancel) { mainRouter.back() } css justifySelfEnd

                filledButton(strings.save) {
                    io {
                        if (add) {
                            contentTypeService.add(contentType)
                            snackbar(strings.createSuccess)
                            mainRouter.back()
                        } else {
                            contentTypeService.update(contentType)
                            snackbar(strings.saveSuccess)
                        }
                    }
                }
            }
        }
    }
}