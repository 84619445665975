/*
 * Copyright © 2020-2023, Simplexion, Hungary and contributors. Use of this source code is governed by the Apache 2.0 license.
 */
package hu.mkik.vb.portal.ui.proceeding.document.components

import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.material.px
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.BeforeUnloadEvent
import org.w3c.dom.HTMLDivElement

/**
 * Contains modal windows and shows them over the normal content.
 */
object Uploads : Z2(
    null,
    document.createElement("div") as HTMLDivElement,
    arrayOf(positionAbsolute, wFull, heightFull, pointerEventsNone),
    { document.body?.appendChild(this.htmlElement) }
) {

    val itemContainer: Z2

    init {
        style.top = 0.px
        zIndex = 10000

        itemContainer = div(positionAbsolute, overflowYAuto, surfaceContainerLowest, borderOutline, borderRadius8) {
            style.bottom = 12.px
            style.right = 12.px
            style.maxHeight = "90vh"
        }

        window.addEventListener("beforeunload", { event ->
            event as BeforeUnloadEvent

            event.returnValue = if (itemContainer.children.isEmpty()) {
                ""
            } else {
                strings.uploadInProgress.localized
            }
        })
    }

}