package hu.mkik.vb.portal.ui

import hu.simplexion.z2.IBaseIcons

internal object icons : IBaseIcons {
    val add= static("add")
    val edit = static("edit")
    val account= static("person")
    val logout= static("logout")
    val proceeding= static("receipt_long")
    val archived= static("inventory_2")
    val calendar= static("calendar_month")
    val meetingRoom= static("meeting_room")
    val administration= static("local_police")
    val documents= static("folder")
    val documentPermissions = static("demography")
    val documentTypes = static("quick_reference")
    val participants= static("groups")
    val task= static("task")
    val contentType= static("content_copy")
    val fees= static("money")
    val tableView= static("list")
    val gridView= static("grid_view")
    val interfaces = static("conversion_path")
    val impressum = static("breaking_news")
    val filter = static("filter_alt")
    val softwareUpdate = static("deployed_code_update")
    val report = static("list_alt")
    val bank = static("savings")
    val pendingTransactions = static("pending_actions")
    val closedTransactions = static("assignment_turned_in")
    val download = static("download")
}