package hu.mkik.vb.portal.model.runtime

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.model.AccountVBType
import hu.simplexion.z2.schematic.Schematic

class AccountSummaryEntry : Schematic<AccountSummaryEntry>() {

    var uuid by uuid<AccountVB>()

    var mpaid by int()
    var fullName by string()
    var email by email().nullable()
    var phone by phoneNumber()
    var accountType by enum<AccountVBType>()
    var activated by boolean()
    var locked by boolean()
    var lastLogin by instant().nullable()

}