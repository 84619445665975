package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.Space
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface SpaceApi : Service {

    suspend fun list(): List<Space>

    suspend fun get(spaceUuid: UUID<Space>) : Space

    suspend fun add(space: Space) : UUID<Space>

    suspend fun update(space: Space)

    suspend fun remove(space: UUID<Space>)

}