package hu.mkik.vb.portal.ui.component

import hu.simplexion.z2.browser.immaterial.table.builders.ColumnBuilder
import hu.simplexion.z2.browser.immaterial.table.builders.TableBuilder
import hu.simplexion.z2.localization.label
import hu.simplexion.z2.schematic.Schematic
import hu.simplexion.z2.schematic.SchematicAccessFunction
import hu.simplexion.z2.schematic.access.SchematicAccessContext
import hu.simplexion.z2.util.UUID

@SchematicAccessFunction
fun <T : Schematic<T>, RT> TableBuilder<T>.referenceColumn(
    toText: (uuid: UUID<RT>) -> String,
    context: SchematicAccessContext? = null,
    @Suppress("UNUSED_PARAMETER")
    accessor: () -> UUID<RT>
): ColumnBuilder<T> {
    checkNotNull(context)

    val field = context.field

    @Suppress("UNCHECKED_CAST")
    fun get(schematic: T): UUID<RT> = field.getValue(schematic) as UUID<RT>

    return column {
        label = field.label(context.schematic)
        render = { + toText(get(it)) }
        comparator = { a, b -> toText(get(a)).compareTo(toText(get(b))) }
    }
}

@SchematicAccessFunction
fun <T : Schematic<T>, RT : Schematic<RT>> TableBuilder<T>.referenceColumn(
    options: Collection<RT>,
    context: SchematicAccessContext? = null,
    @Suppress("UNUSED_PARAMETER")
    accessor: () -> UUID<RT>
): ColumnBuilder<T> {
    checkNotNull(context)

    val field = context.field

    @Suppress("UNCHECKED_CAST")
    fun toText(schematic: T): String {
        val uuid = field.getValue(schematic) as UUID<RT>
        return options.firstOrNull { it.schematicGet("uuid") == uuid }?.toString() ?: ""
    }

    return column {
        label = field.label(context.schematic)
        render = { + toText(it) }
        comparator = { a, b -> toText(a).compareTo(toText(b)) }
    }
}

@SchematicAccessFunction
fun <T : Schematic<T>, RT : Schematic<RT>> TableBuilder<T>.optReferenceColumn(
    options: Collection<RT>,
    context: SchematicAccessContext? = null,
    @Suppress("UNUSED_PARAMETER")
    accessor: () -> UUID<RT>?
): ColumnBuilder<T> {
    checkNotNull(context)

    val field = context.field

    @Suppress("UNCHECKED_CAST")
    fun toText(schematic: T): String {
        val uuid = field.getValue(schematic) as UUID<RT>?
        return options.firstOrNull { it.schematicGet("uuid") == uuid }?.toString() ?: ""
    }

    return column {
        label = field.label(context.schematic)
        render = { + toText(it) }
        comparator = { a, b -> toText(a).compareTo(toText(b)) }
    }
}