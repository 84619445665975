package hu.mkik.vb.portal.ui.task.pages

import hu.mkik.vb.portal.model.runtime.TaskSummary
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.task.modals.taskEditor
import hu.mkik.vb.portal.ui.taskService
import hu.mkik.vb.portal.ui.taskTypes
import hu.mkik.vb.portal.ui.userAccount
import hu.mkik.vb.portal.ui.util.customChip
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.schematic.attachListener
import hu.simplexion.z2.browser.immaterial.select.singleChipSelect
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.localization.locales.localeCapitalized

fun Z2.taskList() =
    tablePage<TaskSummary> {
        val taskFilter = TaskSummaryFilter()

        header {
            title {
                grid("repeat(auto-fit, minmax(min-content, 220px))", "max-content", gridAutoFlowColumn) {

                    div(alignSelfCenter, titleLarge, whiteSpaceNoWrap, pb12, pl8, *textClasses) {
                        text { strings.tasks.localeCapitalized }
                    }

                    val statuses = listOf(strings.done, strings.inProgress, strings.all)

                    singleChipSelect(statuses, label = strings.filterByStatus) {
                        taskFilter.statusFilter = when (it.valueOrNull) {
                            strings.done -> true
                            strings.inProgress -> false
                            else -> null
                        }
                    }.customChip()


                    val assignees = listOf(strings.ownOnly, strings.all)

                    singleChipSelect(assignees, label = strings.filterByAssignee) {
                        taskFilter.assigneeFilter = when (it.valueOrNull) {
                            strings.ownOnly -> userAccount
                            else -> null
                        }
                    }.customChip()

                }
            }
        }

        // FIXME table update (when adding/editing tasks) vs. filtering
        this.table.attachListener(taskFilter) {
            this.table.onSearch("")
        }

        rowId = { it.uuid }
        query = { taskService.query() }
        filterFun = { taskFilter.filter(it) }
        doubleClickFun = { taskEditor(it.uuid, table) }

        with(TaskSummary()) {
            schematicColumn { caseNumber } initialSize 6.5.em
            schematicColumn { caseSubject } label strings.subject
            schematicColumn { assigneeName } label strings.assignedTo
            column {
                label = strings.type
                render = { +taskTypes[it.type]?.name }
                comparator = { a, b -> taskTypes[a.type]!!.name.compareTo(taskTypes[b.type]!!.name) }
            }
            schematicColumn { deadline }
            schematicColumn { done }
            schematicColumn { note }
            schematicColumn { modifiedBy }
            schematicColumn { modifiedAt }
        }

        actionColumn {
            action {
                label = browserStrings.details
                handler = { taskEditor(it.uuid, table) }
            }
        }

    }