package hu.mkik.vb.portal.ui.account.pages

import hu.mkik.vb.portal.ui.component.NewPassword
import hu.mkik.vb.portal.ui.component.singlePageDialogWithTitleAndLanguages
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.auth.model.Credentials
import hu.simplexion.z2.auth.model.Principal
import hu.simplexion.z2.auth.principalService
import hu.simplexion.z2.auth.sessionService
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.routing.Router
import hu.simplexion.z2.browser.routing.isLoggedIn
import io.ktor.http.*

object PasswordReset : Router<Z2>(loggedIn = false) {
    val uuid by uuid<Principal>()
    val key by string()

    override fun default(receiver: Z2, path: List<String>) {
        receiver.singlePageDialogWithTitleAndLanguages(strings.passwordReset, spanLast = false) { passwordReset() }
    }
}

private fun Z2.passwordReset() =
    grid("1fr", "min-content min-content", displayGrid, pl24, pr24) {
        val key = Credentials().also {
            it.principal = PasswordReset.uuid
            it.value = PasswordReset.key.decodeURLPart() // FIXME decodeURLPart handling
        }

        val newPassword = NewPassword(this, false).main()

        div(displayFlex, justifyContentFlexEnd, pt16) {
            filledLaunchButton(strings.ok) {
                if (!newPassword.valid()) return@filledLaunchButton

                try {
                    if (isLoggedIn) sessionService.logout()

                    principalService.resetPassword(
                        Credentials().apply { value = newPassword.newPassword },
                        key
                    )

                    Login.open()
                } catch (ex: Exception) {
                    snackbar(strings.error)
                }
            }
        }
    }
