package hu.mkik.vb.portal.ui.administration.account.modals

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.ui.accountVBService
import hu.mkik.vb.portal.ui.component.modal
import hu.mkik.vb.portal.ui.component.modalHeader
import hu.mkik.vb.portal.ui.component.modalOk
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.heightFull
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.table.builders.size
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.material.vw
import hu.simplexion.z2.history.model.HistoryEntry
import hu.simplexion.z2.localization.localizedTextStore
import hu.simplexion.z2.util.UUID


fun accountHistory(uuid: UUID<AccountVB>) {
    modal {
        modalHeader(strings.events)

        grid("1fr", "1fr min-content", heightFull) {
            style.width = 95.vw
            table<HistoryEntry> {
                query = { accountVBService.history(uuid) }
                rowId = { it.uuid }

                with(HistoryEntry()) {
                    schematicColumn { this.createdAt } size "min-content"
                    column {
                        label = strings.topic
                        render = { +localizedTextStore[it.topic] }
                        initialSize = "min-content"
                    }
                    column {
                        label = strings.verb
                        render = { +localizedTextStore[it.verb] }
                        initialSize = "min-content"
                    }
                    schematicColumn { this.textContent }
                }
            }
        }

        modalOk()
    }
}