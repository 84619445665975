package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.Task
import hu.mkik.vb.portal.model.runtime.TaskSummary
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface TaskApi : Service {

    suspend fun list(proceeding: UUID<Proceeding>): List<Task>

    suspend fun add(task: Task) : UUID<Task>

    suspend fun update(task: Task)

    suspend fun get(taskUuid: UUID<Task>) : Task

    suspend fun remove(task: UUID<Task>)

    suspend fun setDone(taskUuid : UUID<Task>, done : Boolean)

    suspend fun query() : List<TaskSummary>

}