package hu.mkik.vb.portal.model

import hu.simplexion.z2.localization.text.LocalizedTextProvider

enum class ProceedingCloseType : LocalizedTextProvider {
    MissingArbitrationCostPayment,
    WithdrawalBeforeEstablishment,
    NoJudgementAfterEstablishment,
    Judgement,
    LateJudgement,
    InvalidatedJudgement
}