package hu.mkik.vb.portal.ui

import hu.mkik.vb.portal.api.*
import hu.mkik.vb.portal.api.finance.BankApi
import hu.mkik.vb.portal.api.finance.FinanceApi
import hu.mkik.vb.portal.api.technical.SysAdminApi
import hu.mkik.vb.portal.vbCommon
import hu.mkik.vb.portal.vbPortalRoles
import hu.simplexion.z2.services.getService

val accountVBService = getService<AccountVBApi>()
val bankService = getService<BankApi>()
val dmsOneService = getService<DMSOneApi>()
val documentPermissionService = getService<DocumentPermissionApi>()
val documentService = getService<DocumentApi>()
val documentTemplateService = getService<DocumentTemplateApi>()
val documentTypeService = getService<DocumentTypeApi>()
val financeService = getService<FinanceApi>()
val folderTypeService = getService<FolderTypeApi>()
val hearingService = getService<HearingApi>()
val participationService = getService<ParticipationApi>()
val proceedingEventService = getService<ProceedingEventApi>()
val proceedingFeeService = getService<ProceedingFeeConditionApi>()
val proceedingService = getService<ProceedingApi>()
val spaceService = getService<SpaceApi>()
val sysAdminService = getService<SysAdminApi>()
val taskService = getService<TaskApi>()
val taskTypeService = getService<TaskTypeApi>()
val vbPortalService = getService<VbPortalApi>()

suspend fun vbJs() {
    vbCommon()
    vbPortalRoles = vbPortalService.vbPortalRoles()
}