package hu.mkik.vb.portal.model

import hu.simplexion.z2.localization.LocalizationProvider

enum class ProceedingEventType : LocalizationProvider {
    AllapotValtozas,
    Egyeb,
    Lezaras,
    PerertekValtozas,
    ResztvevoAktivalas,
    ResztvevoDeaktivalas,
    ResztvevoFrissites,
    ResztvevoMeghivas,
    TanacsMegalakul,
    TargyalasKituzes,
    UjDokumentum,
    UjResztvevo,
    VisszontKeresetiErtekValtozas,

    FeladatLetrehozas,
    FeladatModositas,
    FeladatKesz,
    FeladatKeszVissza,

    DokumentumFeltoltes,
    DokumentumLetoltes

}