package hu.mkik.vb.portal.ui.proceeding

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.isSecretary
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.routing.NavRouter

object ProceedingList : NavRouter(strings.proceedings, icons.proceeding, useParentNav = true, loggedIn = true, default = { list() }) {
    val add by AddProceeding
    val edit by proceedingRouter
}

private fun Z2.list() =
    tablePage<Proceeding> {

        header {
            text = strings.proceedings
            search()
            if (isSecretary) {
                action(strings.addProceeding) { AddProceeding.open() }
            }
        }

        rowId = { it.uuid }
        query = { proceedingService.active() }
        doubleClickFun = { proceedingRouter.open(it.uuid) }

        with(Proceeding()) {
            schematicColumn { caseNumber }
            schematicColumn { primaryClaimantName }
            schematicColumn { primaryRespondentName }
            schematicColumn { claimValue }
            schematicColumn { secretaryName }
        }

        actionColumn {
            action {
                label = browserStrings.details
                handler = { proceedingRouter.open(it.uuid) }
            }
        }

    }
