package hu.mkik.vb.portal.ui.proceeding.overview

import hu.mkik.vb.portal.model.*
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.isProceedingManager
import hu.mkik.vb.portal.ui.proceeding.*
import hu.mkik.vb.portal.ui.proceeding.hearing.modals.editHearing
import hu.mkik.vb.portal.ui.proceeding.participation.components.participant
import hu.mkik.vb.portal.ui.proceeding.participation.components.participants
import hu.mkik.vb.portal.ui.proceeding.task.components.editTask
import hu.mkik.vb.portal.ui.spaceService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.taskTypes
import hu.mkik.vb.portal.ui.util.loading
import hu.mkik.vb.portal.ui.util.localized
import hu.mkik.vb.portal.ui.util.section
import hu.mkik.vb.portal.ui.util.tertiaryContainer
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.locales.localized
import hu.simplexion.z2.localization.localized
import hu.simplexion.z2.localization.runtime.Currency
import hu.simplexion.z2.localization.runtime.localizedAmount
import hu.simplexion.z2.localization.text.LocalizedText

fun Z2.overview() =
    surfaceContainerLowest(borderOutline) {
        title(currentProceeding.proceeding.caseNumber, strings.overview)
        grid {
            gridTemplateColumns = "1fr max-content"
            gridTemplateRows = "min-content 1fr"

            summary()

            if (currentProceeding.hearings.value.any { it.status == HearingStatus.Organized }) {
                hearingSection().also { it.gridColumn = "1/3" }
            }

            tasksSection().also { it.gridColumn = "1/3" }

            eventsSection().also { it.gridColumn = "1/3" }
        }
    }

private fun Z2.summary() {
    grid(gridGap8) {
        gridTemplateColumns = "300px 1fr"
        gridAutoRows = "min-content"
        rowGap = "16px"

        val proceeding = currentProceeding.proceeding
        val settings = currentProceeding.settings

        div { +strings.subject.localeCapitalized }
        div { +proceeding.subject }

        div { +settings.claimantRole.name.localeCapitalized }
        participant(settings.claimantRole.primaryOrNull, primaryText)

        div(pl24, bodySmall) { +strings.representedBy.localeCapitalized }
        participant(settings.claimantRepresenterRole.primaryOrNull)

        div { +settings.respondentRole.name.localeCapitalized }
        participant(settings.respondentRole.primaryOrNull, primaryText)

        div(pl24, bodySmall) { +strings.representedBy.localeCapitalized }
        participant(settings.respondentRepresenterRole.primaryOrNull)

        div { +strings.claimValue.localeCapitalized }
        div(displayFlex, flexDirectionRow) {
            style.alignItems = "baseline"
            div { +proceeding.claimValue.localizedAmount(Currency.HUF) }
            div(bodySmall, pl8) { +"HUF" }
        }

        if (proceeding.counterClaimValue != 0L) {
            div(pt8) { +strings.counterClaimValue.localeCapitalized }
            div(displayFlex, flexDirectionRow) {
                style.alignItems = "baseline"
                div { +proceeding.counterClaimValue.localizedAmount(Currency.HUF) }
                div(bodySmall, pl8) { +"HUF" }
            }
        }

        settings.chairmanRole.firstOrNull?.let {
            div { +settings.chairmanRole.name.localeCapitalized }
            participant(it, primaryText)
        }

        settings.claimantArbitratorRole.all.let {
            if (it.isNotEmpty()) {
                div { +settings.claimantArbitratorRole.name.localeCapitalized }
                participants(it, primaryText)
            }
        }

        settings.respondentArbitratorRole.all.let {
            if (it.isNotEmpty()) {
                div { +settings.respondentArbitratorRole.name.localeCapitalized }
                participants(it, primaryText)
            }
        }
    }
}

private fun Z2.hearingSection() =
    section(strings.hearings) {
        addCss(pt8)
        div().attach(currentProceeding.hearings) { hearings -> // FIXME this div should be unnecessary
            grid {
                gridTemplateColumns = "max-content max-content max-content 1fr"
                rowGap = 4.px
                hearings
                    .sortedBy { it.start }
                    .sortedBy { it.date }
                    .forEach { if (it.status == HearingStatus.Organized) hearingOverview(it) }
            }
        }
    }.also { it.addCss(pt24) }

private fun Z2.hearingOverview(hearing: Hearing) {
    loading {
        val space = spaceService.get(hearing.site)
        div(displayContents) {
            od(hearing.date.localized).first()
            od(hearing.start.toString())
            od(space.address)
            od(hearing.note).last()

            onMouseEnter { children.forEach { it.addCss(tertiaryContainer, cursorPointer) } } // FIXME class name
            onMouseLeave { children.forEach { it.removeCss(tertiaryContainer, cursorPointer) } }  // FIXME class name

            onClick {
                if (isProceedingManager) {
                    editHearing(hearing) {
                        currentProceeding.refreshHearings()
                    }
                }
            }
        }
    }
}

private fun Z2.tasksSection() =
    section(strings.tasks) {
        addCss(pt8)
        div().attach(currentProceeding.tasks) { tasks -> // FIXME this div should be unnecessary
            grid {
                gridTemplateColumns = "max-content 1fr 1fr max-content max-content"
                rowGap = 4.px
                tasks.sortedBy { it.deadline }.forEach { if (!it.done) taskOverview(it) }
                div(displayFlex, justifyContentCenter, flexDirectionRow) {
                    gridColumn = "1/6"
                    // FIXME hard coded route name
                    textButton(strings.allTasks) { proceedingRouter.openWith(proceedingRouter, currentProceeding.uuid, "tasks") }
                }
            }
        }
    }.also { it.addCss(pt24) }

private fun Z2.taskOverview(task: Task) {
    div(displayContents) {
        od(task.deadline.localized).first()
        od(task.type.localized)
        od(task.assignedTo.fullName)
        od(task.modifiedBy.participantName, small = true)
        od(task.modifiedAt.localized, small = true).last()

        onMouseEnter { children.forEach { it.addCss(tertiaryContainer, cursorPointer) } } // FIXME class name
        onMouseLeave { children.forEach { it.removeCss(tertiaryContainer, cursorPointer) } }  // FIXME class name

        onClick {
            editTask(task) {
                currentProceeding.refreshTasks()
                currentProceeding.refreshEvents()
            }
        }
    }
}

private fun Z2.eventsSection() =
    section(strings.esemenyek) {
        addCss(pt8)

        div().attach(currentProceeding.events) { events ->  // FIXME this div should be unnecessary
            grid {
                gridTemplateColumns = "max-content max-content max-content 1fr max-content max-content"
                rowGap = 4.px
                events.forEach { eventOverview(it) }
            }
        }

        div(displayFlex, justifyContentCenter, flexDirectionRow) {
            gridColumn = "1/7"
            textButton(strings.allEvents) { }
        }
    }.also { it.addCss(pt24) }

private fun Z2.eventOverview(event: ProceedingEvent) {
    if (event.type == ProceedingEventType.DokumentumLetoltes) return

    div(displayContents) {
        od(event.type.localized).first()

        when (event.type) {
            ProceedingEventType.UjDokumentum -> {
                eventParameters(event.documentName, strings.type, currentProceeding.documentTypeMap[event.documentType]?.name)
            }

            ProceedingEventType.FeladatLetrehozas -> {
                val task = currentProceeding.tasks.value.first { it.uuid == event.task }
                eventParameters(taskTypes[task.type]?.name, strings.assignedTo, event.participation.fullName)
            }

            ProceedingEventType.FeladatModositas -> {
                val task = currentProceeding.tasks.value.first { it.uuid == event.task }
                val info = task.deadline.localized + " / " + event.participation.fullName
                eventParameters(taskTypes[task.type]?.name, strings.info, info)
            }

            ProceedingEventType.FeladatKesz -> {
                val task = currentProceeding.tasks.value.first { it.uuid == event.task }
                eventParameters(taskTypes[task.type]?.name, strings.status, strings.done.localized)
            }

            ProceedingEventType.FeladatKeszVissza -> {
                val task = currentProceeding.tasks.value.first { it.uuid == event.task }
                eventParameters(taskTypes[task.type]?.name, strings.status, strings.inProgress.localized)
            }

            ProceedingEventType.UjResztvevo -> {
                eventParameters(event.additionalInfo, strings.role, event.participation.proceedingRole.name)
            }

            else -> {
                eventParameters("", null, event.additionalInfo)
            }
        }

        od(event.createdBy.participantName, true)
        od(event.createdAt.localized, true).last()

        onMouseEnter { children.forEach { it.addCss(tertiaryContainer, cursorPointer) } } // FIXME class name
        onMouseLeave { children.forEach { it.removeCss(tertiaryContainer, cursorPointer) } }  // FIXME class name

        onClick { }
    }
}

private fun Z2.eventParameters(main: String?, subTitle: LocalizedText?, subContent: String?) {
    od(main)
    od(subTitle?.let { "$it: " } ?: "", true)
    od(subContent, true)
}

private fun Z2.od(content: LocalizedText, small: Boolean = false) =
    od(content.toString(), small)

private fun Z2.od(content: String?, small: Boolean = false) =
    div(pr24, h32, displayFlex, alignItemsCenter) {
        if (small) addCss(bodySmall, onSurfaceVariantText)
        span { +content }
    }

private fun Z2.first() {
    addCss(pl8, borderTopLeftRadiusExtraSmall, borderBottomLeftRadiusExtraSmall)
}

private fun Z2.last() {
    addCss(pr8, borderTopRightRadiusExtraSmall, borderBottomRightRadiusExtraSmall)
}