package hu.mkik.vb.portal.ui.util

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.ui.roleGroups
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.browser.css.borderError
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.displayNone
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.immaterial.select.SelectBase
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.localization.localizedTextStore
import hu.simplexion.z2.schematic.Schematic
import hu.simplexion.z2.util.UUID
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import kotlin.time.Duration.Companion.days

fun Z2.show() {
    this.removeCss(displayNone)
}

fun Z2.hide() {
    this.addCss(displayNone)
}

inline fun Z2.showIf(condition : () -> Boolean) { showIf(condition()) }

fun Z2.showIf(condition : Boolean) {
    if (condition) {
        this.removeCss(displayNone)
    } else {
        this.addCss(displayNone)
    }
}

fun LocalDateTime.plusDays(value : Int) =
    toInstant(TimeZone.currentSystemDefault()).plus(value.days).toLocalDateTime(TimeZone.currentSystemDefault())

fun String?.withParenthesis() =
    if (this == null) "" else "($this)"

val UUID<*>.localized
    get() = localizedTextStore[this.toString()]?.toString() ?: this.toShort()

fun List<Participation>.filterByRole(role: UUID<Role>): List<Participation> {
    val roles = roleGroups.filter { it.group == role }.map { it.item } + role
    return this.filter { it.proceedingRole in roles }
}

fun errorBorder(node: Z2) {
    node.removeCss(borderOutline)
    node.addCss(borderError)
}

fun normalBorder(node: Z2) {
    node.removeCss(borderError)
    node.addCss(borderOutline)
}

fun SelectBase<*>.customChip() {
    state.supportEnabled = false
    style.marginRight = "12px"
    style.marginBottom = "8px"
    forceReadOnly = true
}

suspend fun <T : Schematic<T>> ifValidForCreate(schematic: T, processFun: suspend (schematic: T) -> Unit): Boolean {
    schematic.touch()
    return if (schematic.isValidForCreate) {
        processFun(schematic)
        true
    } else {
        snackbar(strings.invalidFields)
        false
    }
}


suspend fun <T : Schematic<T>> ifValid(schematic: T, processFun: suspend (schematic: T) -> Unit): Boolean {
    schematic.touch()
    return if (schematic.isValid) {
        processFun(schematic)
        true
    } else {
        snackbar(strings.invalidFields)
        false
    }
}