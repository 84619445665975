package hu.mkik.vb.portal.ui.account.pages

import hu.mkik.vb.portal.ui.account.model.PasswordResetData
import hu.mkik.vb.portal.ui.accountVBService
import hu.mkik.vb.portal.ui.component.singlePageDialogWithTitleAndLanguages
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.displayGrid
import hu.simplexion.z2.browser.css.pl24
import hu.simplexion.z2.browser.css.pr24
import hu.simplexion.z2.browser.css.pt24
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.BoundField
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.fr
import hu.simplexion.z2.browser.material.modal.modal
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.textfield.TextField
import hu.simplexion.z2.browser.routing.SimpleRouter
import mainRouter

val PasswordResetInit = SimpleRouter(loggedIn = false) {
    singlePageDialogWithTitleAndLanguages(strings.requestPasswordReset) { passwordResetInit() }
}

private fun Z2.passwordResetInit() =
    grid("1fr", "min-content min-content", displayGrid, pl24, pr24) {

        val data = PasswordResetData()
        lateinit var accountNameField: BoundField<String>

        form(displayGrid) {
            gridTemplateColumns = 1.fr
            gridTemplateRows = "min-content min-content"

            accountNameField = field { data.accountName } label strings.passwordResetInitLabel
        }

        grid("1fr min-content", "min-content", pt24) {
            div {
                style.marginLeft = "-8px"
                textButton(strings.back) { mainRouter.back() }
            }

            filledLaunchButton(strings.next) {
                // have to use field values directly as Chrome autofill does not fire proper field change
                // event, see https://stackoverflow.com/questions/35049555/chrome-autofill-autocomplete-no-value-for-password
                // FIXME there are actually events fired but they are the wrong type

                val accountNameOrEmail = (accountNameField.uiField as TextField).inputElement.value

                if (!data.touch()) return@filledLaunchButton

                try {
                    accountVBService.resetPassword(accountNameOrEmail)
                    modal {
                        style.maxWidth = 400.px
                        title(strings.successfulPasswordResetInit)
                        body {
                            +strings.successfulPasswordResetInitExplanation
                        }
                        buttons {
                            gridTemplateColumns = "1fr min-content"
                            gridTemplateRows = "min-content"
                            div { }
                            filledLaunchButton(strings.ok) {
                                Login.open()
                                close()
                            }
                        }
                    }
                } catch (ex: Exception) {
                    snackbar(strings.passwordResetError)
                }
            }
        }
    }
