package hu.mkik.vb.portal.ui.account.pages

import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.application.session
import hu.simplexion.z2.auth.sessionService
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.fr
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.textfield.TextField
import hu.simplexion.z2.browser.material.textfield.textField
import hu.simplexion.z2.browser.routing.SimpleRouter
import hu.simplexion.z2.localization.locales.localeCapitalized
import loadCoreData
import mainRouter

val SecurityCode = SimpleRouter(loggedIn = false) { securityCode() }

private fun Z2.securityCode() {
    surfaceContainerLowest(displayFlex, alignItemsCenter, justifyContentCenter) {

        lateinit var securityCodeField : TextField

        grid(gridGap8) {
            gridTemplateColumns = "min-content"
            gridAutoRows = "min-content"

            div(p24, borderRadius8, borderOutline) {
                grid(gridGap32) {
                    gridTemplateColumns = 400.px
                    gridAutoRows = "min-content"

                    img(w400) {
                        src = "/logo.jpg"
                    }

                    div(titleLarge, justifySelfCenter) {
                        text { strings.securityCode.localeCapitalized }
                    }

                    form(displayGrid) {
                        gridTemplateColumns = 1.fr
                        gridTemplateRows = "min-content min-content"

                        securityCodeField = textField("", label = strings.securityCode)
                    }

                    grid {
                        gridTemplateColumns = "1fr min-content"
                        gridTemplateRows = "min-content"

                        style.position = "relative"
                        style.left = "-8px"

                        div { }
                        filledLaunchButton(strings.next) {
                            try {
                                sessionService.activateSession(securityCodeField.value).let {
                                    session = it
                                    loadCoreData()
                                    mainRouter.open()
                                }
                            } catch (ex: Exception) {
                                snackbar(strings.invalidSecurityCode)
                            }
                        }
                    }
                }
            }

            grid {
                gridTemplateColumns = "1fr repeat(3, min-content)"
                gridTemplateRows = "min-content"
                gridGap = 16.px

                textButton(strings.english) { }
                textButton(strings.help) { }
                textButton(strings.privacy) { }
                textButton(strings.term) { }
            }
        }
    }
}
