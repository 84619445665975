package hu.mkik.vb.portal.model.finance

import hu.simplexion.z2.schematic.Schematic

class ProceedingFeeCalculation : Schematic<ProceedingFeeCalculation>() {
    var registrationFee by decimal(0)
    var administrationFee by decimal(scale = 0)
    var arbitratorHonorarium by decimal(scale = 0)
    var arbitratorsHonorarium by decimal(scale = 0)
    var chairmanHonorarium by decimal(scale = 0)
    var reserveFund by decimal(scale = 0)
    var levy by decimal(scale = 0)
    var socialSecurityContribution by decimal(scale = 0)
    var socialSecurityContributionRequirements by schematicList<OutgoingRequirement>()
    var total by decimal(scale = 0)
}