package hu.mkik.vb.portal.ui.component

import hu.mkik.vb.portal.ui.account.model.PasswordResetData
import hu.mkik.vb.portal.ui.securityPolicy
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.auth.model.EntropyCategory
import hu.simplexion.z2.auth.model.PasswordCheck
import hu.simplexion.z2.auth.util.calculatePasswordEntropy
import hu.simplexion.z2.auth.util.policyErrors
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.*
import hu.simplexion.z2.browser.material.fr
import hu.simplexion.z2.localization.localized
import hu.simplexion.z2.localization.text.StaticText
import kotlin.math.min

class NewPassword(
    parent: Z2,
    val askCurrent: Boolean
) : Z2(parent) {

    val data = PasswordResetData()
    lateinit var currentPasswordField: BoundField<String>
    lateinit var passwordField: BoundField<String>
    lateinit var verificationField: BoundField<String>

    var feedback = false
    val policyErrors = state { emptyList<PasswordCheck>() }

    val currentPassword
        get() = if (askCurrent) currentPasswordField.value else ""

    val newPassword
        get() = passwordField.value

    override fun main(): NewPassword {
        form(displayGrid) {
            gridTemplateColumns = 1.fr
            gridTemplateRows = "min-content min-content"

            if (askCurrent) {
                currentPasswordField = field { data.currentPassword }
            }
            passwordField = field { data.nextPassword }
            verificationField = field { data.nextPasswordVerification }

            div().attach(data) {
                validateAndFeedback()
                strength()
            }

            fun Z2.rule(limit: Int, type: PasswordCheck, message: StaticText) {
                if (limit == 0) return
                li {
                    if (type in policyErrors.value && feedback) addCss(errorText)
                    + message.localizedReplace(limit)
                }
            }

            div(bodySmall) {
                attach(policyErrors) {
                    ul {
                        rule(securityPolicy.passwordLengthMinimum, PasswordCheck.Length, strings.minimumCharacter)
                        rule(securityPolicy.uppercaseMinimum, PasswordCheck.Uppercase, strings.minimumUppercase)
                        rule(securityPolicy.digitMinimum, PasswordCheck.Digit, strings.minimumDigit)
                        rule(securityPolicy.specialCharacterMinimum, PasswordCheck.Special, strings.specialCharacterMinimum)
                        rule(securityPolicy.sameCharacterMaximum, PasswordCheck.Same, strings.sameCharacterMaximum)
                        li {
                            if (PasswordCheck.Strength in policyErrors.value && feedback) addCss(errorText)
                            + strings.entropyMinimum.localizedReplace(securityPolicy.minEntropy.localized)
                        }
                    }
                }
            }
        }

        return this
    }

    fun validateAndFeedback() {
        if (askCurrent) {
            if (currentPasswordField.touched && data.currentPassword.isBlank()) {
                currentPasswordField.toError(strings.mandatory)
            }
        }

        if (verificationField.uiField.state.touched) {
            if (data.nextPassword == data.nextPasswordVerification) {
                verificationField.toNormal()
            } else {
                verificationField.toError(strings.passwordMismatch)
            }
        }

        policyErrors.value = securityPolicy.policyErrors(data.nextPassword)
    }

    fun valid(): Boolean {
        data.touch()
        feedback = true
        validateAndFeedback()
        return policyErrors.value.isEmpty() && ! verificationField.error && ! (askCurrent && currentPasswordField.error)
    }

    fun Z2.strength() {

        val entropyValue = calculatePasswordEntropy(newPassword)
        val entropyCategory = EntropyCategory.entries.last { it.min <= entropyValue }

        div(wFull, mb8) {

            div(wFull, bodySmall, onSurfaceVariantText, justifySelfEnd, mb4) {
                if (newPassword.isEmpty()) {
                    + strings.strength
                } else {
                    + entropyCategory.localized
                }
            }

            div(wFull, positionRelative, surfaceContainerHighest, borderRadius4) {
                gridColumn = "1/span3"
                style.height = "8px"
                if (newPassword.isNotEmpty()) {
                    div(borderRadius4) {
                        style.width = "${min(100.0, entropyValue)}%"
                        style.height = "8px"
                        style.backgroundColor = when (entropyCategory) {
                            EntropyCategory.Poor -> "red"
                            EntropyCategory.Weak -> "red"
                            EntropyCategory.Reasonable -> "orange"
                            EntropyCategory.Excellent -> "green"
                        }
                    }
                }
            }
        }
    }


}