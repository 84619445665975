package hu.mkik.vb.portal.model

import hu.simplexion.z2.schematic.Schematic

class Space : Schematic<Space>() {
    var uuid by uuid<Space>(true)
    var name by string(maxLength = 100, blank = false, minLength = 2)
    var address by string(maxLength = 250)
    var description by string(maxLength = 250)
    var type by string(maxLength = 20)
    var active by boolean(true)
}