package hu.mkik.vb.portal.ui.util

import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.util.localLaunch
import kotlinx.browser.document

fun Z2.remoteText(value : String? = null, loader : suspend () -> String?) {

    val node = document.createTextNode(value ?: "").also { htmlElement.append(it) }
    if (value != null) return

    // TODO error handling, loading indicator
    localLaunch {
        loader()?.let { node.data = it }
    }
}