package hu.mkik.vb.portal.ui.proceeding

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.ui.*
import hu.mkik.vb.portal.ui.component.locale
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.proceeding.participation.components.ParticipantSelector
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.application.session
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.snackbar.warningSnackbar
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.text.LocalizedText
import hu.simplexion.z2.util.hereAndNow
import mainRouter.back

object AddProceeding : NavRouter(strings.proceeding, icons.proceeding, useParentNav = true, default = { AddProceedingPage(this).main() })

private class AddProceedingPage(parent: Z2) : Z2(parent) {

    val proceeding = Proceeding().also {
        it.letterOfClaimantDate = hereAndNow().date
    }

    lateinit var claimantEditor: ParticipantSelector
    lateinit var respondentEditor: ParticipantSelector

    override fun main(): AddProceedingPage {
        currentProceeding = ProceedingViewModel()

        addCss(heightFull, overflowHidden, positionRelative)
        surfaceContainerLowest(borderOutline, p24) {

            div(titleLarge, mb24) { text { strings.addProceeding } }

            div(overflowYAuto) {
                grid(gridGap16) {
                    gridTemplateColumns = "200px 1fr"
                    gridAutoRows = "min-content"

                    section(strings.mainInfo)
                    div {
                        field { proceeding.caseNumber }.validateCaseNumber(proceeding)
                        field { proceeding.subject }
                        field { proceeding.description }
                        field { proceeding.letterOfClaimantDate } css pb12
                        locale({ }) { proceeding.language }
                    }

                    section(strings.proceedingType)
                    div { field { proceeding.type } }

                    section(strings.claimant)
                    div { claimantEditor = ParticipantSelector(this, true).main() }

                    section(strings.respondent)
                    div { respondentEditor = ParticipantSelector(this, true).main() }
                }

                grid(borderTopOutlineVariant, gridGap16, pt16, borderTopOutlineVariant) {
                    gridTemplateColumns = "min-content 1fr min-content"

                    textButton(browserStrings.cancel) { back() }

                    div { }

                    filledLaunchButton(browserStrings.add) {
                        proceeding.touch()

                        val caseNumberValid = (
                            proceeding.caseNumber.matches(Regex("VB/\\d{5}"))
                                && proceedingService.checkCaseNumber(proceeding.caseNumber) == null
                            )

                        val claimantValid = claimantEditor.validate()
                        val respondentValid = respondentEditor.validate()

                        val claimantAccount = accountVBService.getUuidByMpaid(claimantEditor.candidate.mpaid)
                        if (claimantAccount != null && claimantEditor.candidate.email.isEmpty()) {
                            warningSnackbar(strings.noEmail)
                            return@filledLaunchButton
                        }

                        val respondentAccount = accountVBService.getUuidByMpaid(respondentEditor.candidate.mpaid)
                        if (respondentAccount != null && respondentEditor.candidate.email.isEmpty()) {
                            warningSnackbar(strings.noEmail)
                            return@filledLaunchButton
                        }

                        val account = accountVBService.get(session.principal!!.cast())
                        if (participationService.candidates(null, null, account.mpaid.toString()).single().email.isEmpty()) {
                            warningSnackbar(strings.noEmail)
                            return@filledLaunchButton
                        }

                        when {
                            !caseNumberValid -> snackbar(strings.invalidFields)
                            !proceeding.isValidForCreate -> snackbar(strings.invalidFields)
                            !claimantValid -> snackbar(strings.invalidFields)
                            !respondentValid -> snackbar(strings.invalidFields)
                            else -> {
                                val uuid = proceedingService.add(proceeding, claimantEditor.candidate, respondentEditor.candidate)
                                proceedingRouter.open(uuid)
                                snackbar(strings.createSuccess)
                            }
                        }
                    }
                }
            }
        }

        return this
    }

    fun Z2.section(title: LocalizedText) {
        div(borderBottomOutlineVariant) {
            gridColumn = "1/3"
        }
        div(pb4, mb8) {
            +title.localeCapitalized
        }
    }

}