package hu.mkik.vb.portal.ui.proceeding.finance.modals

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.finance.IncomingRequirement
import hu.mkik.vb.portal.model.finance.IncomingRequirementType
import hu.mkik.vb.portal.model.finance.ProceedingFinanceBundle
import hu.mkik.vb.portal.ui.component.participationColumn
import hu.mkik.vb.portal.ui.financeService
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.finance.components.feeCalculation
import hu.mkik.vb.portal.ui.proceeding.finance.logic.ProceedingFeeCalculator
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.plusDays
import hu.simplexion.z2.adaptive.modal.modal
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.field.stereotype.decimalField
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.hereAndNow

class IncomingProposal(
    val bundle: ProceedingFinanceBundle,
    val counter: Boolean,
    val onSave: suspend () -> Unit
) {

    init {
        claimValue()
    }


    fun claimValue() =
        modal {
            val valueLabel = if (counter) strings.counterClaimValue else strings.claimValue

            title(valueLabel.localized)

            val copy = bundle.proceeding.copy()

            fun value() : Long =
                if (counter) copy.counterClaimValue else copy.claimValue

            val arbitrationFees = bundle.incomingRequirements.filter { it.type == IncomingRequirementType.ArbitrationFee && ! it.withdrawn && it.counter == counter }
            val currentArbitrationFee = if (arbitrationFees.isEmpty()) 0 else value()
            val proposedArbitrationFees = mutableListOf<IncomingRequirement>()

            body {
                grid("1fr min-content", "1fr 1fr", gridGap24, pt16, pb16, pb24) {

                    // Proposes Requirements --------------------------------------

                    surfaceContainerLowest(borderOutline) {
                        gridConfig("1fr", "min-content min-content min-content 1fr", "1", "1")

                        div(pb16) { +strings.proposed.localeCapitalized }

                        if (counter) {
                            field { copy.counterClaimValue }
                        } else {
                            field { copy.claimValue }
                        }

                        div().attach(copy) {
                            decimalField(
                                value() - currentArbitrationFee,
                                scale = 0,
                                label = strings.amountChange
                            ) { }.state.readOnly = true
                        }

                        div().attach(copy) {
                            arbitrationFeeProposal(arbitrationFees, copy, proposedArbitrationFees)
                            incomingRequirementSummary(proposedArbitrationFees, false)
                        }
                    }

                    // Current Requirements --------------------------------------

                    surfaceContainerLowest(borderOutline, scroll = false) {
                        gridConfig("1fr", "min-content min-content 1fr", "1", "2")

                        div(pb16) { +strings.alreadyAddedItems.localeCapitalized }

                        decimalField(
                            currentArbitrationFee,
                            scale = 0,
                            label = if (counter) strings.counterClaimValue else strings.claimValue
                        ) { }.state.readOnly = true

                        incomingRequirementSummary(arbitrationFees, true)
                    }

                    div().attach(copy) {
                        gridColumn = "2"
                        gridRow = "1/3"
                        feeCalculation(
                            bundle,
                            ProceedingFeeCalculator(copy, bundle.settings, bundle.participations, counter, 3).result
                        )
                    }
                }
            }

            save(strings.addProposedItems) {
                if (counter) {
                    proceedingService.setCounterClaimValue(copy.uuid, copy.counterClaimValue)
                    currentProceeding.proceeding.counterClaimValue = copy.counterClaimValue
                } else {
                    proceedingService.setClaimValue(copy.uuid, copy.claimValue)
                    currentProceeding.proceeding.claimValue = copy.claimValue
                }
                for (proposed in proposedArbitrationFees) {
                    financeService.add(proposed)
                }
                onSave()
                close()
            }
        }

    private fun Z2.incomingRequirementSummary(inData: List<IncomingRequirement>, showRemainingAmount: Boolean) {
        table<IncomingRequirement> {
            fixRowHeight = false
            rowId = { it.uuid }
            data = inData
            with(IncomingRequirement()) {
                schematicColumn { deadline }
                participationColumn(bundle.participations) { participation }
                schematicColumn { requiredAmount }
                if (showRemainingAmount) {
                    schematicColumn { remainingAmount } label strings.missingAmount
                }
                schematicColumn { counter } label strings.counterClaimAbrv initialSize 3.em
            }
        }
    }

    private fun arbitrationFeeProposal(arbitrationFees: List<IncomingRequirement>, proceeding: Proceeding, proposal: MutableList<IncomingRequirement>) {
        val value = if (counter) proceeding.counterClaimValue else proceeding.claimValue
        val alreadyRequiredAmount = arbitrationFees.sumOf { it.requiredAmount }
        val newFeeAmount = ProceedingFeeCalculator(proceeding, bundle.settings, bundle.participations, counter, 3).result.total

        val (claimantShare, respondentShare) = when {
            bundle.proceeding.expedited -> (100 to 0)
            value < 5_000_000 -> (100 to 0) // FIXME move this into proceeding settings
            else -> (50 to 50)
        }

        println("newFeeAmount: $newFeeAmount  alreadyRequiredAmount: $alreadyRequiredAmount")
        val feeDifference = newFeeAmount - alreadyRequiredAmount

        val claimantAmount = claimantShare * feeDifference / 100
        val respondentAmount = respondentShare * feeDifference / 100

        proposal.clear()

        val claimant = participantOf(bundle.settings.claimantRole)
        val respondent = participantOf(bundle.settings.respondentRole)

        if (claimantAmount > 0) {
            proposal += IncomingRequirement().also {
                it.proceeding = bundle.proceeding.uuid
                it.type = IncomingRequirementType.ArbitrationFee
                it.deadline = hereAndNow().plusDays(15).date
                it.participation = if (counter) respondent else claimant
                it.requiredAmount = claimantAmount
                it.remainingAmount = it.requiredAmount
                it.counter = counter
            }
        }

        if (respondentAmount > 0) {
            proposal += IncomingRequirement().also {
                it.proceeding = bundle.proceeding.uuid
                it.type = IncomingRequirementType.ArbitrationFee
                it.deadline = hereAndNow().plusDays(15).date
                it.participation = if (counter) claimant else respondent
                it.requiredAmount = respondentAmount
                it.remainingAmount = it.requiredAmount
                it.counter = counter
            }
        }

    }

    fun participantOf(role: UUID<Role>) =
        bundle.participations.firstOrNull { p -> p.proceedingRole == role }?.uuid ?: UUID.nil()
}