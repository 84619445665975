package hu.mkik.vb.portal.api.finance

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.finance.*
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface FinanceApi : Service {

    suspend fun bundle(proceedingUuid : UUID<Proceeding>) : ProceedingFinanceBundle

    // Incoming
    
    suspend fun add(incomingRequirement: IncomingRequirement)

    suspend fun update(incomingRequirement: IncomingRequirement)

    suspend fun withdraw(incomingRequirement: IncomingRequirement)

    suspend fun add(incomingPayment: IncomingPayment)

    suspend fun update(incomingPayment: IncomingPayment)

    suspend fun add(incomingPair: IncomingPair)

    suspend fun removeIncoming(incomingPairUuid: UUID<IncomingPair>)

    // Outgoing
    
    suspend fun add(outgoingRequirement: OutgoingRequirement)

    suspend fun update(outgoingRequirement: OutgoingRequirement)

    suspend fun withdraw(outgoingRequirement: OutgoingRequirement)

    suspend fun add(outgoingPayment: OutgoingPayment)

    suspend fun update(outgoingPayment: OutgoingPayment)

    suspend fun add(outgoingPair: OutgoingPair)

    suspend fun removeOutgoing(outgoingPairUuid: UUID<OutgoingPair>)

}