package hu.mkik.vb.portal.ui.proceeding.task.components

import hu.mkik.vb.portal.model.Task
import hu.mkik.vb.portal.ui.isProceedingManager
import hu.mkik.vb.portal.ui.proceeding.currentCaseNumber
import hu.mkik.vb.portal.ui.proceeding.refreshEvents
import hu.mkik.vb.portal.ui.proceeding.refreshTasks
import hu.mkik.vb.portal.ui.proceeding.task.util.assignee
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.taskService
import hu.mkik.vb.portal.ui.taskTypes
import hu.mkik.vb.portal.ui.util.localized
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.css
import hu.simplexion.z2.browser.css.gridGap8
import hu.simplexion.z2.browser.css.mb8
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.html.gridTemplateColumns
import hu.simplexion.z2.browser.html.gridTemplateRows
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.modal.editModal
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.textfield.textField
import hu.simplexion.z2.schematic.util.diff

fun editTask(task: Task, onChanged: suspend () -> Unit) {
    editModal {
        val noChange = !isProceedingManager
        val copy = task.copy()

        style.width = "minmax(400px, min-content)"

        title("${if (noChange) strings.viewTask else strings.editTask} - $currentCaseNumber")

        body {
            grid(gridGap8) {
                gridTemplateColumns = "400px"
                gridTemplateRows = "repeat(4,min-content)"

                div {
                    style.display = "contents"
                    textField(copy.type.localized, label = strings.type).state.readOnly = true
                    assignee(copy, taskTypes[copy.type]!!.assignToRole).state.readOnly = noChange
                    field { copy.deadline } readOnly noChange
                    field { copy.note } readOnly noChange css mb8
                    if (isProceedingManager) {
                        field { copy.done } readOnly noChange supportEnabled false
                    }
                }
            }
        }

        if (noChange) {
            buttons {
                div { }
                textButton(browserStrings.ok) { close() }
            }
        } else {
            save {
                copy.touch()

                if (!copy.isValid) {
                    snackbar(strings.invalidFields)
                    return@save
                }

                if (task.diff(copy).isNotEmpty()) {
                    taskService.update(copy)
                    refreshTasks()
                    refreshEvents()
                    onChanged()
                }
                close()
            }
        }
    }
}