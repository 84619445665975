package hu.simplexion.z2.browser.css

import hu.simplexion.z2.deprecated.browser.CssClass

val bodyLarge = CssClass("body-large")
val bodyMedium = CssClass("body-medium")
val bodySmall = CssClass("body-small")
val borderBottomError = CssClass("border-bottom-error")
val borderBottomLeftRadiusExtraSmall = CssClass("border-bottom-left-radius-extra-small")
val borderBottomOutline = CssClass("border-bottom-outline")
val borderBottomOutlineVariant = CssClass("border-bottom-outline-variant")
val borderBottomPrimary = CssClass("border-bottom-primary")
val borderBottomRightRadiusExtraSmall = CssClass("border-bottom-right-radius-extra-small")
val borderColorError = CssClass("border-color-error")
val borderColorOutline = CssClass("border-color-outline")
val borderColorPrimary = CssClass("border-color-primary")
val borderError = CssClass("border-error")
val borderOutline = CssClass("border-outline")
val borderPrimary = CssClass("border-primary")
val borderRadiusExtraSmall = CssClass("border-radius-extra-small")
val borderTopLeftRadiusExtraSmall = CssClass("border-top-left-radius-extra-small")
val borderTopOutlineVariant = CssClass("border-top-outline-variant")
val borderTopRightRadiusExtraSmall = CssClass("border-top-right-radius-extra-small")
val caretColorPrimary = CssClass("caret-color-primary")
val elevationLevel1 = CssClass("elevation-level-1")
val elevationLevel2 = CssClass("elevation-level-2")
val elevationLevel3 = CssClass("elevation-level-3")
val error = CssClass("error")
val errorText = CssClass("error-text")
val headlineLarge = CssClass("headline-large")
val headlineMedium = CssClass("headline-medium")
val headlineSmall = CssClass("headline-small")
val labelLarge = CssClass("label-large")
val labelMedium = CssClass("label-medium")
val labelSmall = CssClass("label-small")
val onPrimaryText = CssClass("on-primary-text")
val onSurface = CssClass("on-surface")
val onSurfaceText = CssClass("on-surface-text")
val onSurfaceVariant = CssClass("on-surface-variant")
val onSurfaceVariantText = CssClass("on-surface-variant-text")
val primary = CssClass("primary")
val primaryText = CssClass("primary-text")
val shapeCornerLarge = CssClass("shape-corner-large")
val stateLayerOpacityDrag = CssClass("state-layer-opacity-drag")
val stateLayerOpacityFocus = CssClass("state-layer-opacity-focus")
val stateLayerOpacityHover = CssClass("state-layer-opacity-hover")
val stateLayerOpacityPress = CssClass("state-layer-opacity-press")
val surface = CssClass("surface")
val surfaceContainer = CssClass("surface-container")
val surfaceContainerHigh = CssClass("surface-container-high")
val surfaceContainerHighest = CssClass("surface-container-highest")
val surfaceContainerLow = CssClass("surface-container-low")
val surfaceContainerLowest = CssClass("surface-container-lowest")
val tertiary = CssClass("tertiary")
val tertiaryContainer = CssClass("tertiary-container")
val titleLarge = CssClass("title-large")
val titleMedium = CssClass("title-medium")
val titleSmall = CssClass("title-small")
