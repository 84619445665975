package hu.mkik.vb.portal.ui.administration.locales.modals

import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.adaptive.modal.modal
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.localization.model.Locale
import hu.simplexion.z2.localization.model.Translation
import hu.simplexion.z2.localization.translationService
import hu.simplexion.z2.util.UUID

fun translationEditor(locale: UUID<Locale>, translation: Translation?, table: Table<Translation>) =
    modal {

        val copy = translation?.copy() ?: Translation { this.locale = locale }

        title(strings.addTranslation.localized)

        body {
            field { copy.key }
            field { copy.value }
            field { copy.verified }
        }

        save {
            if (!copy.touch()) return@save
            translationService.put(copy)
            if (translation != null) { // FIXME translation table refresh after add
                translation.copyFrom(copy)
                table.redraw()
            }
            close()
        }
    }