package hu.mkik.vb.portal.ui.task.modals

import hu.mkik.vb.portal.model.Task
import hu.mkik.vb.portal.model.runtime.TaskSummary
import hu.mkik.vb.portal.ui.participationService
import hu.mkik.vb.portal.ui.proceeding.ProceedingViewModel
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.task.components.editTask
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.taskService
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.localLaunch

fun taskEditor(taskUuid: UUID<Task>, table: Table<TaskSummary>) {
    localLaunch {
        val task = taskService.get(taskUuid)

        currentProceeding = ProceedingViewModel()
        currentProceeding.proceeding = proceedingService.get(task.proceeding)
        currentProceeding.participations.value = participationService.list(task.proceeding)

        editTask(task) { table.setData(taskService.query()) }
    }
}