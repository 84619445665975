package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.DocumentType
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface DocumentTypeApi : Service {

    suspend fun query(): List<DocumentType>

    suspend fun add(documentType: DocumentType) : UUID<DocumentType>

    suspend fun update(documentType: DocumentType)

    suspend fun remove(documentType: UUID<DocumentType>)

}