package hu.mkik.vb.portal.ui.proceeding.participation.pages

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.ui.administration.account.pages.EditAccount
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.isProceedingManager
import hu.mkik.vb.portal.ui.proceeding.currentCaseNumber
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.participation.modals.addParticipation
import hu.mkik.vb.portal.ui.proceeding.participation.modals.editParticipation
import hu.mkik.vb.portal.ui.proceeding.participation.modals.inviteParticipant
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.builders.size
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.button.textLaunchButton
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.browser.material.snackbar.warningSnackbar

fun Z2.participationList() =
    tablePage<Participation> {

        header {
            title { title(currentCaseNumber, strings.participants) }
            search()
            action(strings.addParticipant) { addParticipation() } onlyIf isProceedingManager
        }

        table.traceMatch = true

        rowId = { it.uuid }
        state = currentProceeding.participations

        if (isProceedingManager) {
            doubleClickFun = { row -> editParticipation(row) }
        }

        with(Participation()) {
            schematicColumn { fullName }
            schematicColumn { roleName } size "min-content"
            schematicColumn { address }
            schematicColumn { email } size "min-content"
            schematicColumn { rank } size 5.em
            schematicColumn { active } size 5.em
        }

        if (isProceedingManager) {
            column {
                label = strings.actions
                render = { row ->
                    style.marginLeft = "-12px"
                    textButton(strings.edit) { editParticipation(row) }
                    if (row.participant == null) {
                        textLaunchButton(strings.invite) {
                            if (row.email.isBlank()) {
                                warningSnackbar(strings.refreshBeforeInvite)
                            } else {
                                inviteParticipant(row)
                            }
                        }
                    } else {
                        textLaunchButton(strings.account) { EditAccount.open(row.participant!!) }
                    }
                }
                initialSize = "min-content"
            }
        }
    }