package hu.mkik.vb.portal.ui.util

import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.util.localLaunch

fun Z2.loading(builder : suspend Z2.() -> Unit) {
    val placeholder = div { + strings.loading }
    localLaunch {
        builder()
        remove(placeholder)
    }
}