package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.Document
import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.runtime.DocumentBundle
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface DocumentApi : Service {

    suspend fun list(): List<Document>

    suspend fun queryDocuments(proceeding: UUID<Proceeding>): List<Document>

    suspend fun add(bundle: DocumentBundle): List<Document>

    suspend fun update(document: Document)

    suspend fun remove(document: UUID<Document>)

    suspend fun setActive(documentUuid: UUID<Document>, value : Boolean)

}