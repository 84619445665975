package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.ProceedingEvent
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service
import kotlinx.datetime.Instant

interface ProceedingEventApi : Service {

    suspend fun query(proceeding: UUID<Proceeding>, cutoff : Instant): List<ProceedingEvent>

}