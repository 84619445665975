package hu.mkik.vb.portal.model

import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.schematic.Schematic
import hu.simplexion.z2.util.UUID

class VbPortalRoles : Schematic<VbPortalRoles>() {

    var secretariatRole by schematic<Role>() // role("titkárság")
    var participantRole by schematic<Role>() // role("ügy résztvevő")
    var presidencyRole by schematic<Role>() // role("választottbíróság elnöksége")
    var stenographerRole by schematic<Role>() // role("jegyzőkönyvvezetők")

    // technical proceeding roles
    var registrationFeeRole by schematic<Role>() // role("regisztrációs díj kamarai alszámla")
    var administrationFeeRole by schematic<Role>() // role("adminisztrációs díj kamarai alszámla")
    var levyRole by schematic<Role>() // role("NAV illeték számla")
    var reserveFundRole by schematic<Role>() // role("tartalékalap kamarai alszámla")
    var socialSecurityContributionRole by schematic<Role>() // role("NAV SZOCHÓ számla")

}
