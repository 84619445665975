package hu.mkik.vb.portal.api.technical

import hu.mkik.vb.portal.model.technical.SoftwareRelease
import hu.simplexion.z2.services.Service

interface SysAdminApi : Service {

    suspend fun activeRelease() : SoftwareRelease

    suspend fun availableReleases() : List<SoftwareRelease>

    suspend fun installRelease(version : String)

    suspend fun restart()

    suspend fun shutdown()

    suspend fun clearDatabaseScript(): String

}