package hu.mkik.vb.portal.ui.document

import hu.mkik.vb.portal.model.Document
import hu.mkik.vb.portal.ui.isSecretary
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.bodyMedium
import hu.simplexion.z2.browser.css.displayGrid
import hu.simplexion.z2.browser.css.gridGap8
import hu.simplexion.z2.browser.css.positionRelative
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.material.modal.modal
import hu.simplexion.z2.browser.material.vh
import hu.simplexion.z2.util.formatByteLength
import hu.simplexion.z2.localization.locales.localeCapitalized

fun documentModal(document: Document, onChanged : () -> Unit) =
    modal {
        style.maxHeight = 90.vh
        addCss(positionRelative, displayGrid)
        gridTemplateColumns = "1fr"
        gridTemplateRows = "min-content 1fr min-content"

        val copy = document.copy()

        title(document.dmsOneFilingNumber)

        body {
            summary(document)

            if (isSecretary) {
                DocumentDetails(this, document).main()
            }
        }

        save {
            document.active = copy.active
            onChanged()
            close()
        }
    }

fun Z2.summary(document: Document) {
    grid(gridGap8) {
        gridTemplateColumns = "200px 1fr"
        gridAutoRows = "min-content"

        div(bodyMedium) { + strings.name.localeCapitalized }
        div { + document.name }

        div(bodyMedium) { + strings.size.localeCapitalized }
        div { + document.size.formatByteLength(2, browserStrings.zeroBytes, browserStrings.bytes) }
    }
}