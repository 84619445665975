package hu.mkik.vb.portal.ui.administration

import defaultLayout
import hu.mkik.vb.portal.ui.administration.account.pages.AccountList
import hu.mkik.vb.portal.ui.administration.contentType.ContentTypeList
import hu.mkik.vb.portal.ui.administration.documentPermission.DocumentPermissionList
import hu.mkik.vb.portal.ui.administration.documentType.DocumentTypeList
import hu.mkik.vb.portal.ui.administration.feeCondition.FeeConditionList
import hu.mkik.vb.portal.ui.administration.locales.LocaleRouter
import hu.mkik.vb.portal.ui.administration.role.RoleList
import hu.mkik.vb.portal.ui.administration.securityPolicy.EditSecurityPolicy
import hu.mkik.vb.portal.ui.administration.softwareUpdate.SoftwareUpdate
import hu.mkik.vb.portal.ui.administration.space.SpaceList
import hu.mkik.vb.portal.ui.administration.taskType.TaskTypeList
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.secretaryOrTechnical
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledButton
import hu.simplexion.z2.browser.material.card.outlinedCard
import hu.simplexion.z2.browser.material.fr
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.browser.routing.RoutingTarget


object Administration : NavRouter(strings.administration, icons.administration, visibility = secretaryOrTechnical) {
    val account by AccountList
    val role by RoleList
    val fees by FeeConditionList

    // val folderTypes      by folderTypeTopRouter
    val documentPermissions by DocumentPermissionList
    val documentTypes by DocumentTypeList
    val taskTypes by TaskTypeList
    val contentTypes by ContentTypeList
    val locales by LocaleRouter

    // val interfaces       by render(strings.connections, icons.interfaces)         {  }
    val securityPolicy by EditSecurityPolicy

    // val impressum        by render(strings.impressum, icons.impressum)           {  }
    val meetingRooms by SpaceList

    // val history          by historiesRouter
    val softwareUpdate by SoftwareUpdate

    override fun default(receiver: Z2, path: List<String>) {
        receiver.defaultLayout(this, nav) { administration() }
    }
}

fun Z2.administration() =
    surfaceContainerLowest(borderOutline) {
        div(titleLarge, pb24) {
            text { strings.administration }
        }

        grid {
            gridTemplateColumns = "repeat(auto-fit, 300px)"
            gridAutoRows = "min-content"
            gridGap = 8.px

            for (target in Administration.targets) {
                if (target.visible()) adminCard(target)
            }
        }
    }

fun Z2.adminCard(target: RoutingTarget<*>) {
    outlinedCard(target.label) {
        style.display = "grid"
        gridTemplateColumns = 1.fr
        gridTemplateRows = "min-content 1fr min-content"

        div(bodyMedium) {
            text { target.label?.support }
        }

        div(justifySelfEnd, pt24) {
            filledButton(browserStrings.open) { target.open() }
        }
    }
}