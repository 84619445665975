package hu.mkik.vb.portal.ui.proceeding.hearing.modals

import hu.mkik.vb.portal.model.Hearing
import hu.mkik.vb.portal.model.Space
import hu.mkik.vb.portal.ui.hearingService
import hu.mkik.vb.portal.ui.proceeding.currentCaseNumber
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.spaceService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.loading
import hu.mkik.vb.portal.ui.util.plusDays
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.layout.scrolledBoxWithLabel
import hu.simplexion.z2.browser.material.modal.addModal
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.browser.material.textfield.textField
import hu.simplexion.z2.util.hereAndNow
import kotlinx.datetime.LocalTime

fun addHearing(onAdd: suspend () -> Unit) =
    addModal {
        loading {
            val hearing = Hearing()

            hearing.proceeding = currentProceeding.proceeding.uuid
            hearing.date = hereAndNow().plusDays(15).date
            hearing.start = LocalTime(0, 0)

            var address = ""

            title("${strings.addHearing} - $currentCaseNumber")

            body {
                loading {
                    val space = spaceService.list().first { it.active }

                    grid("min-content", "min-content", 16) {
                        address = space.address
                        textField(space.address, label = strings.address) { address = it.value }

                        grid("min-content min-content", "min-content", 16) {
                            field { hearing.date }
                            field { hearing.start }
                        }

                        field { hearing.note }

                        scrolledBoxWithLabel(strings.status) {
                            field { hearing.status }
                        }
                    }
                }
            }

            save {
                var space = spaceService.list().first { it.active }

                if (address != space.address) {
                    val spaceUuid = spaceService.add(Space().also {
                        it.address = address
                        it.name = "auto"
                        it.active = false
                    })
                    space = spaceService.get(spaceUuid)
                }

                hearing.site = space.uuid

                hearing.touch()

                if (!hearing.isValidForCreate) {
                    snackbar(strings.invalidFields)
                    return@save
                }

                hearingService.add(hearing)
                onAdd()
                close()
            }
        }
    }