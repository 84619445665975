import hu.mkik.vb.portal.ui.*
import hu.mkik.vb.portal.ui.account.pages.*
import hu.mkik.vb.portal.ui.administration.Administration
import hu.mkik.vb.portal.ui.archive.ArchiveList
import hu.mkik.vb.portal.ui.bank.Bank
import hu.mkik.vb.portal.ui.proceeding.ProceedingList
import hu.mkik.vb.portal.ui.proceeding.ProceedingViewModel
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.name
import hu.mkik.vb.portal.ui.report.Report
import hu.mkik.vb.portal.ui.task.TaskRouter
import hu.simplexion.z2.adaptive.field.registerFieldImpl
import hu.simplexion.z2.application.bootJs
import hu.simplexion.z2.application.isTest
import hu.simplexion.z2.application.session
import hu.simplexion.z2.auth.authAdminService
import hu.simplexion.z2.browser.browserIcons
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.field.FieldStyle
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.layout.Content
import hu.simplexion.z2.browser.material.button.outlinedIconButton
import hu.simplexion.z2.browser.material.menu.DropdownMenu
import hu.simplexion.z2.browser.material.menu.menuItem
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.routing.*
import hu.simplexion.z2.content.contentTypeService
import hu.simplexion.z2.localization.effectiveLocale
import hu.simplexion.z2.localization.fallbackNamespace
import hu.simplexion.z2.localization.localeService
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.text.LocalizedText
import hu.simplexion.z2.util.localLaunch
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event

suspend fun loadCoreData() {
    locales.clear()
    locales += localeService.list()

    securityPolicy = authAdminService.getPolicy()

    if (isLoggedIn) {
        userName = accountVBService.nameOf(session.principal!!.cast()) ?: ""
        userAccount = session.principal!!.cast()

        contentTypes.clear()
        contentTypes += contentTypeService.list()

        taskTypes.clear()
        taskTypes += taskTypeService.list().associateBy { it.uuid }

        dmsOneTorzsAdatok.clear()
        dmsOneTorzsAdatok += dmsOneService.enums()

        roleGroups.clear()
        roleGroups += accountVBService.roleGroups()

        println("logged in:")
        println("  userName=$userName")
        println("  userAccount=$userAccount")
        println("  isTest=$isTest")
        println("  session=$session")
        println("  roles=${session.roles.joinToString { it.name + ":" + it }}")
    }
}

fun main() {
    window.addEventListener("resize", { event: Event ->
        if (isMobileRender && isMobile) return@addEventListener
        if (isDesktopRender && isDesktop) return@addEventListener

        isMobileRender = isMobile
        // FIXME this drops the content, would be better to re-render it
        mainRouter.browserOpen(window.location.pathname, window.location.search, window.location.hash)
    })

    registerFieldImpl()

    localLaunch {
        traceRouting = true
        //EventCentral.trace = true
        fallbackNamespace = "hu.mkik.vb.portal.ui.strings"

        bootJs(FieldStyle.Filled, strings)

        vbJs()

        loadCoreData()

        customizeStyles()
        mainRouter.receiver = Content.apply { defaultLayout = { router, nav, content -> defaultLayout(router, nav, content) } }
        mainRouter.start()
    }
}

@Suppress("unused")
object mainRouter : BrowserRouter() {
    override val label = strings.main

    private val proceeding by ProceedingList
    private val archive by ArchiveList

    //private val calendar by Calendar
    private val task by TaskRouter
    private val bank by Bank
    private val report by Report
    private val administration by Administration

    val login by Login
    private val securityCode by SecurityCode
    private val activation by Activation
    private val passwordResetInit by PasswordResetInit
    private val passwordReset by PasswordReset
    private val viewAccount by ViewAccount
    private val logout by Logout

    override fun browserOpen(pathname: String, search: String, hash: String, changeState: Boolean) {
        if (!pathname.startsWith("/proceeding") && !currentProceeding.uuid.isNil) {
            currentProceeding = ProceedingViewModel()
        }
        super.browserOpen(pathname, search, hash, changeState)
    }

    override fun default(receiver: Z2, path: List<String>) {
        when {
            isParticipant -> ProceedingList.open()
            isSecretary -> ProceedingList.open()
            isTechnical -> Administration.open()
            isLoggedIn -> ProceedingList.open()
            else -> Login.open()
        }
    }

    override fun notFound(receiver: Z2, path: List<String>) {
        default(receiver, path)
    }
}

fun customizeStyles() {
    val root = document.querySelector(":root") as? HTMLElement ?: return
    with(root.style) {
        setProperty(tableBorder, "none")
        setProperty(tableBorderRadius, "0")
        setProperty(tableHeaderBackground, "var(--md-sys-color-surface-container-lowest)")
        setProperty(tableHeaderBottomBorder, "2px solid var(--md-sys-color-outline)")
    }
}

fun Z2.defaultLayout(router: Router<Z2>, nav: Z2Builder, content: Z2Builder) {
    if (isDesktop) {
        grid(wFull, heightFull, pr16, pb16, boxSizingBorderBox) {
            gridTemplateRows = "min-content 1fr"
            gridTemplateColumns = "min-content 1fr"

            logo()
            header()
            navigation(router, nav)
            content()
        }
    } else {
        grid(wFull, heightFull, boxSizingBorderBox, positionRelative) {
            gridTemplateRows = "min-content 1fr"
            gridTemplateColumns = " 1fr"

            mobileHeader()
            content()
        }
    }
}

fun Z2.logo() {
    grid("1fr", "28px 1fr", h60, pl24, pr24, borderRadius4, mt4) {
        style.marginLeft = 4.px

        if (isTest) {
            style.backgroundColor = "red"
            style.color = "white"

        }

        div(whiteSpaceNoWrap, labelMedium, justifySelfCenter, alignSelfCenter, pt8) {
            text { strings.applicationTitleSupport }
        }

        div(whiteSpaceNoWrap, titleMedium, justifySelfCenter, alignSelfCenter, pb8) {
            if (isTest) {
                text { strings.applicationTitle.localized + " - " + strings.test }
            } else {
                text { strings.applicationTitle }
            }
        }
    }
}

fun Router<*>.backLabel(): LocalizedText? {
    var current = this
    while (current is NavRouter && current.useParentNav && current.parent != null) {
        current = current.parent!!
    }
    return current.parent?.label
}

fun Z2.header() =
    grid {
        gridTemplateColumns = "1fr min-content min-content min-content"
        gridTemplateRows = "60px"
        gridGap = 8.px

        div(alignSelfCenter) {
            // FIXME   searchBar()
        }

        div(displayFlex, alignSelfCenter, borderOutline, borderRadius8, bodySmall, p4, pr8, h32, boxSizingBorderBox) {
            div(pl8, whiteSpaceNoWrap, pr8, alignSelfCenter) { text { effectiveLocale.isoCode.uppercase() } }
        }

        div(displayFlex, alignSelfCenter, borderOutline, borderRadius8, bodySmall, p4, pr8) {
            div(pl8, whiteSpaceNoWrap, pr8, alignSelfCenter) { +userName }
            div {
                style.height = 32.px
                style.width = 32.px
                style.borderRadius = 16.px
                style.backgroundColor = "green"
            }
            localLaunch {
                DropdownMenu(this@div, true) {
                    menuItem("account", icons.accounts, strings.account) { ViewAccount.open(userAccount!!) }
                    menuItem("logout", icons.logout, strings.logout) { Logout.open() }
                }
            }
        }
    }

fun Z2.mobileHeader() {
    logo()
}

fun Z2.navigation(router: Router<Z2>, nav: Z2Builder) {
    grid(pt8, overflowYAuto) {
        gridTemplateRows = "min-content 1fr"
        gridTemplateColumns = "1fr"

        div(displayFlex, alignItemsCenter, pl24) {
            val mainNav = (router is NavRouter && router.useParentNav && router.parent == mainRouter)
            if (!mainNav && router != mainRouter) {
                addCss(pt8)
                outlinedIconButton(browserIcons.back, browserStrings.back) { router.up() }
                div(pl16) { text { router.backLabel()?.localeCapitalized } }
            }
        }

        nav()
    }
}