package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.TaskType
import hu.mkik.vb.portal.model.runtime.TaskTypeRole
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface TaskTypeApi : Service {

    suspend fun list(): List<TaskType>

    suspend fun add(taskType: TaskType) : UUID<TaskType>

    suspend fun update(taskType: TaskType)

    suspend fun get(uuid: UUID<TaskType>) : TaskType

    suspend fun roles() : List<TaskTypeRole>

}