package hu.mkik.vb.portal.model.runtime

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.Task
import hu.mkik.vb.portal.model.TaskType
import hu.simplexion.z2.schematic.Schematic

class TaskSummary : Schematic<TaskSummary>() {
    var uuid by uuid<Task>()
    var proceeding by uuid<Proceeding>()
    var caseNumber by string()
    var caseSubject by string()
    var assigneeName by string()
    var assigneeAccount by uuid<AccountVB>().nullable()
    var type by uuid<TaskType>()
    var deadline by localDate()
    var note by string()
    var done by boolean()
    var modifiedBy by string()
    var modifiedAt by instant()
}