package hu.mkik.vb.portal.ui.component

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.ui.participationService
import hu.mkik.vb.portal.ui.proceeding.currentParticipations
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.loading
import hu.mkik.vb.portal.ui.util.localized
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.immaterial.table.builders.ColumnBuilder
import hu.simplexion.z2.browser.immaterial.table.builders.TableBuilder
import hu.simplexion.z2.localization.label
import hu.simplexion.z2.localization.text.LocalizedText
import hu.simplexion.z2.schematic.Schematic
import hu.simplexion.z2.schematic.SchematicAccessFunction
import hu.simplexion.z2.schematic.access.SchematicAccessContext
import hu.simplexion.z2.schematic.schema.SchemaField
import hu.simplexion.z2.util.UUID

fun participationName(participations: List<Participation>, uuid: UUID<Participation>?) =
    participations.firstOrNull { uuid == it.uuid }?.fullName ?: ""

fun participationNameByAccount(participations: List<Participation>, uuid: UUID<AccountVB>?) =
    participations.firstOrNull { uuid == it.participant }?.fullName ?: ""

fun participationType(participations: List<Participation>, uuid: UUID<Participation>?) =
    participations.firstOrNull { uuid == it.uuid }?.proceedingRole?.localized ?: ""

fun participationTypeByAccount(participations: List<Participation>, uuid: UUID<AccountVB>?) =
    participations.firstOrNull { uuid == it.participant }?.proceedingRole?.localized ?: ""

@SchematicAccessFunction
fun Z2.participation(
    options: List<Participation> = currentParticipations,
    afterChange: (UUID<Participation>) -> Unit = { },
    context: SchematicAccessContext? = null,
    accessor: () -> UUID<Participation>
) = selectField(
        options.map { it.uuid },
        { if (it.isNil) "" else participationName(options, it) },
        {
            div { + participationName(options, it) }
            div(bodySmall, onSurfaceVariantText) { + participationType(options, it) }
        },
        afterChange,
        checkNotNull(context)
    )

@SchematicAccessFunction
fun Z2.participationOrNull(
    options: List<Participation>,
    afterChange: (UUID<Participation>) -> Unit = { },
    context: SchematicAccessContext? = null,
    accessor: () -> UUID<Participation>?
) =
    selectField(
        options.map { it.uuid },
        { if (it.isNil) "" else participationName(options, it) },
        {
            div { + participationName(options, it) }
            div(bodySmall, onSurfaceVariantText) { + participationType(options, it) }
        },
        afterChange,
        checkNotNull(context)
    )

fun TableBuilder<Participation>.participationColumn(inLabel: LocalizedText = strings.participation): ColumnBuilder<Participation> {
    return column {
        label = inLabel
        render = { row ->
            div(pb4, pt4) {
                div(whiteSpaceNoWrap) { +row.fullName }
                div(whiteSpaceNoWrap, bodySmall) { +row.proceedingRole.localized }
            }
        }
        comparator = { a, b -> a.fullName.compareTo(b.fullName) }
        initialSize = "1fr"
    }
}

@SchematicAccessFunction
fun TableBuilder<*>.participationColumn(
    participations: List<Participation>,
    context: SchematicAccessContext? = null,
    accessor: () -> UUID<Participation>?
): ColumnBuilder<*> {
    checkNotNull(context)
    val field = context.field as SchemaField<UUID<Participation>?>

    return column {
        label = strings.participation
        render = { row ->
            div(pb4, pt4) {
                div(whiteSpaceNoWrap) { + participationName(participations, field.getValue(row as Schematic<*>)) }
                div(whiteSpaceNoWrap, bodySmall) { + participationType(participations, field.getValue(row as Schematic<*>)) }
            }
        }
        comparator = { a, b ->
            val an = participationName(participations, field.getValue(a as Schematic<*>))
            val bn = participationName(participations, field.getValue(b as Schematic<*>))
            an.compareTo(bn)
        }
    }
}

@SchematicAccessFunction
fun TableBuilder<*>.participationColumn(
    context: SchematicAccessContext? = null,
    accessor: () -> UUID<Participation>
): ColumnBuilder<*> {
    checkNotNull(context)
    val field = context.field as SchemaField<UUID<Participation>>

    return column {
        label = strings.participation
        render = { row ->
            div {
                loading {
                    val participation = participationService.get(field.getValue(row as Schematic<*>))
                    div(pb4, pt4) {
                        div(whiteSpaceNoWrap) { + participation.fullName }
                        div(whiteSpaceNoWrap, bodySmall) { + participation.proceedingRole.localized }
                    }
                }
            }
        }
        // FIXME ordering of composite columns (participation)
        comparator = { _, _ -> 0 }
    }
}

@SchematicAccessFunction
fun TableBuilder<*>.participationNameColumn(
    participations: List<Participation>,
    context: SchematicAccessContext? = null,
    accessor: () -> UUID<Participation>
): ColumnBuilder<*> {
    checkNotNull(context)
    val field = context.field as SchemaField<UUID<Participation>>

    return column {
        label = field.label(context.schematic)
        render = { + participationName(participations, field.getValue(it as Schematic<*>)) }
        comparator = { a, b ->
            val an = participationName(participations, field.getValue(a as Schematic<*>))
            val bn = participationName(participations, field.getValue(b as Schematic<*>))
            an.compareTo(bn)
        }
    }
}

@SchematicAccessFunction
fun TableBuilder<*>.participationTypeColumn(
    participations: List<Participation>,
    context: SchematicAccessContext? = null,
    accessor: () -> UUID<Participation>
): ColumnBuilder<*> {
    checkNotNull(context)
    val field = context.field as SchemaField<UUID<Participation>>

    return column {
        label = strings.role
        render = { + participationType(participations, field.getValue(it as Schematic<*>)) }
        comparator = { a, b ->
            val an = participationType(participations, field.getValue(a as Schematic<*>))
            val bn = participationType(participations, field.getValue(b as Schematic<*>))
            an.compareTo(bn)
        }
    }
}


@SchematicAccessFunction
fun TableBuilder<*>.participationByAccountColumn(
    participations: List<Participation>,
    context: SchematicAccessContext? = null,
    accessor: () -> UUID<AccountVB>
): ColumnBuilder<*> {
    checkNotNull(context)
    val field = context.field as SchemaField<UUID<AccountVB>>

    return column {
        label = strings.participation
        render = { row ->
            div(pb4, pt4) {
                div(whiteSpaceNoWrap) { + participationNameByAccount(participations, field.getValue(row as Schematic<*>)) }
                div(whiteSpaceNoWrap, bodySmall) { + participationTypeByAccount(participations, field.getValue(row as Schematic<*>)) }
            }
        }
        comparator = { a, b ->
            val an = participationNameByAccount(participations, field.getValue(a as Schematic<*>))
            val bn = participationNameByAccount(participations, field.getValue(b as Schematic<*>))
            an.compareTo(bn)
        }
    }
}