package hu.mkik.vb.portal.ui.component

import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.pb16
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.text.LocalizedText

fun Z2.titledTableContainer(
    title : LocalizedText,
    builder : Z2.() -> Unit
) {
    surfaceContainerLowest(borderOutline, pb16, scroll = false) {
        gridConfig("1fr", "min-content 1fr")
        div(pb16) { + title.localeCapitalized }
        builder()
    }
}