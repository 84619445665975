package hu.mkik.vb.portal.model.finance

import hu.simplexion.z2.localization.text.LocalizedTextProvider

enum class OutgoingRequirementType : LocalizedTextProvider {
    RegistrationFee,
    AdministrationFee,
    ArbitratorsHonorarium,
    ChairmanHonorarium,
    ReserveFund,
    Levy,
    SocialSecurityContribution,
    Expense,
    Refund;

    companion object {
        val arbitrationCostTypes = listOf(
            AdministrationFee,
            ArbitratorsHonorarium,
            ChairmanHonorarium,
            ReserveFund,
            SocialSecurityContribution
        )
    }
}