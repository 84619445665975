package hu.mkik.vb.portal.ui.report

import hu.simplexion.z2.localization.text.LocalizedTextProvider

internal object reportStrings : LocalizedTextProvider {
    val accountingDate = static("Számfejtés leadása")
    val administrationFee = static("Kamara")
    val arbitrationFee = static("Meghatározott eljárási díj")
    val arbitrationFeeDates = static("Eljárási díj befolyás kelte")
    val caAdministrationFee = static("Viszontkereset kamara")
    val caArbitrationFee = static("Meghatározott viszontkereseti eljárási díj")
    val caChairmanHonorarium = static("Viszontkereset Elnök honor")
    val caClaimantArbitratorsHonorarium = static("Viszontkereset Felperes Bíró honor")
    val caLevy = static("Viszontkereset illeték")
    val caReserveFund = static("Viszontkereseti tartalékalap")
    val caRespondentArbitratorsHonorarium = static("Viszontkereset Alperes Bíró honor")
    val caSocialSecurityContribution = static("Viszontkereseti szociális hozzájárulás")
    val caseNumber = static("VB ügyszám")
    val chairmanHonorarium = static("Elnök honor")
    val chairmanName = static("Elnök")
    val charimanHonorariumTotal = static("Elnöki honor összes")
    val claimValue = static("Perérték")
    val claimantAccountNumber = static("Visszautalás számlaszám")
    val claimantAddresses = static("Felperes cím")
    val claimantArbitratorName = static("Fp. Bíró")
    val claimantArbitratorsHonorarium = static("Fp. Bíró honor")
    val claimantArbitratorsHonorariumTotal = static("Felperesi honor összes")
    val claimantCity = static("Felperes cím")
    val claimantName = static("Felperes név")
    val claimantNames = static("Felperes")
    val claimantRefundAmount = static("Visszautalás felperesnek")
    val closeDate = static("Befejezés kelte")
    val closeType = static("Befejezés módja")
    val contractDate = static("VB szerződés dátuma")
    val counterClaimValue = static("viszontkereseti perérték")
    val expenseDates = static("Szakértői díj, választottbírói költség befolyás kelte")
    val expenses = static("Szakértői díj, választottbírói költség")
    val letterOfClaimantDate = static("Beérkezés kelte")
    val levy = static("Illeték")
    val notes = static("Megjegyzés")
    val paidArbitrationFees = static("Befolyt eljárási díj")
    val refundRecipient = static("visszautalás címzetje")
    val registrationFee = static("Regisztrációs díj összege")
    val registrationFeeDates = static("Regisztrációs díj befolyt")
    val reserveFund = static("Tartalékalap")
    val respondentAccountNumber = static("Visszautalás számlaszám")
    val respondentAddresses = static("Alperes cím")
    val respondentArbitratorName = static("Ap. Bíró")
    val respondentArbitratorsHonorarium = static("Ap. Bíró honor")
    val respondentArbitratorsHonorariumTotal = static("Alperesi honor összes")
    val respondentCity = static("Alperes cím")
    val respondentName = static("Alperes név")
    val respondentNames = static("Alperes")
    val respondentRefundAmount = static("Visszautalás alperesnek")
    val secretary = static("Ügyintéző")
    val socialSecurityContribution = static("Szociális hozzájárulás")
    val subject = static("Jogcím")
    val totalLevy = static("illeték (összes)")
    val verification = static("Ellenőrző sor")
    val closedCases = static("Lezárt ügyek száma")
    val paidArbitrationFee = static("Befizetett eljárási díj")
    val honorarium = static("Honorárium")
    val refund = static("Visszautalandó")
    val openedCases = static("Beérkezett ügyek száma")
    val accumulatedReserveFund = static("Kumulált tartalékalap / áthozat")
    val amount = static("összeg")
    val recipient = static("címzett")
    val account = static("számlaszám")

}