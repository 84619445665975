package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.dmsone.DMSOneTorzsAdat
import hu.simplexion.z2.services.Service

interface DMSOneApi : Service {

    suspend fun enums(): List<DMSOneTorzsAdat>

    suspend fun dmsoneImport()

}