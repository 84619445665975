package hu.mkik.vb.portal.model.finance

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.Proceeding
import hu.simplexion.z2.schematic.Schematic

class OutgoingRequirement  : Schematic<OutgoingRequirement>() {

    var uuid by uuid<OutgoingRequirement>(true)

    var proceeding by uuid<Proceeding>()
    var participation by uuid<Participation>()

    var type by enum<OutgoingRequirementType>()

    var requiredAmount by decimal(scale = 0, min = 1)
    var remainingAmount by decimal(scale = 0, min = 0)

    var deadline by localDate()
    var counter by boolean()
    var withdrawn by boolean()

}