package hu.mkik.vb.portal.ui.util

import hu.simplexion.z2.browser.css.backgroundTransparent
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.p0
import hu.simplexion.z2.browser.css.p16
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.builders.TableBuilder
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.container
import hu.simplexion.z2.browser.layout.surfaceContainerLowest

fun <T> Z2.tablePage(builder : TableBuilder<T>.() -> Unit) =
    surfaceContainerLowest(borderOutline) {
        container(p0, backgroundTransparent, scroll = false) {
            removeCss(p16)
            table(builder)
        }
    }