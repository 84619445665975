package hu.mkik.vb.portal.model.report

import hu.simplexion.z2.schematic.Schematic

class YearSummaryItem : Schematic<YearSummaryItem>() {
    /**
     * 1..12 -> month
     * 13 -> 1st quarter
     * 14 -> 2nd quarter
     * 15 -> 3rd quarter
     * 16 -> 4th quarter
     * 17 -> total
     */
    var period by int()
    var closedCases by int()
    var registrationFee by decimal(scale = 0)
    var paidArbitrationFee by decimal(scale = 0)
    var administrationFee by decimal(scale = 0)
    var reserveFund by decimal(scale = 0)
    var honorarium by decimal(scale = 0)
    var levy by decimal(scale = 0)
    var refund by decimal(scale = 0)
    var openedCases by int()
    var accumulatedReserveFund by decimal(scale = 0)
}