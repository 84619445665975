package hu.mkik.vb.portal.ui.proceeding.document.components

import hu.mkik.vb.portal.model.Document
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceedingService
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.content.contentService
import hu.simplexion.z2.content.model.Content
import hu.simplexion.z2.util.localLaunch
import hu.simplexion.z2.util.vmNowMicro
import kotlinx.coroutines.delay
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.math.min

class ContentUpload(
    val document: Document,
    val content: Content,
    val file: File
) : Z2(
    Uploads.itemContainer,
    classes = arrayOf(
        w304, h48, boxSizingBorderBox,
        displayFlex, alignItemsCenter, justifyContentFlexStart,
        pl16, pr16,
        bodySmall
    )
) {

    lateinit var progress: Z2

    override fun main(): ContentUpload {
        zIndex = 10000

        grid(positionRelative, wFull, boxSizingBorderBox) {
            gridTemplateColumns = "1fr"
            gridTemplateRows = "24px 4px"

            div(overflowHidden) { +content.name }

            div(positionRelative, surfaceContainerHighest) {
                style.height = "4px"
                style.width = "100%"
                div(tertiary) {
                    progress = this
                    style.height = "4px"
                    style.width = "0%"
                }
            }
        }

        localLaunch { uploadFile() }
        return this
    }

    suspend fun uploadFile() {
        var position = 0
        val total = file.size.toInt()
        var remaining = total

        val start = vmNowMicro()

        while (remaining > 0) {
            val size = min(1_000_000, remaining)

            val chunk = file.slice(position, position + size)

            val reader = FileReader()
            reader.readAsArrayBuffer(chunk)
            while (reader.readyState != FileReader.DONE) {
                delay(50)
            }

            contentService.uploadChunk(content.uuid, position.toLong(), Int8Array(reader.result as ArrayBuffer).unsafeCast<ByteArray>())

            position += size
            remaining -= size

            progress.style.width = "${100L * position / total}%"
        }

        progress.style.width = "100%"

        contentService.closeUpload(content.uuid, "") // FIXME sha256 during file upload

        if (currentProceeding.uuid == document.proceeding) {
            currentProceeding.events.value = proceedingService.events(currentProceeding.uuid)
            uploadReady.ready = true
        }

        val duration = 3000 - ((vmNowMicro() - start) / 1000) // 1500 = minimum time to keep the upload progress on screen
        if (duration > 0) delay(duration)

        parent?.remove(this)
    }

}