package hu.mkik.vb.portal.ui.bank

import defaultLayout
import hu.mkik.vb.portal.ui.bank.pages.closedTransactions
import hu.mkik.vb.portal.ui.bank.pages.pendingTransactions
import hu.mkik.vb.portal.ui.bank.pages.statements
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.secretary
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.routing.NavRouter

object Bank : NavRouter(strings.bank, icons.bank, visibility = secretary) {
    val statements by render(strings.statements, icons.report) { statements() }
    val pending by render(strings.pendingTransactions, icons.pendingTransactions) { pendingTransactions() }
    val closed by render(strings.closedTransactions, icons.closedTransactions) { closedTransactions() }

    override fun default(receiver: Z2, path: List<String>) {
        receiver.defaultLayout(this, nav) { statements() }
    }
}