package hu.mkik.vb.portal.ui.administration.contentType

import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.css.backgroundTransparent
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.p0
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.container
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.content.contentTypeService
import hu.simplexion.z2.content.model.ContentType

object ContentTypeList : NavRouter(
    strings.contentTypes,
    icons.contentType,
    useParentNav = true,
    default = { contentTypeList() }
) {
    val add by AddContentType
    val edit by EditContentType
}

private fun Z2.contentTypeList() =
    tablePage<ContentType> {

        header {
            text = strings.contentTypes
            action(strings.addContentType) { AddContentType.open() }
        }

        rowId = { it.uuid }
        query = { contentTypeService.list() }

        schematicColumn { ContentType().extension }
        schematicColumn { ContentType().mimeType }
        schematicColumn { ContentType().sizeLimit }
        schematicColumn { ContentType().allowed }
        editActionColumn { EditContentType.open(it.uuid) }
    }