package hu.mkik.vb.portal.ui.administration.locales.pages

import hu.mkik.vb.portal.ui.administration.locales.LocaleRouter
import hu.mkik.vb.portal.ui.administration.locales.modals.localeEditor
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.localization.localeService
import hu.simplexion.z2.localization.model.Locale

fun Z2.localeList() =
    tablePage<Locale> {

        header {
            text = strings.languages
        }

        rowId = { it.uuid }
        query = { localeService.list() }

        with(Locale()) {
            schematicColumn { isoCode }
            schematicColumn { countryCode }
            schematicColumn { nativeName }

            actionColumn {
                action {
                    label = strings.edit
                    handler = { localeEditor(it) }
                }
                action {
                    label = strings.translations
                    handler = {
                        LocaleRouter.locale = it
                        LocaleRouter.translations.open()
                    }
                }
            }
        }
    }