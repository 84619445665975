package hu.mkik.vb.portal.ui.component

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.finance.IncomingRequirement
import hu.simplexion.z2.browser.css.bodySmall
import hu.simplexion.z2.browser.css.onSurfaceVariantText
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.immaterial.schematic.BoundField
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.localization.locales.localized
import hu.simplexion.z2.localization.localized
import hu.simplexion.z2.schematic.SchematicAccessFunction
import hu.simplexion.z2.schematic.access.SchematicAccessContext

val List<IncomingRequirement>.open
    get() = filter { it.remainingAmount != 0L }

operator fun List<IncomingRequirement>.get(uuid: UUID<IncomingRequirement>?) =
    firstOrNull { uuid == it.uuid }

val IncomingRequirement?.localizedAmount
    get() = this?.requiredAmount?.localized ?: ""

val IncomingRequirement?.localizedType
    get() = this?.type?.localized ?: ""

fun List<IncomingRequirement>.short(uuid: UUID<IncomingRequirement>?) =
    this[uuid].let { r -> "${r.localizedAmount} / ${r.localizedType}" }

fun List<IncomingRequirement>.short(participants: List<Participation>, uuid: UUID<IncomingRequirement>?) =
    this[uuid].let { r -> "${r.localizedAmount} / ${r.localizedType} / ${participationName(participants, r?.participation)}" }

@SchematicAccessFunction
fun Z2.incomingRequirement(
    options: List<IncomingRequirement>,
    participants : List<Participation>,
    afterChange : (UUID<IncomingRequirement>) -> Unit = {  },
    context: SchematicAccessContext? = null,
    accessor: () -> UUID<IncomingRequirement>
): BoundField<UUID<IncomingRequirement>> {

    checkNotNull(context)

    return selectField(
        options.map { it.uuid },
        { if (it.isNil) "" else options.short(participants, it) },
        {
            div { + options.short(it) }
            div(bodySmall, onSurfaceVariantText) { + participationName(participants, options[it]?.participation) }
        },
        afterChange,
        context
    )
}