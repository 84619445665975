package hu.mkik.vb.portal.ui.task.pages

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.model.runtime.TaskSummary
import hu.simplexion.z2.schematic.Schematic

class TaskSummaryFilter : Schematic<TaskSummaryFilter>() {

    var statusFilter by boolean().nullable()
    var assigneeFilter by uuid<AccountVB>().nullable()

    fun filter(item: TaskSummary): Boolean {
        if (statusFilter != null && item.done != statusFilter) return false
        if (assigneeFilter != null && item.assigneeAccount != assigneeFilter) return false
        return true
    }

}