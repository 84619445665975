package hu.mkik.vb.portal.ui.administration.feeCondition

import hu.mkik.vb.portal.model.ProceedingFeeCondition
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.proceedingFeeService
import hu.mkik.vb.portal.ui.secretary
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.backgroundTransparent
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.p0
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.container
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.routing.NavRouter

object FeeConditionList : NavRouter(strings.proceedingFees, icons.fees, useParentNav = true, visibility = secretary, default = { list() }) {
    val add by AddProceedingFee
    val edit by EditProceedingFee
}

private fun Z2.list() =
    surfaceContainerLowest(borderOutline) {

        container(p0, backgroundTransparent, scroll = false) {

            table<ProceedingFeeCondition> {

                header {
                    text = strings.proceedingFees
                    action(strings.addProceedingFee) { AddProceedingFee.open() }
                }

                rowId = { it.uuid }
                query = { proceedingFeeService.list() }
                doubleClickFun = { EditProceedingFee.open(it.uuid) }

                val template = ProceedingFeeCondition()

                schematicColumn { template.proceedingType }
                schematicColumn { template.type }
                schematicColumn { template.validFrom }
                schematicColumn { template.subjectValueLimit }
                schematicColumn { template.minimumFee }
                schematicColumn { template.maximumFee }
                schematicColumn { template.baseFee }
                schematicColumn { template.validFrom }
                schematicColumn { template.percent }
                schematicColumn { template.percentageBasis }

                actionColumn {
                    action {
                        label = browserStrings.details
                        handler = { EditProceedingFee.open(it.uuid) }
                    }
                }

            }
        }
    }