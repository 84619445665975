package hu.mkik.vb.portal.ui.proceeding.finance.modals

import hu.mkik.vb.portal.*
import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.finance.*
import hu.mkik.vb.portal.ui.component.participationColumn
import hu.mkik.vb.portal.ui.component.titledTableContainer
import hu.mkik.vb.portal.ui.financeService
import hu.mkik.vb.portal.ui.proceeding.arbitratorRoles
import hu.mkik.vb.portal.ui.proceeding.finance.components.socialSecurityContribution
import hu.mkik.vb.portal.ui.proceeding.finance.logic.ProceedingFeeCalculator
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.plusDays
import hu.simplexion.z2.adaptive.modal.modal
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.gridColumn
import hu.simplexion.z2.browser.html.gridRow
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.schematic.state
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.browser.material.radiobutton.radioButtonGroup
import hu.simplexion.z2.localization.localized
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.hereAndNow

class OutgoingProposal(
    val bundle: ProceedingFinanceBundle,
    val counter: Boolean,
    val onSave: suspend () -> Unit
) {

    val calculationType = state<OutgoingCalculationType?> { null }

    init {
        outgoingProposal()
    }

    fun outgoingProposal() =
        modal {

            title(if (counter) strings.counterClaimPayments else strings.claimPayments)

            val proposedPayments = mutableListOf<OutgoingRequirement>()

            body {
                gridConfig("1fr 1fr", "1fr 1fr", gap = 24)

                // Számítási mód ----------------------------------------------------

                calculationParameters()

                // SZOCHO TODO double calculation cleanup
                socialSecurityContribution(bundle, counter)

                // Eddig felvett kifizetesek ---------------------------------------

                titledTableContainer(strings.alreadyAddedItems) {
                    outgoingRequirementsSummary(bundle.outgoingRequirements.filter { ! it.withdrawn && it.counter == counter }, true)
                }

                // Javasolt kifizetesek ---------------------------------------------

                titledTableContainer(strings.proposed) {
                    div().attach(calculationType) {
                        paymentProposal(bundle.outgoingRequirements, bundle.proceeding, proposedPayments)
                        outgoingRequirementsSummary(proposedPayments, false)
                    }
                }
            }

            save(strings.addProposedItems) {
                for (proposed in proposedPayments) {
                    if (proposed.requiredAmount != 0L) {
                        financeService.add(proposed)
                    }
                }
                onSave()
                close()
            }
        }

    private fun Z2.outgoingRequirementsSummary(inData: List<OutgoingRequirement>, showRemainingAmount: Boolean) {
        table<OutgoingRequirement> {
            fixRowHeight = false
            rowId = { it.uuid }
            data = inData
            with(IncomingRequirement()) {
                schematicColumn { deadline }
                participationColumn(bundle.participations) { participation }
                schematicColumn { requiredAmount }
                if (showRemainingAmount) {
                    schematicColumn { remainingAmount } label strings.notPaidYet
                }
                schematicColumn { counter } label strings.counterClaimAbrv initialSize 3.em
            }
        }
    }

    private fun Z2.calculationParameters() {
        div {
            gridColumn = "2"
            gridRow = "1"

            radioButtonGroup(null, OutgoingCalculationType.entries.toList(), { + it.localized }) {
                calculationType.value = it
            }
        }
    }

    private fun paymentProposal(alreadyAddedItems: List<OutgoingRequirement>, proceeding: Proceeding, proposal: MutableList<OutgoingRequirement>) {

        val calculation = ProceedingFeeCalculator(proceeding, bundle.settings, bundle.participations, counter).result

        proposal.clear()

        when (calculationType.value) {
            OutgoingCalculationType.MissingArbitrationCostPayment -> missingArbitrationCostPayment(calculation, proposal)
            //ProceedingCloseType.MissingCounterCostPayment -> missingCounterCostPayment(calculation, proposal)
            OutgoingCalculationType.WithdrawalBeforeEstablishment -> withdrawalBeforeEstablishment(proceeding, proposal)
            OutgoingCalculationType.NoJudgementAfterEstablishment -> noJudgementAfterEstablishment(calculation, proposal)
            OutgoingCalculationType.Judgement -> judgement(calculation, proposal)
            null -> return
        }
    }

    fun missingArbitrationCostPayment(calculation: ProceedingFeeCalculation, proposal: MutableList<OutgoingRequirement>) {
        proposal += payment(OutgoingRequirementType.RegistrationFee, registrationFeeRole, calculation.registrationFee)
        refund(proposal)
    }

//    fun missingCounterCostPayment(calculation: ProceedingFeeCalculation, proposal: MutableList<OutgoingRequirement>) {
//        proposal += payment(OutgoingRequirementType.RegistrationFee, registrationFeeRoleName, calculation.registrationFee)
//        refund(proposal)
//    }

    fun withdrawalBeforeEstablishment(proceeding: Proceeding, proposal: MutableList<OutgoingRequirement>) {
        val calculation = ProceedingFeeCalculator(proceeding, bundle.settings, bundle.participations, counter, numberOfArbitrators = 3).result

        proposal += payment(OutgoingRequirementType.RegistrationFee, registrationFeeRole, calculation.registrationFee)
        proposal += payment(OutgoingRequirementType.AdministrationFee, administrationFeeRole, calculation.administrationFee / 2)
        proposal += payment(OutgoingRequirementType.ReserveFund, reserveFundRole, calculation.reserveFund)
        proposal += payment(OutgoingRequirementType.Levy, levyRole, calculation.levy)

        refund(proposal)
    }

    fun noJudgementAfterEstablishment(calculation: ProceedingFeeCalculation, proposal: MutableList<OutgoingRequirement>) {
        val arbitratorRoles = bundle.settings.arbitratorRoles()
        val arbitrators = bundle.participations.filter { it.proceedingRole in arbitratorRoles && it.active }

        proposal += payment(OutgoingRequirementType.RegistrationFee, registrationFeeRole, calculation.registrationFee)
        proposal += payment(OutgoingRequirementType.AdministrationFee, administrationFeeRole, calculation.administrationFee / 2)
        proposal += payment(OutgoingRequirementType.ReserveFund, reserveFundRole, calculation.reserveFund)
        proposal += payment(OutgoingRequirementType.Levy, levyRole, calculation.levy)

        arbitrators.forEach { arbitrator ->
            proposal += if (arbitrator.proceedingRole == bundle.settings.chairmanRole) {
                payment(OutgoingRequirementType.ChairmanHonorarium, arbitrator.proceedingRole, calculation.chairmanHonorarium / 2)
            } else {
                payment(OutgoingRequirementType.ArbitratorsHonorarium, arbitrator.proceedingRole, calculation.arbitratorHonorarium / 2)
            }
        }

        calculation.socialSecurityContributionRequirements.forEach {
            proposal += it.copy().apply {
                deadline = hereAndNow().plusDays(15).date
                participation = participantOf(socialSecurityContributionRole.uuid)
                requiredAmount /= 2
                remainingAmount /= 2
            }
        }

        refund(proposal)
    }

    fun judgement(calculation: ProceedingFeeCalculation, proposal: MutableList<OutgoingRequirement>) {
        val arbitratorRoles = bundle.settings.arbitratorRoles()
        val arbitrators = bundle.participations.filter { it.proceedingRole in arbitratorRoles && it.active }

        proposal += payment(OutgoingRequirementType.RegistrationFee, registrationFeeRole, calculation.registrationFee)
        proposal += payment(OutgoingRequirementType.AdministrationFee, administrationFeeRole, calculation.administrationFee)
        proposal += payment(OutgoingRequirementType.ReserveFund, reserveFundRole, calculation.reserveFund)
        proposal += payment(OutgoingRequirementType.Levy, levyRole, calculation.levy)

        arbitrators.forEach { arbitrator ->
            proposal += if (arbitrator.proceedingRole == bundle.settings.chairmanRole) {
                payment(OutgoingRequirementType.ChairmanHonorarium, arbitrator.proceedingRole, calculation.chairmanHonorarium)
            } else {
                payment(OutgoingRequirementType.ArbitratorsHonorarium, arbitrator.proceedingRole, calculation.arbitratorHonorarium)
            }
        }

        calculation.socialSecurityContributionRequirements.forEach {
            proposal += it.copy().apply {
                deadline = hereAndNow().plusDays(15).date
                participation = participantOf(socialSecurityContributionRole.uuid)
            }
        }

        refund(proposal)
    }

    fun refund(proposal: MutableList<OutgoingRequirement>) {
        val total = proposal.sumOf { it.requiredAmount }

        val claimantReq = requirementSum(bundle.settings.claimantRole)
        val respondentReq = requirementSum(bundle.settings.respondentRole)

        val claimantPercent = if (claimantReq > 0) {
            (100 * (claimantReq + respondentReq)) / claimantReq
        } else {
            0
        }
        val respondentPercent = if (respondentReq > 0) {
            (100 * (claimantReq + respondentReq)) / respondentReq
        } else {
            0
        }

        val claimantShare = (total * claimantPercent) / 100
        val respondentShare = (total * respondentPercent) / 100

        val claimantPaid = incomingPaymentSum(bundle.settings.claimantRole)
        val respondentPaid = incomingPaymentSum(bundle.settings.respondentRole)

        val claimantRefund = claimantPaid - claimantShare
        val respondentRefund = respondentPaid - respondentShare

        if (claimantRefund > 0) {
            proposal += payment(OutgoingRequirementType.Refund, bundle.settings.claimantRole, claimantRefund, true)
        }

        if (respondentRefund > 0) {
            proposal += payment(OutgoingRequirementType.Refund, bundle.settings.respondentRole, claimantRefund, true)
        }
    }

    fun payment(type: OutgoingRequirementType, role: Role, amount: Long, primary: Boolean = false): OutgoingRequirement =
        payment(type, participantOf(role.uuid), amount, primary)


    fun payment(type: OutgoingRequirementType, roleUuid: UUID<Role>, amount: Long, primary: Boolean = false): OutgoingRequirement =
        payment(type, participantOf(roleUuid), amount, primary)

    fun payment(type: OutgoingRequirementType, participation: UUID<Participation>, amount: Long, primary: Boolean = false): OutgoingRequirement =
        OutgoingRequirement().also {
            it.proceeding = bundle.proceeding.uuid
            it.type = type
            it.deadline = hereAndNow().plusDays(15).date
            it.participation = participation
            it.requiredAmount = amount
            it.remainingAmount = amount
            it.counter = counter
        }

    fun requirementSum(role: UUID<Role>): Long {
        val participations = bundle.participations.filter { it.proceedingRole == role }.map { it.uuid }
        return bundle.incomingRequirements
            .filter { it.participation in participations && it.type == IncomingRequirementType.ArbitrationFee && ! it.withdrawn }
            .sumOf { it.requiredAmount }
    }

    fun incomingPaymentSum(role: UUID<Role>): Long {
        val participations = bundle.participations.filter { it.proceedingRole == role }.map { it.uuid }
        return bundle.incomingPayments
            .filter { it.participation in participations }
            .sumOf { it.paymentAmount }
    }

    fun participantOf(role: UUID<Role>, primary: Boolean = false) =
        bundle.participations.firstOrNull { p -> p.proceedingRole == role && if (primary) p.primary else true }?.uuid ?: UUID.nil()

    fun participantOf(roleName: String) =
        participantOf(bundle.roles.firstOrNull { it.name == roleName }?.uuid ?: UUID.nil())

}