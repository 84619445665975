package hu.mkik.vb.portal.model

enum class FeeType {
    RegistrationFee,
    ArbitalTribunalCosts,
    AdministrationFee,
    AdministrationFeeRenewal,
    ArbitratorsHonorarium,
    ArbitratorsHonorariumRenewal,
    ChairmanHonorarium,
    ChairmanHonorariumRenewal,
    ReserveFund,
    Levy,
    SocialSecurityContribution,
    ArbitratorsHonorariumReduction
}