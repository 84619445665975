package hu.mkik.vb.portal

import hu.mkik.vb.portal.model.VbPortalRoles

lateinit var vbPortalRoles : VbPortalRoles

val secretariatRole
    get() = vbPortalRoles.secretariatRole

val participantRole
    get() = vbPortalRoles.participantRole

val presidencyRole
    get() = vbPortalRoles.presidencyRole

val stenographerRole
    get() = vbPortalRoles.stenographerRole

val registrationFeeRole
    get() = vbPortalRoles.registrationFeeRole

val administrationFeeRole
    get() = vbPortalRoles.administrationFeeRole

val levyRole
    get() = vbPortalRoles.levyRole

val socialSecurityContributionRole
    get() = vbPortalRoles.socialSecurityContributionRole

val reserveFundRole
    get() = vbPortalRoles.reserveFundRole

fun vbCommon() {

}