package hu.mkik.vb.portal.ui.report

import hu.mkik.vb.portal.model.report.ProceedingRegisterItem
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.overflowHidden
import hu.simplexion.z2.browser.css.positionRelative
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.browser.material.modal.modal
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.util.hereAndNow
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.text.monthNameTable
import hu.simplexion.z2.localization.withLocalization
import hu.simplexion.z2.xlsx.browser.onExportXlsx
import kotlinx.datetime.number

fun Z2.monthlySummary() =
    surfaceContainerLowest(borderOutline) {
        modal {
            var year = hereAndNow().date.year
            var month = hereAndNow().date.month.number

            title(strings.monthlySummaryReport)
            body {
                yearSelect { year = it }
                monthSelect { month = it }
            }
            save(strings.ok) {
                this@surfaceContainerLowest.run(year, month)
                close()
            }
        }
    }

private fun Z2.run(year: Int, month: Int) {
    lateinit var table: Table<ProceedingRegisterItem>

    grid {
        gridConfig("1fr", "min-content 1fr")
        addCss(overflowHidden)

        div {
            title(strings.monthlySummaryReport.localeCapitalized + " - " + year + ". " + monthNameTable[month - 1]) {
                filledLaunchButton(strings.download) {
                    table.onExportXlsx()
                }
            }
        }

        div(positionRelative) {
            style.overflowX = "scroll"

            div {
                style.minWidth = 8000.px

                table<ProceedingRegisterItem> {
                    table = this.table

                    fixRowHeight = false
                    rowId = { it.caseNumber }
                    query = { proceedingService.monthly(year, month) }

                    val maxContent = "max-content"

                    withLocalization(reportStrings) {
                        with(ProceedingRegisterItem()) {
                            schematicColumn { caseNumber } initialSize maxContent
                            schematicColumn { secretary } initialSize maxContent
                            schematicColumn { claimantName } initialSize maxContent
                            schematicColumn { claimantCity } initialSize maxContent
                            schematicColumn { respondentName } initialSize maxContent
                            schematicColumn { respondentCity } initialSize maxContent
                            schematicColumn { letterOfClaimantDate }
                            schematicColumn { closeDate }
                            schematicColumn { accountingDate }
                            schematicColumn { closeType } initialSize maxContent
                            schematicColumn { subject } initialSize maxContent
                            schematicColumn { notes } initialSize 10.em
                            schematicColumn { claimValue }
                            schematicColumn { counterClaimValue }
                            schematicColumn { arbitrationFee }
                            schematicColumn { caArbitrationFee }
                            schematicColumn { registrationFee }
                            multiLineColumn { registrationFeeDates }
                            multiLineColumn { arbitrationFeeDates }
                            schematicColumn { paidArbitrationFees }
                            schematicColumn { expenses }
                            multiLineColumn { expenseDates }
                            schematicColumn { levy }
                            schematicColumn { administrationFee }
                            schematicColumn { chairmanHonorarium }
                            schematicColumn { claimantArbitratorsHonorarium }
                            schematicColumn { respondentArbitratorsHonorarium }
                            schematicColumn { caLevy }
                            schematicColumn { caAdministrationFee }
                            schematicColumn { caChairmanHonorarium }
                            schematicColumn { caClaimantArbitratorsHonorarium }
                            schematicColumn { caRespondentArbitratorsHonorarium }
                            schematicColumn { chairmanName } initialSize maxContent
                            schematicColumn { claimantArbitratorName } initialSize maxContent
                            schematicColumn { respondentArbitratorName } initialSize maxContent
                            schematicColumn { claimantRefundAmount }
                            schematicColumn { claimantName } initialSize maxContent
                            schematicColumn { claimantAccountNumber } initialSize maxContent
                            schematicColumn { respondentRefundAmount }
                            schematicColumn { respondentName } initialSize maxContent
                            schematicColumn { respondentAccountNumber } initialSize maxContent
                            schematicColumn { contractDate }
                            schematicColumn { reserveFund }
                            schematicColumn { caReserveFund }
                            schematicColumn { socialSecurityContribution }
                            schematicColumn { caSocialSecurityContribution }
                            schematicColumn { verification }
                            schematicColumn { charimanHonorariumTotal }
                            schematicColumn { claimantArbitratorsHonorariumTotal }
                            schematicColumn { respondentArbitratorsHonorariumTotal }
                        }
                    }
                }
            }
        }
    }
}