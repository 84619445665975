package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.ParticipationCandidate
import hu.mkik.vb.portal.model.Proceeding
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.services.Service
import hu.simplexion.z2.util.UUID

interface ParticipationApi : Service {

    suspend fun list(): List<Participation>

    suspend fun list(proceeding: UUID<Proceeding>, technical : Boolean = false): List<Participation>

    suspend fun get(participationUuid: UUID<Participation>) : Participation

    /**
     * @param  technical  When true the history entry will be technical, otherwise it will be business.
     */
    suspend fun add(participation: Participation, technical: Boolean = true, fictive: Boolean = false): UUID<Participation>

    suspend fun update(participation: Participation)

    suspend fun remove(participation: UUID<Participation>)

    suspend fun candidates(proceeding: UUID<Proceeding>?, role: UUID<Role>?, filter : String) : List<ParticipationCandidate>

    suspend fun roleOf(account : UUID<AccountVB>, proceeding: UUID<Proceeding>): UUID<Role>?

    suspend fun nameOf(uuid : UUID<Participation>) : String?

    suspend fun setActive(uuid: UUID<Participation>, active : Boolean)

    suspend fun invite(uuid: UUID<Participation>)

    suspend fun refresh(mpaid: Int)

    suspend fun representations(representative: UUID<Participation>): List<UUID<Participation>>

    suspend fun addRepresentation(representative: UUID<Participation>, represented: UUID<Participation>)

    suspend fun removeRepresentation(representative: UUID<Participation>, represented: UUID<Participation>)

}