package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.model.runtime.AccountSummaryEntry
import hu.mkik.vb.portal.model.runtime.RoleGroupItem
import hu.simplexion.z2.auth.model.Credentials
import hu.simplexion.z2.auth.model.Principal
import hu.simplexion.z2.history.model.HistoryEntry
import hu.simplexion.z2.services.Service
import hu.simplexion.z2.util.UUID

interface AccountVBApi : Service {

    suspend fun add(account: AccountVB): UUID<AccountVB>

    suspend fun getUuidByEmail(email: String): UUID<AccountVB>?

    suspend fun getUuidByMpaid(mpaid: Int): UUID<AccountVB>?

    suspend fun save(account: AccountVB)

    suspend fun get(uuid: UUID<AccountVB>): AccountVB

    suspend fun list(): List<AccountSummaryEntry>

    suspend fun nameOf(uuid: UUID<AccountVB>): String?

    /**
     * Reset the password of the account with the given e-mail address. This is a
     * function available for the public.
     *
     * The function does not tell the user if the e-mail address is registered in the
     * system or not, execution is successful even if there is no account with the
     * e-mail. In this case the system does not send an e-mail but silently ignores
     * the password reset request.
     *
     * The function also checks if there was recent password reset request for the
     * given e-mail. If there was, it does not send a new e-mail but returns with the
     * remaining time before the next e-mail can be sent.
     *
     * @return  0 when the password reset e-mail has been sent (apparently, see above)
     *          the remaining time (in seconds) before the password reset functio
     *          can be executed again for this e-mail address.
     */
    suspend fun resetPassword(accountNameOrEmail: String): Long

    suspend fun setPassword(principalUuid: UUID<Principal>, currentPassword: String, newPassword: String)

    suspend fun roleGroups(): List<RoleGroupItem>

    suspend fun history(uuid : UUID<AccountVB>) : List<HistoryEntry>

    suspend fun refresh(uuid : UUID<AccountVB>)

    suspend fun canActivate(key: Credentials): Boolean
}