package hu.mkik.vb.portal.model.finance

import hu.simplexion.z2.schematic.Schematic

class BankTransactionBundle : Schematic<BankTransactionBundle>() {

    var transaction by schematic<BankTransaction>()
    var incomingPayments by schematicList<IncomingPayment>()
    var outgoingPayments by schematicList<OutgoingPayment>()

}