package hu.mkik.vb.portal.model

import hu.simplexion.z2.localization.LocalizationProvider

enum class AccountVBType : LocalizationProvider {
    Participant,
    Secretariat,
    Presidency,
    Stenographer,
    Technical;
}