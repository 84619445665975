package hu.mkik.vb.portal.ui.proceeding.participation.components

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.ui.isSecretary
import hu.mkik.vb.portal.ui.proceeding.proceedingRouter
import hu.simplexion.z2.browser.css.cursorPointer
import hu.simplexion.z2.browser.css.positionRelative
import hu.simplexion.z2.browser.css.wMinContent
import hu.simplexion.z2.browser.css.whiteSpaceNoWrap
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.onClick
import hu.simplexion.z2.deprecated.browser.CssClass

fun Z2.participant(participation: Participation?, vararg classes: CssClass) {
    div(*classes, wMinContent, whiteSpaceNoWrap, positionRelative) {
        +participation?.fullName
        if (isSecretary && participation != null) {
            addCss(cursorPointer)
            onClick {
                proceedingRouter.participants.open()
            }
        }
    }
}

fun Z2.participants(participations: List<Participation>, vararg classes: CssClass) {
    div(*classes, wMinContent, whiteSpaceNoWrap, positionRelative) {
        for (participant in participations) {
            participant(participant)
        }
    }
}