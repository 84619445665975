package hu.mkik.vb.portal.model.finance

import hu.mkik.vb.portal.model.Proceeding
import hu.simplexion.z2.schematic.Schematic

class OutgoingPair : Schematic<OutgoingPair>() {
    var uuid by uuid<OutgoingPair>(true)

    var proceeding by uuid<Proceeding>()

    var requirement by uuid<OutgoingRequirement>()
    var payment by uuid<OutgoingPayment>().nullable()

    var pairedAmount by decimal(scale = 0, min = 1)
}