package hu.mkik.vb.portal.ui.proceeding.finance.logic

import hu.mkik.vb.portal.model.FeeType
import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.ProceedingSettings
import hu.mkik.vb.portal.model.finance.OutgoingRequirement
import hu.mkik.vb.portal.model.finance.OutgoingRequirementType
import hu.mkik.vb.portal.model.finance.ProceedingFeeCalculation
import hu.mkik.vb.portal.ui.proceeding.arbitratorRoles
import hu.mkik.vb.portal.ui.proceeding.feeTable
import kotlin.math.max
import kotlin.math.min

class ProceedingFeeCalculator(
    val proceeding: Proceeding,
    val settings: ProceedingSettings,
    val participations: List<Participation>,
    val counter: Boolean,
    val numberOfArbitrators: Int? = null
) {

    var amount = if (counter) proceeding.counterClaimValue else proceeding.claimValue

    val errors = mutableListOf<String>()
    val result = ProceedingFeeCalculation()

    val arbitratorRoles = settings.arbitratorRoles()
    val arbitrators = participations.filter { it.proceedingRole in arbitratorRoles && it.active }

    init {
        result.registrationFee = registrationFee()
        result.administrationFee = administrationFee()
        result.arbitratorHonorarium = arbitratorHonorarium()
        result.arbitratorsHonorarium = arbitratorsHonorarium()
        result.chairmanHonorarium = chairmanHonorarium()
        result.reserveFund = reserveFund()
        result.levy = levy()
        result.socialSecurityContributionRequirements = socialSecurityContributionRequirements()
        result.socialSecurityContribution = result.socialSecurityContributionRequirements.sumOf { it.requiredAmount }

        result.total = (
            result.administrationFee +
                result.arbitratorsHonorarium +
                result.chairmanHonorarium +
                result.levy +
                result.reserveFund +
                result.socialSecurityContribution
            )
    }

    private fun registrationFee(): Long {
        if (counter && proceeding.waiveCounterRegistrationFee) return 0

        val fee = feeTable
            .filter { it.proceedingType == proceeding.type && it.type == FeeType.RegistrationFee }
            .sortedBy { it.subjectValueLimit }
            .sortedBy { it.validFrom }
            .lastOrNull { it.subjectValueLimit <= proceeding.claimValue }

        if (fee == null) {
            errors += "registration fee is null"
            return 0
        }

        return fee.baseFee
    }

    private fun administrationFee(): Long {
        val fee = feeTable
            .filter { it.proceedingType == proceeding.type && it.type == FeeType.AdministrationFee }
            .sortedBy { it.subjectValueLimit }
            .sortedBy { it.validFrom }
            .lastOrNull { it.subjectValueLimit <= proceeding.claimValue }

        if (fee == null) {
            errors += "administration fee is null"
            return 0
        }

        return max(fee.minimumFee, fee.baseFee + (amount - fee.subjectValueLimit) * fee.percent / 100_000)
    }

    private fun arbitratorHonorarium(): Long {
        val fee = feeTable
            .filter { it.proceedingType == proceeding.type && it.type == FeeType.ArbitratorsHonorarium }
            .sortedBy { it.subjectValueLimit }
            .sortedBy { it.validFrom }
            .lastOrNull { it.subjectValueLimit <= proceeding.claimValue }

        if (fee == null) {
            errors += "arbitrator honorarium is null"
            return 0
        }

        return max(fee.minimumFee, fee.baseFee + max(0, amount - fee.subjectValueLimit) * fee.percent / 100_000)
    }

    private fun arbitratorsHonorarium(): Long {
        if (numberOfArbitrators != null) {
            return (numberOfArbitrators - 1) * result.arbitratorHonorarium // -1 = chairman
        }

        if (arbitrators.size <= 1) return 0

        return (arbitrators.size - 1) * result.arbitratorHonorarium // -1 = chairman
    }

    private fun chairmanHonorarium(): Long {
        val fee = feeTable
            .filter { it.proceedingType == proceeding.type && it.type == FeeType.ChairmanHonorarium }
            .maxByOrNull { it.validFrom }

        if (fee == null) {
            errors += "chairman honorarium is null"
            return 0
        }

        return result.arbitratorHonorarium * fee.percent / 100_000
    }

    private fun reserveFund(): Long {
        val fee = feeTable
            .filter { it.proceedingType == proceeding.type && it.type == FeeType.ReserveFund }
            .maxByOrNull { it.validFrom }

        if (fee == null) {
            errors += "reserve fund is null"
            return 0
        }

        return (result.chairmanHonorarium + result.arbitratorsHonorarium) * fee.percent / 100_000
    }

    fun levy(): Long {
        val skip = if (counter) proceeding.isRespondentLevyFree else proceeding.isClaimantLevyFree
        if (skip) return 0

        val fee = feeTable
            .filter { it.proceedingType == proceeding.type && it.type == FeeType.Levy }
            .maxByOrNull { it.validFrom }

        if (fee == null) {
            errors += "levy is null"
            return 0
        }

        return min(fee.maximumFee, max(fee.minimumFee, amount * fee.percent / 100_000))
    }

    fun socialSecurityContributionRequirements(): List<OutgoingRequirement> {
        val fee = feeTable
            .filter { it.proceedingType == proceeding.type && it.type == FeeType.SocialSecurityContribution }
            .maxByOrNull { it.validFrom }

        if (fee == null) {
            errors += "socialSecurityContribution is null"
            return emptyList()
        }

        if (numberOfArbitrators != null) {
            return listOf(
                OutgoingRequirement().also { req ->
                    req.proceeding = proceeding.uuid
                    req.type = OutgoingRequirementType.SocialSecurityContribution
                    req.requiredAmount = (result.chairmanHonorarium + result.arbitratorsHonorarium) * fee.percent / 100_000
                    req.counter = counter
                }
            )
        }

        return arbitrators.map { arbitrator ->
            val base = if (arbitrator.proceedingRole == settings.chairmanRole) {
                result.chairmanHonorarium
            } else {
                result.arbitratorHonorarium
            }
            OutgoingRequirement().also { req ->
                req.proceeding = proceeding.uuid
                req.participation = arbitrator.uuid
                req.type = OutgoingRequirementType.SocialSecurityContribution
                req.requiredAmount = if (arbitrator.retired) {
                    0
                } else {
                    max(0, base) * fee.percent / 100_000
                }
                req.counter = counter
            }
        }
    }
}