package hu.mkik.vb.portal.model

import hu.simplexion.z2.schematic.Schematic

class FolderType : Schematic<FolderType>() {
    var uuid by uuid<FolderType>()
    var proceedingType by enum<ProceedingType>()
    var name by string() maxLength 100
    var masterFolder by uuid<FolderType>().nullable()

    override fun toString() = name
}