package hu.mkik.vb.portal.model.dmsone

import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.schematic.Schematic

class DMSOneCim : Schematic<DMSOneCim>() {
    var tipusKod by string()
    var isAlapertelmezett by boolean()
    var sorszam by int()

    var orszag by string()
    var varos by string()
    var irsz by string()
    var megye by string()
    var utca by string()
    var kozter by string()
    var hsz by string()
    var epulet by string(10)
    var lepcsohaz by string(10)
    var emelet by string(10)
    var ajto by string(10)
    var postafiok by string(10)
    var parcelNumber by string()

    val localized : String
        get() {
            // 1094 Budapest, Tompa utca 11. 3. emelete 24.

            val parts = mutableListOf<String>()
            parts += irsz
            parts += "$varos,"
            parts += utca
            parts += kozter
            parts += hsz
            if (epulet.isNotEmpty()) parts += "$epulet épület"
            if (lepcsohaz.isNotEmpty()) parts += "$lepcsohaz lépcsőház"
            if (emelet.isNotEmpty()) parts += "$emelet emelet"
            if (ajto.isNotEmpty()) parts += "$ajto ajtó"
            if (parcelNumber.isNotEmpty()) {
                parts += strings.hrsz.localized + ":" + parcelNumber
            }

            return parts.joinToString(" ")
        }
}