package hu.mkik.vb.portal.ui

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.TaskType
import hu.mkik.vb.portal.model.dmsone.DMSOneTorzsAdat
import hu.mkik.vb.portal.model.runtime.RoleGroupItem
import hu.mkik.vb.portal.model.runtime.TaskTypeRole
import hu.mkik.vb.portal.participantRole
import hu.mkik.vb.portal.secretariatRole
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.simplexion.z2.application.securityOfficerRole
import hu.simplexion.z2.application.technicalAdminRole
import hu.simplexion.z2.auth.model.SecurityPolicy
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.routing.RoutingTarget
import hu.simplexion.z2.browser.routing.effectiveRoles
import hu.simplexion.z2.content.model.ContentType
import hu.simplexion.z2.localization.model.Locale
import hu.simplexion.z2.util.UUID
import kotlinx.browser.window

val isMobile
    get() = window.innerWidth < 450 || window.innerHeight < 450

val isLandscape
    get() = window.innerHeight < window.innerWidth

val isDesktop
    get() = !isMobile

var isMobileRender = isMobile

var isDesktopRender
    get() = !isMobileRender
    set(value) {
        isMobileRender = !value
    }

val isParticipant
    get() = participantRole.uuid in effectiveRoles

val isTechnical
    get() = technicalAdminRole.uuid in effectiveRoles

val isSecretary
    get() = secretariatRole.uuid in effectiveRoles

val isProceedingManager
    get() = secretariatRole.uuid in effectiveRoles || (currentProceeding.settings.chairmanRole in effectiveRoles)

val isSecurityOfficer
    get() = securityOfficerRole.uuid in effectiveRoles

val isTechnicalAdmin
    get() = isSecurityOfficer or isTechnical

val isSecretaryOrTechnical
    get() = isSecretary or isTechnicalAdmin

val secretary = { _ : RoutingTarget<Z2> -> isSecretary }

val secretaryOrTechnical = { _ : RoutingTarget<Z2> -> isSecretaryOrTechnical }

val technical = { _ : RoutingTarget<Z2> -> isTechnical || isSecurityOfficer }

var testPassword = ""

val locales = mutableListOf<Locale>()

val contentTypes = mutableListOf<ContentType>()

val taskTypes = mutableMapOf<UUID<TaskType>, TaskType>()

val roleGroups = mutableListOf<RoleGroupItem>()

val dmsOneTorzsAdatok = mutableListOf<DMSOneTorzsAdat>()

val dmsoneAdathordozok
    get() = dmsOneTorzsAdatok.filter { it.torzsKod == "adathordozok" }

val dmsoneBeerkezesmodok
    get() = dmsOneTorzsAdatok.filter { it.torzsKod == "beerkezesmodok" }

val taskTypeRoles = mutableListOf<TaskTypeRole>()

var userName: String = ""

var userAccount: UUID<AccountVB>? = null

val accountNames = mutableMapOf<UUID<AccountVB>, String>()

val participationNames = mutableMapOf<UUID<Participation>, String>()

lateinit var securityPolicy: SecurityPolicy