package hu.mkik.vb.portal.model.dmsone

import hu.simplexion.z2.localization.localizedTextStore
import hu.simplexion.z2.schematic.Schematic

class DMSOneTorzsAdat : Schematic<DMSOneTorzsAdat>() {
    var torzsKod by string()
    var nev by string()
    var id by int()
    var kod by string()

    val localized
        get() = localizedTextStore["dmsone.$torzsKod/$id"]?.value ?: nev
}