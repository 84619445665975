package hu.mkik.vb.portal.model

import hu.simplexion.z2.schematic.Schematic

class Document : Schematic<Document>() {
    var uuid by uuid<Document>()

    var proceeding by uuid<Proceeding>()
    var documentType by uuid<DocumentType>()
    var folderType by uuid<FolderType>()
    var masterDocument by uuid<Document>().nullable()

    var submitter by uuid<Participation>()
    var uploadedBy by uuid<AccountVB>()

    var name by string(maxLength = 1000)

    var size by long()
    var uploadedAt by instant()
    var referenceNumber by string(maxLength = 20)
    var expedite by boolean()

    var expediteStatus by enum<ExpediteStatus>()

    var active by boolean()

    var submissionMode by int()
    var dataCarrier by int()

    var manualFiling by boolean()
    var privateType by boolean()

    var dmsOneSubmissionNumber by string()
    var dmsOneFilingNumber by string()
}