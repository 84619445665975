package hu.mkik.vb.portal.ui.proceeding.task.components

import hu.mkik.vb.portal.model.Task
import hu.mkik.vb.portal.ui.proceeding.currentCaseNumber
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.refreshEvents
import hu.mkik.vb.portal.ui.proceeding.refreshTasks
import hu.mkik.vb.portal.ui.proceeding.task.util.assignee
import hu.mkik.vb.portal.ui.proceeding.task.util.type
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.taskService
import hu.mkik.vb.portal.ui.taskTypes
import hu.mkik.vb.portal.ui.util.errorBorder
import hu.mkik.vb.portal.ui.util.localized
import hu.mkik.vb.portal.ui.util.normalBorder
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.state
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.immaterial.select.SelectBase
import hu.simplexion.z2.browser.layout.scrolledBoxWithLabel
import hu.simplexion.z2.browser.material.modal.addModal
import hu.simplexion.z2.browser.material.radiobutton.RadioButtonGroup
import hu.simplexion.z2.browser.material.radiobutton.radioButtonGroup
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.hereAndNow

fun addTask(onChanged: suspend () -> Unit) {
    addModal {
        val task = Task()

        task.proceeding = currentProceeding.proceeding.uuid
        task.deadline = hereAndNow().date

        val taskRoleGroup = state<UUID<Role>?> { null }

        lateinit var roleBox: Z2
        lateinit var roleSelect: RadioButtonGroup<UUID<Role>>
        lateinit var typeSelect: SelectBase<*>
        lateinit var participantSelect: SelectBase<*>

        style.width = "minmax(400px, min-content)"

        title("${strings.addTask} - $currentCaseNumber")

        body {
            grid(gridGap24) {
                gridTemplateColumns = "minmax(300px,max-content) 400px"
                gridTemplateRows = "repeat(4,min-content)"

                scrolledBoxWithLabel(strings.role) {
                    radioButtonGroup(
                        taskRoleGroup.value,
                        options = taskTypes.values.map { it.assignToRole }.distinct(),
                        itemBuilderFun = { span(whiteSpaceNoWrap) { + it.localized } },
                        onChange = {
                            task.type = UUID.nil()
                            task.assignedTo = UUID.nil()
                            normalBorder(roleBox)
                            taskRoleGroup.value = it
                        }
                    ).also { roleSelect = it }
                }.also {
                    it.gridRow = "1/span5"
                    roleBox = it
                }

                div().attach(taskRoleGroup) {
                    style.display = "contents"
                    if (taskRoleGroup.value == null) {
                        div(labelMedium, alignSelfCenter, heightFull).also {
                            gridRow = "1/span5"
                        }
                    } else {
                        type(task, taskRoleGroup.value !!).also { typeSelect = it }
                        assignee(task, taskRoleGroup.value !!).also { participantSelect = it }
                        field { task.deadline }
                        field { task.note }
                    }
                }
            }
        }

        save {
            if (roleSelect.valueOrNull == null) {
                snackbar(strings.invalidFields)
                errorBorder(roleBox)
                return@save
            }

            task.touch()

            if (task.type.isNil) {
                typeSelect.toError(strings.mandatorySelect.localized)
            }

            if (task.assignedTo.isNil) {
                participantSelect.toError(strings.mandatorySelect.localized)
            }

            if (! task.isValidForCreate) {
                snackbar(strings.invalidFields)
                return@save
            }

            taskService.add(task)
            refreshTasks()
            refreshEvents()
            onChanged()
            close()
        }
    }
}
