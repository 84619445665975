package hu.mkik.vb.portal.ui.document

import hu.mkik.vb.portal.model.Document
import hu.mkik.vb.portal.ui.*
import hu.mkik.vb.portal.ui.util.remoteText
import hu.mkik.vb.portal.ui.util.section
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.locales.localized

class DocumentDetails(
    parent: Z2,
    val document: Document
) : Z2(parent) {

    override fun main(): DocumentDetails {
        submission()
        sending()
        return this
    }

    fun Z2.submission() {
        section(strings.incomingFiling) {
            addCss(pt16)
            grid(gridGap8) {
                gridTemplateColumns = "200px 1fr"
                gridAutoRows = "min-content"

                field { document.dmsOneFilingNumber } readOnly true
                field { document.dmsOneSubmissionNumber } readOnly true css w400

                div(bodyMedium) { + strings.uploadTime.localeCapitalized }
                div { + document.uploadedAt.localized }

                div(bodyMedium) { + strings.submitter.localeCapitalized }
                div { remoteText(participationNames[document.submitter]) { participationService.nameOf(document.submitter) } }

                div(bodyMedium) { + strings.uploader.localeCapitalized }
                div { remoteText(accountNames[document.uploadedBy]) { accountVBService.nameOf(document.uploadedBy) } }
            }
        }
    }

    fun Z2.sending() {
//        section(strings.outgoingFiling) {
// TODO outgoing filing details
//        }
    }
}