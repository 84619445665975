package hu.mkik.vb.portal.ui.bank.modals

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.model.finance.*
import hu.mkik.vb.portal.ui.bankService
import hu.mkik.vb.portal.ui.component.*
import hu.mkik.vb.portal.ui.financeService
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.loading
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.html.span
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.state
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.browser.material.modal.modal
import hu.simplexion.z2.browser.material.snackbar.errorSnackbar
import hu.simplexion.z2.browser.material.snackbar.warningSnackbar
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.localLaunch
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.locales.localized
import hu.simplexion.z2.schematic.Schematic

fun editTransaction(transaction: BankTransaction) =
    modal {
        style.width = "calc(95vw - 32px)"

        title(strings.edit)

        body {
            TransactionEditor(this, transaction).main()
        }

        save {

        }
    }

private class Assignment : Schematic<Assignment>() {
    var id by int()
    var proceeding by uuid<Proceeding>()
    var participation by uuid<Participation>()
    var incomingPayment by schematic<IncomingPayment>() // used for display the existing pairings
    var incomingRequirement by uuid<IncomingRequirement>() // used for creating a new pairing
    var outgoingPayment by schematic<OutgoingPayment>()// used for display the existing pairings
    var outgoingRequirement by uuid<OutgoingRequirement>() // used for creating a new pairing
    var caseNumber by string()
    var amount by decimal(scale = 0)

    var requirementTypeName by string() // to display the incoming or outgoing type name
}

class TransactionEditor(
    parent: Z2,
    val transaction: BankTransaction
) : Z2(parent) {

    lateinit var proceedings: List<Proceeding>
    lateinit var transactionBundle: BankTransactionBundle

    val selectedProceeding = state<Proceeding?> { null }

    val BankTransaction.isIncoming
        get() = amount > 0

    override fun main(): TransactionEditor {
        loading {
            proceedings = proceedingService.active()
            transactionBundle = bankService.transactionBundle(transaction.uuid)

            val candidates = proceedings.filter { it.caseNumber.lowercase() in transaction.additionalInfo.lowercase() }
            if (candidates.size == 1) selectedProceeding.value = candidates.first()

            grid("400px 1fr", "1fr", 32) {
                details()
                distribution()
            }
        }
        return this
    }

    private fun Z2.details() =
        grid("400px", "repeat(5,min-content)", gap = 16) {
            with(transaction) {
                field { valueDate } readOnly true
                field { amount } readOnly true
                field { peerAccount } readOnly true
                field { peerName } readOnly true
                field { additionalInfo } readOnly true
            }
        }

    private fun Z2.distribution() =
        grid("1fr", "min-content min-content 1fr 1fr") {
            remainingAmount()
            distribute()
            pairings()
        }

    private fun Z2.remainingAmount() {
        div(mb24) {
            span {
                + strings.remainingAmount.localeCapitalized
                + ":"
            }
            span(pl24, primaryText) { + transaction.remainingAmount.localized }
            span(pl8, bodyMedium) { + "HUF" }
        }
    }

    private fun Z2.distribute() {
        grid("1fr min-content", "min-content min-content max-content") {

            val assignment = Assignment()
            val bundle = ProceedingFinanceBundle()

            proceeding(proceedings) { assignment.proceeding } gridColumn "1/3"

            proceedingSelect(assignment, bundle)

            requirementSelect(assignment, bundle)

            amount(assignment, bundle)

            div(alignSelfCenter, mb20, pl24) {
                filledLaunchButton(strings.add) {
                    assign(assignment)
                    transactionBundle.copyFrom(bankService.transactionBundle(transaction.uuid))
                }
            }
        }
    }

    private fun Z2.proceedingSelect(assignment: Assignment, bundle: ProceedingFinanceBundle) =
        div().attach(assignment) {
            if (assignment.proceeding != bundle.proceeding.uuid) {
                bundle.copyFrom(ProceedingFinanceBundle())
                localLaunch {
                    financeService.bundle(assignment.proceeding).also { new ->
                        if (new.proceeding.uuid == assignment.proceeding) bundle.copyFrom(new)
                    }
                    assignment.caseNumber = proceedingCaseNumber(proceedings, assignment.proceeding)
                }
            }
        }

    private fun Z2.requirementSelect(assignment: Assignment, bundle: ProceedingFinanceBundle) =
        div().attach(bundle) {
            if (transaction.isIncoming) {
                incomingRequirement(bundle.incomingRequirements.open, bundle.participations, {
                    bundle.incomingRequirements[assignment.incomingRequirement]?.let { req ->
                        assignment.amount = req.remainingAmount
                        assignment.participation = req.participation
                    }
                }) {
                    assignment.incomingRequirement
                }
            } else {
                outgoingRequirement(bundle.outgoingRequirements.open, bundle.participations, {
                    bundle.outgoingRequirements[assignment.outgoingRequirement]?.let { req ->
                        assignment.amount = req.remainingAmount
                        assignment.participation = req.participation
                    }
                }) {
                    assignment.outgoingRequirement
                }
            }
        } gridColumn "1/3"

    private fun Z2.amount(assignment: Assignment, bundle: ProceedingFinanceBundle) {
        div().attach(bundle) {
            participation(bundle.participations, {
                assignment.outgoingRequirement = UUID.nil()
                assignment.incomingRequirement = UUID.nil()
                assignment.amount = 0L
            }) {
                assignment.participation
            }
        } gridColumn "1/3"

        field { assignment.amount }
    }

    private fun Z2.pairings() {
        surfaceContainerLowest(borderOutline) {

            val assignments = transactionBundle.incomingPayments.map { ip ->
                Assignment().also {
                    it.id = 1
                    it.proceeding = ip.proceeding
                    it.caseNumber = proceedings.firstOrNull { p -> p.uuid == ip.proceeding }?.caseNumber ?: ""
                    it.participation = ip.participation
                    it.amount = ip.paymentAmount
                    it.incomingPayment = ip
                }
            } + transactionBundle.outgoingPayments.map { op ->
                Assignment().also {
                    it.id = 1
                    it.proceeding = op.proceeding
                    it.caseNumber = proceedings.firstOrNull { p -> p.uuid == op.proceeding }?.caseNumber ?: ""
                    it.participation = op.participation
                    it.amount = op.paymentAmount
                    it.outgoingPayment = op
                }
            }

            table<Assignment> {
                fixRowHeight = false
                rowId = { it.id }
                data = assignments

                with(Assignment()) {
                    schematicColumn { caseNumber } initialSize 8.em
                    participationColumn { participation }
                    schematicColumn { amount }
                    actionColumn {
                        action {
                            label = strings.breakPair
                            handler = { breakAssignment(it) }
                        }
                        initialSize = "min-content"
                    }
                }
            }
        }
    }

    private suspend fun assign(assignment: Assignment) {
        assignment.touch()
        if (transaction.isIncoming) {
            val payment = IncomingPayment().also {
                it.valueDate = transaction.valueDate
                it.participation = assignment.participation
                it.bankTransaction = transaction.uuid
                it.proceeding = assignment.proceeding
                it.paymentAmount = assignment.amount
                it.remainingAmount = assignment.amount
            }
            if (! payment.isValidForCreate) {
                warningSnackbar(strings.invalidFields)
            } else {
                financeService.add(payment)
                transactionBundle.copyFrom(bankService.transactionBundle(transactionBundle.transaction.uuid))

            }
        } else {
            val payment = OutgoingPayment().also {
                it.valueDate = transaction.valueDate
                it.participation = assignment.participation
                it.bankTransaction = transaction.uuid
                it.proceeding = assignment.proceeding
                it.paymentAmount = assignment.amount
                it.remainingAmount = assignment.amount
            }
            if (! payment.isValidForCreate) {
                warningSnackbar(strings.invalidFields)
            } else {
                financeService.add(payment)
                transactionBundle.copyFrom(bankService.transactionBundle(transactionBundle.transaction.uuid))
            }
        }
    }

    private fun breakAssignment(assignment: Assignment) {
        if (transaction.isIncoming) {
            breakIncoming(assignment)
        } else {
            breakOutgoing(assignment)
        }
    }

    private fun breakIncoming(assignment: Assignment) {
        localLaunch {
            val ip = assignment.incomingPayment
            if (ip.remainingAmount != ip.paymentAmount) {
                errorSnackbar(strings.removeProceedingPairingFirst)
                return@localLaunch
            }

        }
    }

    private fun breakOutgoing(assignment: Assignment) {
        TODO("Not yet implemented")
    }


}