package hu.mkik.vb.portal.model.dmsone

import hu.simplexion.z2.schematic.Schematic

class DMSOnePartnerAdat : Schematic<DMSOnePartnerAdat>() {
    var id by int()
    var sorszam by int()
    var isAlapertelmezett by boolean()
    var ertek by string()
    var tipusNev by string()
    var tipusKod by string()
}