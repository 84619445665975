package hu.mkik.vb.portal.ui.proceeding.hearing.pages

import hu.mkik.vb.portal.model.Hearing
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.isProceedingManager
import hu.mkik.vb.portal.ui.proceeding.currentProceeding
import hu.mkik.vb.portal.ui.proceeding.hearing.modals.addHearing
import hu.mkik.vb.portal.ui.proceeding.hearing.modals.editHearing
import hu.mkik.vb.portal.ui.spaceService
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.loading
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.mb24
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.textLaunchButton

fun Z2.hearings() {
    surfaceContainerLowest(borderOutline) {
        Hearings(this).main()
    }
}

class Hearings(parent: Z2) : Z2(parent) {

    lateinit var table: Table<Hearing>

    override fun main(): Hearings {
        addCss(mb24)
        build()
        return this
    }

    suspend fun refreshTable() {
        currentProceeding.refreshHearings()
        table.setData(currentProceeding.hearings.value)
    }

    fun Z2.build() {
        clear()

        title(currentProceeding.proceeding.caseNumber, strings.hearings) { actions() }

        loading {
            val spaces = spaceService.list()

            table<Hearing> {
                this@Hearings.table = table

                rowId = { it.uuid }
                query = { currentProceeding.hearings.value }
                doubleClickFun = { if (isProceedingManager) editHearing(it) { refreshTable() } }

                with(Hearing()) {
                    schematicColumn { date }
                    schematicColumn { start } initialSize "min-content"
                    column {
                        label = strings.space
                        render = { + spaces.first { s -> s.uuid == it.site }.address }
                    }
                    schematicColumn { note }
                    schematicColumn { status }
                    if (isProceedingManager) {
                        actionColumn {
                            action {
                                label = strings.edit
                                handler = { editHearing(it) { refreshTable() } }
                            }
                            initialSize = "min-content"
                        }
                    }
                }
            }
        }
    }

    fun Z2.actions() {
        if (isProceedingManager) {
            textLaunchButton(strings.addHearing) {
                addHearing { refreshTable() }
            }
        }
    }
}