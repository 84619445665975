package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.ProceedingFeeCondition
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface ProceedingFeeConditionApi : Service {

    suspend fun list(): List<ProceedingFeeCondition>

    suspend fun get(feeTableEntry: UUID<ProceedingFeeCondition>) : ProceedingFeeCondition

    suspend fun add(feeTableEntry: ProceedingFeeCondition) : UUID<ProceedingFeeCondition>

    suspend fun update(feeTableEntry: ProceedingFeeCondition)

    suspend fun remove(feeTableEntry: UUID<ProceedingFeeCondition>)

}