package hu.mkik.vb.portal.ui.report

import hu.mkik.vb.portal.model.report.HonorariumSummary
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.proceedingService
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.overflowHidden
import hu.simplexion.z2.browser.css.positionRelative
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.modal.modal
import hu.simplexion.z2.util.hereAndNow
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.text.monthNameTable
import hu.simplexion.z2.localization.withLocalization
import hu.simplexion.z2.xlsx.browser.onExportXlsx
import kotlinx.datetime.number

fun Z2.honorariumSummary() =
    surfaceContainerLowest(borderOutline) {
        modal {
            var year = hereAndNow().date.year
            var month = hereAndNow().date.month.number

            title(strings.honorariumSummaryReport)
            body {
                yearSelect { year = it }
                monthSelect { month = it }
            }
            save(strings.ok) {
                this@surfaceContainerLowest.run(year, month)
                close()
            }
        }
    }

private fun Z2.run(year: Int, month: Int) {
    lateinit var table: Table<HonorariumSummary>

    grid {
        gridConfig("1fr", "min-content 1fr")
        addCss(overflowHidden)

        div {
            title(strings.honorariumSummaryReport.localeCapitalized + " - " + year + ". " + monthNameTable[month - 1]) {
                filledLaunchButton(strings.download) {
                    table.onExportXlsx()
                }
            }
        }

        div(positionRelative) {
            div {
                table<HonorariumSummary> {
                    table = this.table

                    fixRowHeight = false
                    rowId = { it.caseNumber }
                    query = { proceedingService.honorariumSummary(year, month) }

                    withLocalization(reportStrings) {
                        with(HonorariumSummary()) {
                            schematicColumn { recipient }
                            schematicColumn { amount }
                            schematicColumn { caseNumber }
                        }
                    }
                }
            }
        }
    }
}