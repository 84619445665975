package hu.mkik.vb.portal.ui.administration.account.modals

import hu.mkik.vb.portal.ui.accountVBService
import hu.mkik.vb.portal.ui.proceeding.participation.components.ParticipantSelector
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.material.button.filledLaunchButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.modal.inputModal
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.warningSnackbar

fun dmsOneSelect() =
    inputModal {
        this.style.width = 800.px

        title(strings.mpaid)

        lateinit var selector: ParticipantSelector

        body {
            selector = ParticipantSelector(this, true).main()
        }

        buttons {
            textButton(strings.cancel) { closeWith(null) }
            filledLaunchButton(strings.ok) {
                if (accountVBService.getUuidByMpaid(selector.candidate.mpaid) != null) {
                    warningSnackbar(strings.accountWithMpaidExists)
                } else {
                    closeWith(selector.candidate)
                }
            }
        }
    }