package hu.mkik.vb.portal.ui.proceeding.participation.components

import hu.mkik.vb.portal.model.ParticipationCandidate
import hu.mkik.vb.portal.ui.participationService
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.field.FieldState
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.columnGap
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.select.SelectBase
import hu.simplexion.z2.browser.immaterial.select.SelectConfig
import hu.simplexion.z2.browser.material.px

class ParticipantSelector(
    parent: Z2,
    val add: Boolean
) : Z2(parent) {

    var candidate = ParticipationCandidate()

    lateinit var partnerSelect: SelectBase<ParticipationCandidate>

    override fun main(): ParticipantSelector {
        grid("1fr 1fr", "repeat(3, 1fr)") {
            columnGap = 16.px
            buildPartnerSelect() gridColumn "1/3"
            field { candidate.email } readOnly true
            field { candidate.mpaid } readOnly true
            field { candidate.address }.also { it.gridColumn("1/3") } readOnly true
            field { candidate.phone } readOnly true
            field { candidate.bankAccount } readOnly true
        }
        return this
    }

    fun validate(): Boolean {
        val valid = (partnerSelect.valueOrNull != null) && candidate.isValidForCreate
        partnerSelect.state.touched = true
        partnerSelect.state.error = !valid
        return valid
    }

    private fun Z2.buildPartnerSelect() =
        SelectBase(
            this,
            FieldState().also {
                it.label = strings.participantSearchHint.localized
            },
            SelectConfig<ParticipationCandidate>().apply {
                this.itemTextFun = { it.dmsonePartner.fullName }
                this.itemContentFun = {
                    grid("1fr", "min-content min-content") {
                        div { text { it.dmsonePartner.fullName } }
                        div { text { it.dmsonePartner.mpaid } }
                    }
                }
                onChange = {
                    candidate.copyFrom(it.value)

                    it.state.error = false

                }
            },
            queryFun = {
                participationService.candidates(null, null, it)
            }
        )
            .main()
            .also {
                partnerSelect = it
            }
}