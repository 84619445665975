package hu.mkik.vb.portal.ui.account.pages

import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.application.session
import hu.simplexion.z2.auth.model.Session
import hu.simplexion.z2.auth.sessionService
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.routing.SimpleRouter
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.util.localLaunch
import kotlinx.browser.window
import kotlinx.coroutines.delay

val Logout = SimpleRouter(strings.logout, icons.logout) { logout() }

private fun Z2.logout() {

    surfaceContainerLowest(displayFlex, alignItemsCenter, justifyContentCenter) {
        grid {
            gridTemplateColumns = "min-content"
            gridAutoRows = "min-content"
            gridGap = 8.px

            div {
                style.padding = 24.px
                style.border = "1px solid lightgray"
                style.borderRadius = "8px"
                grid {
                    gridTemplateColumns = 400.px
                    gridAutoRows = "min-content"
                    gridGap = 16.px

                    img(pb8) {
                        style.width = 400.px
                        src = "/logo.jpg"
                    }

                    div(titleLarge, justifySelfCenter, pb8) {
                        text { strings.logout.localeCapitalized }
                    }

                    div(wFull, positionRelative, pb8) {
                        div(wFull, surfaceContainerHighest, pt4) {  }
                        div(positionAbsolute, wFull, primary, pt4,  "scale-up-width-2000".css) {
                            style.top = "0"
                            zIndex = 1
                        }
                    }
                }
            }
        }
    }

    session = Session()
    window.sessionStorage.clear()

    localLaunch {

        try {
            sessionService.logout()
            delay(1000)
        } catch (ex: Exception) {
            ex.printStackTrace()
        }

        window.location.href = "/login"
    }
}