package hu.mkik.vb.portal.api

import hu.mkik.vb.portal.model.Hearing
import hu.mkik.vb.portal.model.Proceeding
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.services.Service

interface HearingApi : Service {

    suspend fun list(): List<Hearing>

    suspend fun list(proceeding: UUID<Proceeding>): List<Hearing>

    suspend fun add(hearing: Hearing) : UUID<Hearing>

    suspend fun update(hearing: Hearing)

    suspend fun remove(hearing: UUID<Hearing>)

}