package hu.mkik.vb.portal.model

import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.schematic.Schematic

class TaskType : Schematic<TaskType>() {
    var uuid by uuid<TaskType>(true)
    var proceedingType by enum<ProceedingType>()
    var assignToRole by uuid<Role>(nil =false)
    var name by string(100, blank = false)
    var deadlineDays by int(min = 0, max = 365)
    var note by string()
    var active by boolean(true)
}