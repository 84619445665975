package hu.mkik.vb.portal.model

import hu.mkik.vb.portal.model.dmsone.DMSOnePartner
import hu.simplexion.z2.schematic.Schematic

class ParticipationCandidate : Schematic<ParticipationCandidate>() {
    var account by uuid<AccountVB>(true)
    var mpaid by int()
    var dmsonePartner by schematic<DMSOnePartner>()
    var fullName by string()
    var email by email(true)
    var address by string()
    var phone by string()
    var bankAccount by string()
    var retired by boolean(false)

    fun patchedCopy(participation: Participation): Participation {
        val copy = participation.copy()
        copy.fullName = fullName
        copy.address = address
        copy.email = email
        copy.phone = phone
        copy.bankAccount = bankAccount
        return copy
    }
}