package hu.mkik.vb.portal.model

import hu.simplexion.z2.schematic.Schematic

class DocumentType : Schematic<DocumentType>() {
    var uuid by uuid<DocumentType>()
    var name by string() maxLength 100 minLength 3 blank false
    var proceedingType by enum<ProceedingType>()
    var privateType by boolean()

    override fun toString() = name
}