package hu.mkik.vb.portal.ui.administration.locales.pages

import hu.mkik.vb.portal.ui.administration.locales.modals.translationEditor
import hu.mkik.vb.portal.ui.isProceedingManager
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.tablePage
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.builders.size
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.localization.locales.localeCapitalized
import hu.simplexion.z2.localization.model.Locale
import hu.simplexion.z2.localization.model.Translation
import hu.simplexion.z2.localization.translationService

fun Z2.translationList(locale: Locale) =
    tablePage<Translation> {

        header {
            title { + "${strings.translations.localeCapitalized} - ${locale.nativeName} (${locale.isoCode}-${locale.countryCode})" }
            search()
            action(strings.addTranslation) { translationEditor(locale.uuid, null, table) }
        }

        rowId = { it.key }
        query = { translationService.list(locale.uuid) }

        if (isProceedingManager) {
            doubleClickFun = { translationEditor(locale.uuid, it, table) }
        }

        with(Translation()) {
            schematicColumn { key }
            schematicColumn { value }
            schematicColumn { verified } size "min-content"
            editActionColumn { translationEditor(locale.uuid, it, table) }
        }

    }