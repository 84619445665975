package hu.mkik.vb.portal.model.runtime

import hu.mkik.vb.portal.model.*
import hu.simplexion.z2.schematic.Schematic

class ProceedingBundle : Schematic<ProceedingBundle>() {
    var proceeding by schematic<Proceeding>()
    var hearings by schematicList<Hearing>()
    var participations by schematicList<Participation>()
    var documents by schematicList<Document>()
    var settings by schematic<ProceedingSettings>()
    var roles by schematicList<ProceedingRole>() // FIXME schematic companion not found, replace with Role
    var folderTypes by schematicList<FolderType>()
    var documentTypes by schematicList<DocumentType>()
    var uploadPermissions by schematicList<DocumentPermission>()
    var documentPermissions by schematicList<DocumentPermission>()
    var events by schematicList<ProceedingEvent>()
    var tasks by schematicList<Task>()
}