package hu.mkik.vb.portal.ui.component

import hu.mkik.vb.portal.ui.locales
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.localization.model.Locale
import hu.simplexion.z2.schematic.SchematicAccessFunction
import hu.simplexion.z2.schematic.access.SchematicAccessContext

@SchematicAccessFunction
fun Z2.locale(
    afterChange : (UUID<Locale>) -> Unit = {  },
    context: SchematicAccessContext? = null,
    accessor: () -> UUID<Locale>
) {
    checkNotNull(context)
    selectField(
        locales.map { it.uuid },
        { if (it.isNil) "" else locales.first { l -> l.uuid == it }.nativeName },
        {
            div { + if (it.isNil) "" else locales.first { l -> l.uuid == it }.nativeName }
        },
        afterChange,
        context
    )
}
