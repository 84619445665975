package hu.mkik.vb.portal.ui.bank.pages

import hu.mkik.vb.portal.model.finance.BankTransaction
import hu.mkik.vb.portal.ui.bank.modals.editTransaction
import hu.mkik.vb.portal.ui.bankService
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.proceeding.gridConfig
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.loading
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.html.grid
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.localization.locales.localeCapitalized

fun Z2.pendingTransactions() =
    surfaceContainerLowest(borderOutline) {
        loading {
            grid {
                gridConfig("1fr", "min-content 1fr")

                title(strings.pendingTransactions.localeCapitalized)

                div {
                    table<BankTransaction> {
                        rowId = { it.uuid }
                        query = { bankService.pendingTransactions() }
                        doubleClickFun = { editTransaction(it) }

                        with(BankTransaction()) {
                            schematicColumn { valueDate }
                            schematicColumn { type } initialSize "min-content"
                            schematicColumn { amount }
                            schematicColumn { remainingAmount }
                            schematicColumn { peerName }
                            schematicColumn { peerAccount }
                            schematicColumn { additionalInfo }
                            actionColumn {
                                action {
                                    label = strings.edit
                                    handler = { editTransaction(it) }
                                }
                                initialSize = "min-content"
                            }
                        }
                    }
                }
            }
        }
    }