package hu.mkik.vb.portal.ui.account.pages

import hu.mkik.vb.portal.model.AccountVB
import hu.mkik.vb.portal.ui.administration.account.pages.editAccount
import hu.simplexion.z2.browser.html.Z2Builder
import hu.simplexion.z2.browser.routing.NavRouter

object ViewAccount : NavRouter(useParentNav = true) {
    val uuid by uuid<AccountVB>()
    override var default: Z2Builder = { editAccount(uuid) }
}