package hu.mkik.vb.portal.ui.administration.documentPermission

import hu.mkik.vb.portal.model.DocumentPermission
import hu.mkik.vb.portal.ui.*
import hu.mkik.vb.portal.ui.component.optReferenceColumn
import hu.mkik.vb.portal.ui.component.referenceColumn
import hu.mkik.vb.portal.ui.util.loading
import hu.simplexion.z2.auth.roleService
import hu.simplexion.z2.browser.css.backgroundTransparent
import hu.simplexion.z2.browser.css.borderOutline
import hu.simplexion.z2.browser.css.p0
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.immaterial.table.schematicColumn
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.layout.container
import hu.simplexion.z2.browser.layout.surfaceContainerLowest
import hu.simplexion.z2.browser.routing.NavRouter

object DocumentPermissionList : NavRouter(
    strings.documentPermissions,
    icons.documentPermissions,
    useParentNav = true,
    visibility = secretaryOrTechnical,
    default = { documentPermissionList() }
)

internal fun Z2.documentPermissionList() =
    surfaceContainerLowest(borderOutline) {

        container(p0, backgroundTransparent, scroll = false) {
            loading {
                val folderTypes = folderTypeService.query()
                val documentTypes = documentTypeService.query()
                val roles = roleService.query()

                table<DocumentPermission> {

                    header {
                        text = strings.folderTypes
                        search()
                    }

                    rowId = { it.uuid }
                    query = { documentPermissionService.query() }

                    with(DocumentPermission()) {
                        schematicColumn { order }
                        schematicColumn { proceedingType }
                        schematicColumn { list }
                        schematicColumn { upload }
                        schematicColumn { download }
                        schematicColumn { inactivate }
                        referenceColumn(folderTypes) { folderType }
                        referenceColumn(documentTypes) { documentType }
                        optReferenceColumn(roles) { systemRole }
                        optReferenceColumn(roles) { proceedingRole }
                        schematicColumn { description }
                    }
                }
            }
        }
    }